import React, { FC, useCallback, useMemo, useState } from "react";
import ViewComponent from "../../../../components/modals/ViewSettings/viewComponent";
import { Button } from "../../../../mobiscroll/react/components/button";
import { Datepicker } from "../../../../mobiscroll/react/components/datepicker";
import {
  CalendarNext,
  CalendarPrev,
  CalendarToday
} from "../../../../mobiscroll/react/components/eventcalendar";
import { getFullYear } from "../util/common";
import { OverlayTrigger, Popover } from "react-bootstrap";
import LayoutButtonPopup from "../../../../components/modals/layoutButton/LayoutButtonPopup";

const CustomWithNavButtons: FC<any> = ({
  buttonText,
  currentDate,
  startDate,
  endDate,
  setCurrentDate,
  setRefDate,
  setCalView,
  extendedMorningStart,
  extendedEveningEnd,
  setRangeVal,
  rangeVal,
  view,
  setView,
  setCurrentYear,
  viewSettingsData,
  layoutAndFiltersData,
  selectActiveLayout,
  refetchLayouts,
  filterIds,
  toggleDropdown,
  openDropdown,
  changeView
}) => {
  const [onChangeDate, setOnChangeDate] = useState(false)
  const getNrDays = useCallback((start: any, end: any) => {
    return (
      Math.round(
        Math.abs((end.setHours(0) - start.setHours(0)) / (24 * 60 * 60 * 1000))
      ) + 1
    );
  }, []);

  const onDatePickerClose = useCallback(() => {
    if (onChangeDate === true) {
      if (startDate.current && endDate.current) {
        // navigate the calendar
        setCurrentDate(startDate.current);
        // set calendar view
        setRefDate(startDate.current);
        if (
          getNrDays(startDate.current, endDate.current) > 1 &&
          getNrDays(startDate.current, endDate.current) <= 7
        ) {
          setView("week");
          setCalView({
            timeline: {
              type: "day",
              size: getNrDays(startDate.current, endDate.current),
              timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              startTime: extendedMorningStart,
              endTime: extendedEveningEnd,
              weekNumbers: false,
              eventList: viewSettingsData?.showColumnSummaryEvent,
              maxEventStack: 'all'
            },
          });
  
        } else if (
          getNrDays(startDate.current, endDate.current) > 7 &&
          getNrDays(startDate.current, endDate.current) < 32
        ) {
          setView("month");
          setCalView({
            timeline: {
              type: "day",
              size: getNrDays(startDate.current, endDate.current),
              startTime: "00:00",
              endTime: "24:00",
              resolutionHorizontal: "week",
              weekNumbers: true,
              startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
              endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
              eventList: viewSettingsData?.showColumnSummaryEvent,
              maxEventStack: 'all'
            },
          });
        } else if (getNrDays(startDate.current, endDate.current) >= 32) {
          setView("year");

          setCalView({
            timeline: {
              type: "day",
              size: getNrDays(startDate.current, endDate.current),
              startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
              endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
              startTime: "00:00",
              endTime: "24:00",
              resolutionHorizontal: "month",
              weekNumbers: true,
              eventList: viewSettingsData?.showColumnSummaryEvent,
              maxEventStack: 'all'
            },
          });
        } else {
          setView("day");
          setCalView({
            timeline: {
              type: "day",
              size: getNrDays(startDate.current, endDate.current),
              timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              startTime: extendedMorningStart,
              startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
              endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
              endTime: extendedEveningEnd,
              weekNumbers: false,
              eventList: viewSettingsData?.showColumnSummaryEvent,
              maxEventStack: 'all'
            },
          });
        }
      }
      setRangeVal([startDate.current, endDate.current]);
      setOnChangeDate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNrDays, currentDate,onChangeDate]);

  const onChange = useCallback(
    (args: any) => {
      setOnChangeDate(true)
      const date = args.value;
      setRangeVal(date);
      setCurrentYear(getFullYear(date[0]));
      if (date[0] && date[1]) {
        startDate.current = date[0];
        endDate.current = date[1];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate]
  );

  const buttonProps = useMemo(() => {
    const content = (
      <span className="mbsc-calendar-title d-inline-flex align-items-center">
        {buttonText}
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ms-2"
        >
          <path
            d="M10.9498 1.75L6.87481 5.825C6.39355 6.30625 5.60605 6.30625 5.12481 5.825L1.0498 1.75"
            stroke="currentcolor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    );
    return {
      children: content,
      className: "mbsc-calendar-button",
      variant: "flat",
    };
  }, [buttonText]);



  const setPlanningcapacityview = (day: string) => {
    setView(day);
    changeView(day);
    setCurrentDate(new Date());
  };



  
  const renderDateCalender = (
    <Popover id="render-hours-tooltip">
      <div className="p-5 bg-white card">
        <div>Customize the date range of your projects screen. Tip: the "Project has items in current view" filter. 
          </div>
        </div> 
    </Popover>
  )

  const renderViewSettingsTooltip = (
    <Popover id="render-hours-tooltip">
      <div className="p-5 bg-white card">
        <div>Change how compact your project overview is. <a href="https://knowledgebase.proxuma.io/article/projects-dashboard" className="text-decoration-underline ms-2" target="_blank">
              Learn more
            </a> 
          </div>
        </div> 
    </Popover>
  )
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100 flex-wrap pickerMain">
        <div className="picLeft d-flex align-items-center">
          <div className="me-4 d-flex" id="project-date-range">
            <Datepicker
              select="range"
              display="anchored"
              showOverlay={false}
              touchUi={true}
              buttons={[]}
              inputComponent={Button}
              inputProps={buttonProps}
              onClose={onDatePickerClose}
              onChange={onChange}
              value={rangeVal}
            />
            <div className="h-32px d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={renderDateCalender}
              >
                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2">i</button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="d-flex align-items-center md-custom-range-view-controls">
            <div id="project-date-previous"><CalendarPrev /></div>
            <div id="project-date-today"><CalendarToday /></div>
            <div id="project-date-next"><CalendarNext /></div>
          </div>
        </div>

        <div className="picMid d-flex align-items-center">
          <div className="min-w-160px"></div>
          <div className="bg-white border border-primary rounded-pill me-1 switchBtns">
            <ViewComponent view={view} handleViewChange={setPlanningcapacityview} />
          </div>
        </div>

        <div className="picRight d-flex" id="view-settings-projects">
        
          <LayoutButtonPopup
            layoutAndFiltersData={layoutAndFiltersData}
            selectActiveLayout={selectActiveLayout}
            refetchLayouts={refetchLayouts}
            layoutType="project"
            payload={{ projectPayload: filterIds }}
            toggleDropdown={toggleDropdown}
            openDropdown={openDropdown}
          />

        </div>
      </div>
    </>
  );
};

export default React.memo(CustomWithNavButtons);