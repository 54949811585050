import moment from 'moment-timezone';
import { FC } from "react";
import { Loading } from "../../../components/loading/Loading";
import '../logs/login/Log.scss';

type Props = {
  loading?: any;
  data?: any;
  changeDateFormat?: any
  setting?: any
  isfetchgeneralsetting?:any
}


const NotificationPage: FC<Props> = ({loading,data,changeDateFormat,setting,isfetchgeneralsetting}) => {

  
  return (
    <>
      <Loading
        isLoading={loading}
        isHideSpinner={true}
        className="splash-screen"
      />
      <div className="py-6 px-7 h-100 notifyHeight">
        <div className="card mh-100 notifications" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="text-dark fw-bold fs-18 m-0">Notifications</h3>
            </div>
          </div>
          <div className="card-body pt-4 overflow-auto mh-100">
            {!loading &&
              <div className="timeline timeline-6 mt-3 ml-5 overflow-auto" >
                <div className='accordion' id='kt_accordion_1'>
                  {data?.map((notification: any, index: any) => (

                    <div className='accordion-item mb-1 rounded-1'>
                      <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>

                        <button
                          className='accordion-button fw-bold collapsed p-4 fs-16'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#kt_accordion_1_body_${index}`}
                          aria-expanded='false'
                          aria-controls={`kt_accordion_1_body_${index}`}
                        >
                          {changeDateFormat(moment(notification.date).format("DD-MM-Y"))}

                          {/* {moment(log.date).format("MM-DD-Y")} */}
                        </button>
                      </h2>

                      {/* <div
                        id={`kt_accordion_1_body_${index}`}
                        className={`accordion-collapse pb-6 collapse ${index == 0 ? 'show' : ''}`}
                        aria-labelledby={`kt_accordion_1_header_${index}`}
                        data-bs-parent='#kt_accordion_1'
                      >
                        {notification?.notificationdata?.map((logd: any) => (
                          <div>
                            {JSON.parse(logd.response_data).code != '500' &&
                              <div className='accordion-body pb-0 fs-15'>
                                <span className="px-2 p-1 fs-7 fs-ls-14 min-w-55px badge badge-light-primary me-4 bg-prlight">{moment(logd.created_at).format("h:mm a")}</span>
                                {logd.is_successfull == '1' ? (
                                  <>

                                    {logd.username} successfully logged in from IP Address {logd?.ip_address}&nbsp;
                                    <span className="px-2 p-1 fs-7 fs-ls-14 min-w-55px badge badge-light-success">{JSON.parse(logd.response_data).code} OK</span>
                                  </>
                                ) : (
                                  <>
                                    {logd?.username} attempt wrong password from IP Address {logd?.ip_address}&nbsp;
                                    <span className="px-2 p-1 fs-7 fs-ls-14 min-w-55px badge badge-light-danger">{JSON.parse(logd.response_data).code} ERR</span>
                                  </>
                                )}
                              </div>
                            }
                          </div>
                        ))}
                      </div> */}
                      <div
                        id={`kt_accordion_1_body_${index}`}
                        className={`accordion-collapse pb-6 collapse ${index == 0 ? 'show' : ''}`}
                        aria-labelledby={`kt_accordion_1_header_${index}`}
                        data-bs-parent='#kt_accordion_1'
                      >
                        {!isfetchgeneralsetting && notification?.notificationdata && notification?.notificationdata?.map((logd: any) => (
                          <div>
                              <div className='accordion-body pb-0 fs-15'>
                                <span className="px-2 p-1 fs-15 min-w-49px badge badge-light-primary me-4 bg-prlight">{moment(logd.created_at).tz(setting?.timezone).format(setting?.time_format)}</span>
                                {/* <span className="px-2 p-1 min-w-55px badge badge-light-success">{logd?.message} OK</span> */}
                                <span className="px-2 p-1 min-w-55px"><span className="text-dark fw-bold">
                                {logd?.url ? (<a href={logd?.url ? logd?.url : 'javascript:void(0)'} target='_blank' className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4">
                                  {logd?.title} :
                                  </a>):(<a className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4">
                                  {logd?.title} :
                                  </a>)}
                                
                                  </span>{logd?.message}</span>
                                {logd.response_data && 
                                <>
                                {logd?.response_data == '200' ? 
                                (
                                  <>
                                    <span className="px-2 p-1 fs-15 min-w-55px badge badge-light-success">{logd?.response_data} OK</span>
                                  </>
                                ) : (
                                  <>
                                    <span className="px-2 p-1 fs-15 min-w-55px badge badge-light-danger">{logd?.response_data} ERR</span>
                                  </>
                                )}
                                </>
                              }
                              </div>

                        </div>
                        ))}
                      </div>
                    </div>

                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default NotificationPage;