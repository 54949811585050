/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { isNotEmpty, toAbsoluteUrl, toApiAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Loading } from "../../../../components/loading/Loading";
import { initialMediaSetting, MediaSetting } from "../core/_models";
import { createAdminMedia, getGeneralSetting, updateAdminMedia } from "../core/_requests";
import { alert } from "@mobiscroll/react";

const AdminMedia = () => {

    const [logoImage, setlogoImage] = useState<any>("");
    const [logourl, setlogourl] = useState<any>('');
    const [companyLogo, setcompanyLogo] = useState<any>("");
    const [companyurl, setcompanyurl] = useState<any>('');

    const [imageValidation, setimageValidation] = useState("");
    const [imglength, setImglen] = useState(false);

    const [companylogoValidation, setcompanylogoValidation] = useState("");
    const [companylogolength, setcompanylogolen] = useState(false);

    const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");

    const {
        refetch,
        data: mediaSetting } = useQuery(
            `media-setting`,
            async () => {
                return await getGeneralSetting('');
            },
            {
                cacheTime: 0,
                onError: (err) => {
                    //   console.error(err);
                },
            }
        );

    useEffect(() => {
        setlogourl(mediaSetting?.admin_background);
        setcompanyurl(mediaSetting?.company_logo);

    }, [mediaSetting]);

    const getUserPic = (pic: any) => {
        let path = '';
        if (pic == 'logoImage') {
            if (logoImage == null) {
                return `url(${toAbsoluteUrl(`${blankImg}`)})`;
            }
            if (logoImage) {
                return `url(${URL.createObjectURL(logoImage)})`;
            }
            path = (`${logourl}`);
        }
        if(pic == 'companyLogo') {
            if (companyLogo == null) {
                return `url(${toAbsoluteUrl(`${blankImg}`)})`;
            }
            if (companyLogo) {
                return `url(${URL.createObjectURL(companyLogo)})`;
            }
            path = (`${companyurl}`);
        }
        return `url(${path})`;
    }

    const selectBackground = (e: any) => {
        const image = e.currentTarget.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png|jfif|webp)$/)) {
            setimageValidation("Uploaded file has unsupported format.");
            return false;
        }
        setImglen(true);
        setimageValidation('');
        setlogoImage(e.currentTarget.files[0]);
    }

    const removeImg = (e: any) => {
        (document.querySelector(".backgroundCls") as HTMLInputElement).value = ""
        setlogoImage(null);
        setImglen(false);
        setimageValidation('');
        setlogourl('');
    }

    const selectCompanyLogo = (event: any) => {

        const imageFile = event.currentTarget.files[0];

        if (!imageFile.name.match(/\.(jpg|jpeg|png|jfif|webp)$/)) {
            setcompanylogoValidation("Uploaded file has unsupported format.");
            return false;
        }
        setcompanylogolen(true);
        setcompanylogoValidation('');
        setcompanyLogo(event.currentTarget.files[0]);
    }

    const removeCompanyLogo = (e: any) => {
        (document.querySelector(".companyLogoCls") as HTMLInputElement).value = ""
        setcompanyLogo(null);
        setcompanylogolen(false);
        setcompanylogoValidation('');
        setcompanyurl('');
    }

    let settingForEdit = mediaSetting ?
        {
            id: mediaSetting._id,
            admin_background: mediaSetting.admin_background,
            company_logo: mediaSetting.company_logo,
        }
        : initialMediaSetting;

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch();
        }
    };
    const formik = useFormik<MediaSetting>({
        enableReinitialize: true,
        initialValues: settingForEdit,
        // validationSchema: profileDetailSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoading(true);
            try {
                let adminBg: any = new FormData();
                Object.keys(values).forEach((key) => {
                    if (key != "admin_background" && values[key as keyof MediaSetting] !== "undefined") {
                        adminBg.append(key, values[key as keyof MediaSetting]);
                    }
                });
                adminBg.append("admin_background", logoImage!);

                let profilelogo: any = new FormData();
                Object.keys(values).forEach((key) => {
                    if (key != "company_logo" && values[key as keyof MediaSetting] !== "undefined") {
                        profilelogo.append(key, values[key as keyof MediaSetting]);
                    }
                });
                profilelogo.append("company_logo", companyLogo!);

                if ((logoImage != '' || logoImage == null) || (companyLogo != '' || companyLogo != null)) {
                    if (isNotEmpty(values._id)) {
                        await updateAdminMedia(profilelogo);
                        await updateAdminMedia(adminBg);
                    } else {
                        await createAdminMedia(profilelogo);
                        await createAdminMedia(adminBg);
                    }
                } else {
                    if (isNotEmpty(values._id)) {
                        await updateAdminMedia(values);
                    } else {
                        await createAdminMedia(values);
                    }
                }
            } catch (ex) {
                setLoading(false);
            } finally {
                navigate("/superadmin/general-settings/admin-media");
                setSubmitting(true);
                setLoading(false);
                cancel(true);
                alert({
                    message: 'Changes saved successfully!',
                    callback: function (result: any) {
                        window.location.reload();
                    }
                });
            }
        },
    });



    return (
        <>
            <div className="col">
                <div className="card card-custom card-stretch h-100">

                    <form
                        onSubmit={formik.handleSubmit}
                        noValidate className='form d-flex flex-column h-100 overflow-auto'>
                        <div className="card-header">
                            <div className="card-title align-items-start flex-column">
                                <h1 className="fw-bolder m-0 fs-4 fs-lg-2">
                                    Media
                                </h1>
                            </div>
                            <div className="card-toolbar">

                                <button
                                    type="submit"
                                    className="btn btn-primary h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14"
                                    disabled={loading || imageValidation != '' || companylogoValidation != ''}
                                >
                                    {!loading && "Save changes"}
                                    <Loading isLoading={loading} text={"Please wait..."}></Loading>
                                </button>
                                {/* <Link
                                    to="/superadmin/general-settings/admin-media"
                                    className="btn btn-dark h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14 d-flex align-items-center"
                                >
                                    Cancel
                                </Link> */}
                            </div>
                        </div>
                        {/* end::Header */}

                        {/* begin::Form */}
                        <div className="form">
                            {/* begin::Body */}
                            <div className="card-body">

                                <div className="row">
                                    <label className="col-xl-3"></label>
                                    <div className="col-lg-9 col-xl-6">
                                    </div>
                                </div>
                                <div className="form-group row mb-8">
                                    <label className="mw-250px col-form-label fw-bold fs-6 fs-ls-15">Background</label>
                                    <div className="col-lg-9 col-xl-6">
                                        <div
                                            className="image-input image-input-outline"
                                            id="kt_profile_avatar"
                                            data-kt-image-input='true'
                                            style={{
                                                backgroundImage: `url('${blankImg}')`,
                                            }}
                                        >

                                            <div
                                                className="image-input  image-input-outline"
                                                data-kt-image-input='true'
                                                id="kt_profile_avatar">

                                                {logoImage != "" || logourl != '' ?
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={{ backgroundImage: `${getUserPic('logoImage')}` }}
                                                    ></div>
                                                    :
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={{ backgroundImage: `url('${blankImg}')` }}
                                                    ></div>
                                                }

                                                <label
                                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px w-ls-35 h-ls-35 bg-white shadow"
                                                    data-kt-image-input-action='change'
                                                    data-bs-toggle='tooltip'
                                                    title="Change Background"
                                                >
                                                    <i className='bi bi-pencil-fill fs-7'></i>
                                                    <input
                                                        id="file"
                                                        name="file"
                                                        type="file"
                                                        accept='image/*'
                                                        className="backgroundCls"
                                                        onChange={(event: any) => { selectBackground(event) }}
                                                    />
                                                    <input type="hidden" name="profile_pic_remove" />
                                                </label>

                                                {
                                                    imglength == true
                                                        || logourl != '' || imageValidation != ''
                                                        ?
                                                        <div>
                                                            <label
                                                                onClick={(e: any) => removeImg(e)}
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                                data-kt-image-input-action='remove'
                                                                data-bs-toggle='tooltip'
                                                                title=""
                                                                data-original-title="Remove avatar"
                                                            >
                                                                <i className='bi bi-x fs-2 fs-ls-25'></i>
                                                            </label>
                                                        </div>
                                                        :
                                                        ''
                                                }

                                            </div>
                                            {imageValidation != ''}
                                            <div className="fv-plugins-message-container" style={{ marginTop: '3px' }}>
                                                <div className="fv-help-block fs-8">{imageValidation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="mw-250px col-form-label fw-bold fs-6 fs-ls-15">Company Logo</label>
                                    <div className="col-lg-9 col-xl-6">
                                        <div
                                            className="image-input image-input-outline"
                                            id="kt_profile_logo"
                                            data-kt-image-input='true'
                                            style={{
                                                backgroundImage: `url('${blankImg}')`,
                                            }}
                                        >
                                            <div
                                                className="image-input  image-input-outline"
                                                data-kt-image-input='true'
                                                id="kt_profile_logo">

                                                {companyLogo != "" || companyurl != '' ?
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={{ backgroundImage: `${getUserPic('companyLogo')}` }}
                                                    ></div>
                                                    :
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={{ backgroundImage: `url('${blankImg}')` }}
                                                    ></div>
                                                }
                                                <label
                                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px w-ls-35 h-ls-35 bg-white shadow"
                                                    data-kt-image-input-action='change'
                                                    data-bs-toggle='tooltip'
                                                    title="Change Company Logo"
                                                >
                                                    <i className='bi bi-pencil-fill fs-7'></i>
                                                    <input
                                                        id='logo-file'
                                                        type="file"
                                                        accept='image/*'
                                                        name="logo-file"
                                                        className="companyLogoCls"
                                                        onChange={(event: any) => { selectCompanyLogo(event) }}
                                                    />
                                                    <input type="hidden" name="logo_remove" />
                                                </label>

                                                {
                                                    companylogolength == true
                                                        || companyurl != '' || companylogoValidation != ''
                                                        ?
                                                        <div>
                                                            <label
                                                                onClick={(e: any) => removeCompanyLogo(e)}
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                                data-kt-image-input-action='remove'
                                                                data-bs-toggle='tooltip'
                                                                title=""
                                                                data-original-title="Remove avatar"
                                                            >
                                                                <i className='bi bi-x fs-2 fs-ls-25'></i>
                                                            </label>
                                                        </div>
                                                        :
                                                        ''
                                                }

                                            </div>
                                            {companylogoValidation != ''}
                                            <div className="fv-plugins-message-container" style={{ marginTop: '3px' }}>
                                                <div className="fv-help-block fs-8">{companylogoValidation}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end::Body */}
                        </div>
                        {/* end::Form */}
                    </form>
                </div>
            </div>
        </>
    );
}

export { AdminMedia }