import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../helpers";
import { Dashboard, DashboardsQueryResponse } from "../../app/modules/apps/dashboard-management/core/_model";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const USER_URL_NODE = `${API_URL_NODE}`;

const getTotalsSuperAdmin = (query: string): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}superadmin/dashboard`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getTotals = (): Promise<Dashboard | undefined> => {
  return axios
    .get(`${USER_URL_NODE}dashboard`)
    .then((response: AxiosResponse<Response<Dashboard>>) => response.data)
    .then((response: Response<Dashboard>) => response.data);
};

export {
  getTotals,
  getTotalsSuperAdmin
};
