import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import {
  setcallApiforProjectfilter,
  storeProjectCompany,
  storeProjectLead,
  storeProjectStatus,
  storeProjects,
  storeTaskStatus,
  storelineOfBusinessIds,
  storeProjectStartDateOption,
  storeProjectStartDateMonthValue,
  storeProjectEndDateOption,
  storeProjectEndDateMonthValue,
  storeProjectView,
  setcallApiforfilter,
  storeProjectTypeids
} from "../../../../../app/counterSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hook";
import { DropDown } from "./../MainFilter/DropDown";
import { loadFromLocalStorage } from "../../../../helpers/latest_selection";
type Props = {
  filterArray?: any;
};
const FilterMain: FC<Props> = ({ filterArray }) => {
  const dispatch = useAppDispatch();
  const count = useAppSelector((state) => state.counter);
  const [currentClick, setcurrentClick] = useState("");

  //Line of business
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>(Array);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [showlineOfBusiness, setshowlineOfBusiness] = useState("d-none");

  //project
  const [showproject, setshowproject] = useState("d-none");
  const [Projectid, setProjectid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");

  //Project start date
  const [showprojectStartDate, setshowprojectStartDate] = useState("d-none");

  //Project end date
  const [showprojectEndDate, setshowprojectEndDate] = useState("d-none");

  // Project with current view
  const [showprojectView, setshowprojectView] = useState("d-none");

  //people
  const [Peopletid, setPeopletid] = useState<any>(Array);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [showpeople, setshowpeople] = useState("d-none");
  const [peopleinputText, setpeopleinputText] = useState("");

  //company
  const [showcompany, setshowcompany] = useState("d-none");
  const [Companyid, setCompanyid] = useState<any>(Array);
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [companyinputText, setcompanyinputText] = useState("");

  //task status
  const [showtaskstatus, setshowtaskstatus] = useState("d-none");
  const [taskstatusid, settaskstatus] = useState<any>(Array);
  const [taskstatusName, settaskstatusName] = useState<any>([]);
  const [taskstatusinputText, settaskstatusinputText] = useState("");

  //project status
  const [showprojectstatus, setshowprojectstatus] = useState("d-none");
  const [projectstatusid, setprojectstatus] = useState<any>(Array);
  const [projectstatusName, setprojectstatusName] = useState<any>([]);
  const [projectstatusinputText, setprojectstatusinputText] = useState("");

  const [projectStartDateMonthValue, setProjectStartDateMonthValue] = useState<number>(0);
  const [projectEndDateMonthValue, setProjectEndDateMonthValue] = useState<number>(0);
  const [projectStartDateOption, setProjectStartDateOption] = useState<string>('');
  const [projectEndDateOption, setProjectEndDateOption] = useState<string>('');

  const [projectView, setProjectView] = useState<boolean>(false);

  const [projectlist, setProjectList] = useState<any>();
  const [companylist, setcompanyList] = useState<any>();

  const [highlightProjectStartDate, setHighlightProjectStartDate] = useState(false);
  const [highlightProjectEndDate, setHighlightProjectEndDate] = useState(false);
  const [highlightProjectView, setHighlightProjectView] = useState(true);

  //project type
  //project status
  const [showprojecttype, setshowprojecttype] = useState("d-none");
  const [projecttypeid, setprojecttype] = useState<any>(Array);
  const [projecttypeName, setprojecttypeName] = useState<any>([]);
  const [projecttypeinputText, setprojecttypeinputText] = useState("");

  const clearAllFilter = async (value: any) => {
    dispatch(setcallApiforProjectfilter(value));
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    dispatch(storeProjects(ar));
    setshowproject("d-none");
    setprojectinputText("");

    setshowpeople("d-none");
    setPeopletid(ar);
    setPeopletName(ar);
    dispatch(storeProjectLead(ar));
    setpeopleinputText("");

    setshowprojectStartDate("d-none")

    setshowprojectEndDate("d-none")
    setshowprojectView("d-none")

    setCompanyid(ar);
    setCompanyName(ar);
    dispatch(storeProjectCompany(ar));
    setshowcompany("d-none");
    setcompanyinputText("");

    settaskstatus(ar);
    settaskstatusName(ar);
    dispatch(storeTaskStatus(ar));
    setshowtaskstatus("d-none");
    settaskstatusinputText("");

    if (value) {
      setprojectstatus(ar);
      setprojectstatusName(ar);
      dispatch(storeProjectStatus(ar));
      setshowprojectstatus("d-none");
      setprojectstatusinputText("");
    }

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    dispatch(storelineOfBusinessIds(ar));
    setshowlineOfBusiness("d-none");
    setlineOfBusinessinputText("");

    setprojecttype(ar);
    setprojecttypeName(ar);
    dispatch(storeProjectTypeids(ar));
    setshowprojecttype("d-none");
    setprojecttypeinputText("");

    storeFilterValue("all")

    if (filterArray?.companies?.length > 0) {
      setcompanyList(filterArray?.companies);
    }

    if (filterArray?.projects?.length > 0) {
      setProjectList(filterArray?.projects);
    }
  };

  const hideSelection = (e: any) => {
    dispatch(setcallApiforProjectfilter(true));
    let ar: any = [];
    if (e.target.value === "Project") {
      setProjectid([]);
      setProjectName([]);
      dispatch(storeProjects(ar));
      setshowproject("d-none");
      setprojectinputText("");
      //handleProjectFilterClick([])
      if (filterArray?.companies?.length > 0) {
        setcompanyList(filterArray?.companies);
      }
    } else if (e.target.value === "project_lead") {
      setshowpeople("d-none");
      setPeopletid(ar);
      setPeopletName(ar);
      dispatch(storeProjectLead(ar));
      setpeopleinputText("");

    } else if (e.target.value == "Company") {
      setCompanyid(ar);
      setCompanyName(ar);
      dispatch(storeProjectCompany(ar));
      setshowcompany("d-none");
      setcompanyinputText("");

      if (filterArray?.projects?.length > 0) {
        setProjectList(filterArray?.projects);
      }
    } else if (e.target.value === "task_status") {
      settaskstatus(ar);
      settaskstatusName(ar);
      dispatch(storeTaskStatus(ar));
      setshowtaskstatus("d-none");
      settaskstatusinputText("");
    } else if (e.target.value === "project_status") {
      setprojectstatus(ar);
      setprojectstatusName(ar);
      dispatch(storeProjectStatus(ar));
      setshowprojectstatus("d-none");
      setprojectstatusinputText("");
    } else if (e.target.value === "LineOfBusiness") {
      setlineOfBusinessinputText("");
      setshowlineOfBusiness("d-none");
      setlineOfBusinessid(ar);
      setlineOfBusinessName(ar);
      dispatch(storelineOfBusinessIds(ar));
    }  else if (e.target.value === "ProjectType") {
      setprojecttypeinputText("");
      setshowprojecttype("d-none");
      setprojecttype(ar);
      setprojecttypeName(ar);
      dispatch(storeProjectTypeids(ar));
    } else if (e.target.value === "ProjectStartDate") {
      setshowprojectStartDate("d-none");
      setProjectStartDateOption("");
      setProjectStartDateMonthValue(0);
      dispatch(storeProjectStartDateOption(""));
      dispatch(storeProjectStartDateMonthValue(0));
    } else if (e.target.value === "ProjectEndDate") {
      setshowprojectEndDate("d-none");
      setProjectEndDateOption("");
      setProjectEndDateMonthValue(0);
      dispatch(storeProjectEndDateOption(""));
      dispatch(storeProjectEndDateMonthValue(0));
    } else if (e.target.value === "ProjectView") {
      setshowprojectView("d-none");
      setProjectView(false);
      dispatch(storeProjectView(false));
    } else {
      setProjectid([]);
      setProjectName([]);
      dispatch(storeProjects(ar));
      setshowproject("d-none");
      setprojectinputText("");

      setshowpeople("d-none");
      setPeopletid(ar);
      setPeopletName(ar);
      dispatch(storeProjectLead(ar));
      setpeopleinputText("");

      setshowprojectStartDate("d-none");

      setshowprojectEndDate("d-none");

      setCompanyid(ar);
      setCompanyName(ar);
      dispatch(storeProjectCompany(ar));
      setshowcompany("d-none");
      setcompanyinputText("");

      settaskstatus(ar);
      settaskstatusName(ar);
      dispatch(storeTaskStatus(ar));
      setshowtaskstatus("d-none");
      settaskstatusinputText("");

      setprojectstatus(ar);
      setprojectstatusName(ar);
      dispatch(storeProjectStatus(ar));
      setshowprojectstatus("d-none");
      setprojectstatusinputText("");

      setshowlineOfBusiness("d-none");
      setlineOfBusinessid(ar);
      setlineOfBusinessName(ar);
      dispatch(storelineOfBusinessIds(ar));
      setlineOfBusinessinputText("");

      setshowprojectStartDate("d-none");
      setProjectStartDateOption("");
      setProjectStartDateMonthValue(0);

      setshowprojectEndDate("d-none");
      setProjectEndDateOption("");
      setProjectEndDateMonthValue(0);
      setshowprojectView("d-none");
      setProjectView(false);

      dispatch(storeProjectView(false));
      dispatch(storeProjectEndDateOption(""));
      dispatch(storeProjectEndDateMonthValue(0));
      dispatch(storeProjectStartDateOption(""));
      dispatch(storeProjectStartDateMonthValue(0));

      setprojecttypeinputText("");
      setshowprojecttype("d-none");
      setprojecttype(ar);
      setprojecttypeName(ar);
      dispatch(storeProjectTypeids(ar));

      if (filterArray?.companies?.length > 0) {
        setcompanyList(filterArray?.companies);
      }
      if (filterArray?.projects?.length > 0) {
        setProjectList(filterArray?.projects);
      }
    }
  };

  const ChangeFilter = (e: any) => {
    dispatch(setcallApiforfilter(true));
    if (e.target.getAttribute("data-name") === "project") {
      setshowproject("");
      setcurrentClick("Project");
      toggleDropdown("Project")
    } else if (e.target.getAttribute("data-name") === "project_status") {
      setshowprojectstatus("");
      setcurrentClick("project_status");
      toggleDropdown("project_status")
    } else if (e.target.getAttribute("data-name") === "ProjectStartDate") {
      setshowprojectStartDate("");
      setcurrentClick("ProjectStartDate");
      toggleDropdown("ProjectStartDate")
    } else if (e.target.getAttribute("data-name") === "ProjectEndDate") {
      setshowprojectEndDate("");
      setcurrentClick("ProjectEndDate");
      toggleDropdown("ProjectEndDate")
    } else if (e.target.getAttribute("data-name") === "ProjectView") {
      setshowprojectView("");
      setcurrentClick("ProjectView");
      toggleDropdown("ProjectView")
    } else if (e.target.getAttribute("data-name") === "projectlead") {
      setshowpeople("");
      setcurrentClick("project_lead");
      toggleDropdown("project_lead")
    } else if (e.target.getAttribute("data-name") === "task_status") {
      setshowtaskstatus("");
      setcurrentClick("task_status");
      toggleDropdown("task_status")
    } else if (e.target.getAttribute("data-name") === "LineOfBusiness") {
      setshowlineOfBusiness("");
      setcurrentClick("LineOfBusiness");
      toggleDropdown("LineOfBusiness")
    } else if (e.target.getAttribute("data-name") === "ProjectType") {
      setshowprojecttype("");
      setcurrentClick("ProjectType");
      toggleDropdown("ProjectType")
    } else {
      setshowcompany("");
      setcurrentClick("Company");
      toggleDropdown("Company")
    }
  };

  const storeFilterValue = (value: any) => {
    if (value === "Project" || value === "all") {
      dispatch(storeProjects(Projectid));
    }
    if (value === "project_lead" || value === "all") {
      dispatch(storeProjectLead(Peopletid));
    }
    if (value === "Company" || value === "all") {
      dispatch(storeProjectCompany(Companyid));
    }
    if (value === "task_status" || value === "all") {
      dispatch(storeTaskStatus(taskstatusid));
    }
    if (value === "project_status" || value === "all") {
      dispatch(storeProjectStatus(projectstatusid));
    }
    if (value === "LineOfBusiness" || value === "all") {
      dispatch(storelineOfBusinessIds(lineOfBusinessid));
    }
    if (value === "ProjectType" || value === "all") {
      dispatch(storeProjectTypeids(projecttypeid));
    } else if (value === "ProjectStartDate" || value === "all") {
      dispatch(storeProjectStartDateOption(projectStartDateOption));
      dispatch(storeProjectStartDateMonthValue(projectStartDateMonthValue));
    } else if (value === "ProjectEndDate" || value === "all") {
      dispatch(storeProjectEndDateOption(projectEndDateOption));
      dispatch(storeProjectEndDateMonthValue(projectEndDateMonthValue));
    } else if (value === "ProjectView" || value === "all") {
      dispatch(storeProjectView(projectView));
    }
  };

  const resetSelectedItems = (value: any) => {
    let resetArray = ["Project", "project_lead" , "Company", "task_status", "project_status", "LineOfBusiness","ProjectType"];
    if(resetArray.includes("Project")){
      setProjectid(count.projects)
    }
    if(resetArray.includes("project_lead")){
      setPeopletid(count.project_lead)
    }
    if(resetArray.includes("Company")){
      setCompanyid(count.project_company)
    }
    if(resetArray.includes("task_status")){
      settaskstatus(count.task_status)
    }
    if(resetArray.includes("project_status")){
      setprojectstatus(count.project_status)
    }
    if(resetArray.includes("LineOfBusiness")){
      setlineOfBusinessid(count.lineOfBusiness_ids)
    }
    if(resetArray.includes("ProjectType")){
      setprojecttype(count.projecttype_ids)
    }
  };

  const handleChange = (e: any) => {
    dispatch(setcallApiforProjectfilter(true))
    if (e.target.getAttribute("data-id") === "Project") {
      let projectIds: any = [];
      if (e.target.checked) {
        projectIds = [...Projectid, parseInt(e.target.value)];
        setProjectid(projectIds);
      } else {
        projectIds = Projectid.filter(
          (item: any) => item !== parseInt(e.target.value)
        );
        setProjectid(projectIds);
      }

      if (projectIds.length > 0) {
        const newCompanyId = filterArray?.projects
          ?.filter((item: any) => projectIds.includes(item.id))
          ?.map((item: any) => item.companyID);
        const newcompanies = filterArray?.companies?.filter((item: any) =>
          newCompanyId.includes(item.id)
        );
        if (newcompanies.length > 0) {
          const newcompaniesId = newcompanies?.map((item: any) => item.id);
          const updatedIds = Companyid?.filter((pid: any) =>
            newcompaniesId.includes(pid)
          );
          setcompanyList(newcompanies);
          setCompanyid(updatedIds);
        }
      } else {
        setcompanyList(filterArray?.companies);
      }
    } else if (e.target.getAttribute("data-id") === "project_lead") {
      if (e.target.checked) {
        setPeopletid((Peopletid: any) => [
          ...Peopletid,
          parseInt(e.target.value),
        ]);
      } else {
        setPeopletid(
          Peopletid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Company") {
      let companyIds: any = [];
      if (e.target.checked) {
        companyIds = [...Companyid, parseInt(e.target.value)];
        setCompanyid(companyIds);
      } else {
        companyIds = Companyid.filter(
          (item: any) => item !== parseInt(e.target.value)
        );
        setCompanyid(companyIds);
      }

      if (companyIds.length > 0) {
        const newProjects = filterArray?.projects?.filter((item: any) =>
          companyIds.includes(item.companyID)
        );
        if (newProjects.length > 0) {
          const newprojectIds = newProjects?.map((item: any) => item.id);
          const updatedIds = Projectid?.filter((pid: any) =>
            newprojectIds.includes(pid)
          );
          setProjectList(newProjects);
          setProjectid(updatedIds);
        } else {
          setProjectList([]);
        }
      } else {
        setProjectList(filterArray?.projects);
      }
    } else if (e.target.getAttribute("data-id") === "task_status") {
      if (e.target.checked) {
        settaskstatus((taskstatusid: any) => [
          ...taskstatusid,
          parseInt(e.target.value),
        ]);
      } else {
        settaskstatus(
          taskstatusid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "project_status") {
      if (e.target.checked) {
        setprojectstatus((projectstatusid: any) => [
          ...projectstatusid,
          parseInt(e.target.value),
        ]);
      } else {
        setprojectstatus(
          projectstatusid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    } else if (e.target.getAttribute("data-id") === "LineOfBusiness") {
      if (e.target.checked) {
        setlineOfBusinessid((lineOfBusinessid: any) => [
          ...lineOfBusinessid,
          parseInt(e.target.value),
        ]);
      } else {
        setlineOfBusinessid(
          lineOfBusinessid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    }  else if (e.target.getAttribute("data-id") === "ProjectType") {
      if (e.target.checked) {
        setprojecttype((projecttypeid: any) => [
          ...projecttypeid,
          parseInt(e.target.value),
        ]);
      } else {
        setprojecttype(
          projecttypeid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    }
  };

  const handleDateChange = (option, monthValue) => {
    if (option && monthValue) {
      if (currentClick === 'ProjectStartDate') {
        setProjectStartDateOption(option)
        setProjectStartDateMonthValue(monthValue)
        dispatch(storeProjectStartDateMonthValue(monthValue))
        dispatch(storeProjectStartDateOption(option))
      }
      else if (currentClick === 'ProjectEndDate') {
        setProjectEndDateOption(option)
        setProjectEndDateMonthValue(monthValue)
        dispatch(storeProjectEndDateMonthValue(monthValue))
        dispatch(storeProjectEndDateOption(option))
      }
    }
  }

  const handleViewChange = (option) => {
      setProjectView(option)
    dispatch(storeProjectView(option))
  }


  let inputHandler = (e: any) => {
    var lowerCase = e.target.value.toLowerCase();
    if (e.target.id === "project_lead-search") {
      setpeopleinputText(lowerCase);
    } else if (e.target.id === "Company-search") {
      setcompanyinputText(lowerCase);
    } else if (e.target.id === "Project-search") {
      setprojectinputText(lowerCase);
    } else if (e.target.id === "task_status-search") {
      settaskstatusinputText(lowerCase);
    } else if (e.target.id === "project_status-search") {
      setprojectstatusinputText(lowerCase);
    } else if (e.target.id === "LineOfBusiness-search") {
      setlineOfBusinessinputText(lowerCase);
    } else if (e.target.id === "ProjectType-search") {
      setprojecttypeinputText(lowerCase);
    }
  };

  const selectAll = (e: any) => {
    dispatch(setcallApiforProjectfilter(true));
    if (e.target.id === "Project") {
      if (e.target.checked) {
        const selectallid = projectlist
          ?.map((x: any) => parseInt(x.id));
        setProjectid(selectallid);
      } else {
        setProjectid([]);
      }
      setcompanyList(filterArray?.companies);
    } else if (e.target.id === "project_lead") {
      if (e.target.checked) {
        const selectallid = filterArray?.peoples
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setPeopletid(selectallid);
      } else {
        setPeopletid([]);
      }
    } else if (e.target.id === "Company") {
      if (e.target.checked) {
        const selectallid = companylist
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setCompanyid(selectallid);
      } else {
        setCompanyid([]);
      }
      setProjectList(filterArray?.projects);
    } else if (e.target.id === "task_status") {
      if (e.target.checked) {
        const selectallid = filterArray?.task_status
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        settaskstatus(selectallid);
      } else {
        settaskstatus([]);
      }
    } else if (e.target.id === "project_status") {
      if (e.target.checked) {
        const selectallid = filterArray?.project_status
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setprojectstatus(selectallid);
      } else {
        setprojectstatus([]);
      }
    } else if (e.target.id === "LineOfBusiness") {
      if (e.target.checked) {
        const selectallid = filterArray?.lineOfBusiness?.data
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setlineOfBusinessid(selectallid);
      } else {
        setlineOfBusinessid([]);
      }
    } else if (e.target.id === "ProjectType") {
      if (e.target.checked) {
        const selectallid = filterArray?.projectTypes
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setprojecttype(selectallid);
      } else {
        setprojecttype([]);
      }
    }
  };

  useEffect(() => {
    if (!filterArray?.isfetchlayoutandfilters) {
      let savedPreferences = loadFromLocalStorage('project_layout');
      let savedPreferencesproject = savedPreferences?.project_filter || filterArray?.layoutandfilters?.project_filter;
      const projectFilter = Object.keys(savedPreferencesproject || "") || []
      if (projectFilter?.length === 0 && !filterArray?.isfetchprojectstatus) {
        clearAllFilter(false);
        dispatch(setcallApiforProjectfilter(true));
        let status_id = filterArray?.project_status
        ?.filter((s: any) => parseInt(s.value) !== 0 && s.isActive === true)
        ?.map((x: any) => parseInt(x.id))
        setprojectstatus(status_id);
        const projectStatusArray = filterArray?.project_status
        .filter((item: { id: any }) =>
          status_id.includes(item.id)
        )
        .map((item: { name: any }) => item.name);
        setprojectstatusName(projectStatusArray);
        dispatch(storeProjectStatus(
          filterArray?.project_status
            ?.filter((s: any) => parseInt(s.value) !== 0 && s.isActive === true)
            ?.map((x: any) => parseInt(x.id))
        ))
        setshowprojectstatus("");
        setcurrentClick("project_status");
        setProjectView(true)
        dispatch(storeProjectView(true))
        setshowprojectView("");
        setHighlightProjectView(true);
        setcurrentClick("ProjectView");
      }
      
    }
  }, [filterArray?.isfetchprojectstatus, filterArray?.isfetchlayoutandfilters]);

  useEffect(() => {
    if (filterArray?.isfetchlayoutandfilters) {
        clearAllFilter(false);
    }
  }, [filterArray?.isfetchlayoutandfilters]);

  useEffect(() => {

    if (
      !filterArray?.isfetchlayoutandfilters &&
      !filterArray?.isCompanyFetch &&
      !filterArray?.isPeopleFetch &&
      !filterArray?.isProjectFetch &&
      !filterArray?.isfetchstatus &&
      !filterArray?.isfetchprojectstatus &&
      !filterArray?.islineOfBusinessFetch 
    ) {
      if (filterArray?.projects && filterArray?.projects?.length > 0) {
        setProjectList(filterArray?.projects);
      }

      if (filterArray?.companies && filterArray?.companies?.length > 0) {
        setcompanyList(filterArray?.companies);
      }
      let savedPreferences = loadFromLocalStorage('project_layout');
      let savedPreferencesproject = savedPreferences?.project_filter || filterArray?.layoutandfilters?.project_filter;
      let data = JSON.parse(JSON.stringify(savedPreferencesproject));
      let callApiforProjectfilter = false;
      if (data?.project_status && data?.project_status?.length === 0) {
        setprojectstatus([]);
        dispatch(storeProjectStatus([]))
        setshowprojectstatus("d-none");
      } else if (data?.project_status?.length > 0) {
        const ids1 = filterArray?.project_status
          ?.filter((s: any) => s.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        const filterStatus = data?.project_status?.filter((item: any) =>
          ids1?.includes(item)
        );
        if(filterStatus?.length > 0) {
          setprojectstatus(filterStatus);
          dispatch(storeProjectStatus(filterStatus))
          setshowprojectstatus("");
          setcurrentClick("project_status");
        }else{
          callApiforProjectfilter = true;
        }
      }
      if (data?.companies?.length > 0) {
        let ids1 = filterArray?.companies?.map((item1: any) =>
          parseInt(item1?.id)
        );
        const filteredcompany = data?.companies?.filter((id: any) =>
          ids1?.includes(id)
        );
        if(filteredcompany?.length > 0){
          setCompanyid(filteredcompany);
          dispatch(storeProjectCompany(filteredcompany))
          setshowcompany("");
        }else{
          callApiforProjectfilter = true;
        }

        const newProjects = filterArray?.projects?.filter((item: any) =>
          filteredcompany.includes(item.companyID)
        );
        if (newProjects?.length > 0) {
          setProjectList(newProjects);
        }
      }

      if (data?.projects?.length > 0) {
        let ids1 = filterArray?.projects?.map((item1: any) =>
          parseInt(item1?.id)
        );
        const filteredprojects = data?.projects?.filter((id: any) =>
          ids1?.includes(id)
        );
        if (filteredprojects?.length > 0) {
          setProjectid(filteredprojects);
          dispatch(storeProjects(filteredprojects))
          setshowproject("");
        }else{
          callApiforProjectfilter = true;
        }
        
        const newCompanies = filterArray?.projects
          ?.filter((item: any) => filteredprojects.includes(item.id))
          ?.map((item: any) => item.companyID);
        if (newCompanies?.length > 0) {
          setcompanyList(
            filterArray?.companies?.filter((item: any) =>
              newCompanies.includes(item.id)
            )
          );
        }
      }

      if (data?.project_leads?.length > 0) {
        let ids1 = filterArray?.peoples
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filteredpeople = data?.project_leads?.filter((id: any) =>
          ids1?.includes(id)
        );
        if(filteredpeople?.length > 0){
          setPeopletid(filteredpeople);
          dispatch(storeProjectLead(filteredpeople))
          setshowpeople("");
        }else{
          callApiforProjectfilter = true;
        }
      }
      if (data?.task_status?.length > 0) {
        let ids1 = filterArray?.task_status
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((item1: any) => parseInt(item1?.id));
        const filterTaskStatus = data?.task_status?.filter((id: any) =>
          ids1?.includes(id)
        );
        if(filterTaskStatus?.length > 0){
          settaskstatus(filterTaskStatus);
          dispatch(storeTaskStatus(filterTaskStatus))
          setshowtaskstatus("");
        }else{
          callApiforProjectfilter = true;
        }
      }
      if (data?.project_type?.length > 0) {
        let ids1 = filterArray?.projectTypes?.filter((twork: any) => twork.isActive === true)?.map((item1: any) => parseInt(item1?.id));
        const filterprojecttype = data?.project_type?.filter((id: any) =>
          ids1?.includes(id)
        );
        if(filterprojecttype?.length > 0){
          setprojecttype(filterprojecttype);
          dispatch(storeProjectTypeids(filterprojecttype))
          setshowprojecttype("");
        }else{
          callApiforProjectfilter = true;
        }
      }
      if (data?.lineOfBusiness?.length > 0) {
        setlineOfBusinessid(data?.lineOfBusiness);
        dispatch(storelineOfBusinessIds(data?.lineOfBusiness))
        setshowlineOfBusiness("");
      }

      if (data?.project_start_date_month_value && data?.project_start_date_option) {
        setProjectStartDateMonthValue(data?.project_start_date_month_value)
        setProjectStartDateOption(data?.project_start_date_option)
        dispatch(storeProjectStartDateMonthValue(data?.project_start_date_month_value))
        dispatch(storeProjectStartDateOption(data?.project_start_date_option))
        setshowprojectStartDate("");
        setHighlightProjectStartDate(true);
      }
      if (data?.project_end_date_month_value && data?.project_end_date_option) {
        setProjectEndDateMonthValue(data?.project_end_date_month_value)
        setProjectEndDateOption(data?.project_end_date_option)
        dispatch(storeProjectEndDateMonthValue(data?.project_end_date_month_value))
        dispatch(storeProjectEndDateOption(data?.project_end_date_option))
        setshowprojectEndDate("");
        setHighlightProjectEndDate(true);
      }
      if (data?.project_view) {
        setProjectView(data?.project_view)
        dispatch(storeProjectView(data?.project_view))
        setshowprojectView("");
        setHighlightProjectView(true);
      }
      if(callApiforProjectfilter){
        dispatch(setcallApiforProjectfilter(true));
      }
    }

  }, [filterArray?.isfetchlayoutandfilters, filterArray?.isCompanyFetch, filterArray?.isPeopleFetch, filterArray?.isfetchstatus,
  filterArray?.isfetchprojectstatus, filterArray?.islineOfBusinessFetch, filterArray?.isProjectFetch, filterArray?.projects, filterArray?.companies,
  filterArray?.project_end_date_month_value,
  filterArray?.project_end_date_option,
  filterArray?.project_start_date_month_value,
  filterArray?.project_start_date_option,
  filterArray?.project_view,
  filterArray?.layoutandfilters?.project_filter, filterArray?.project_status, filterArray?.peoples, filterArray?.task_status]);

  useEffect(() => {
    if (
      !filterArray?.isfetchlayoutandfilters &&
      !filterArray?.isCompanyFetch &&
      !filterArray?.isPeopleFetch &&
      !filterArray?.isProjectFetch &&
      !filterArray?.isfetchstatus &&
      !filterArray?.isfetchprojectstatus &&
      !filterArray?.islineOfBusinessFetch
    ) {
        const projectStatusArray = filterArray.project_status
        .filter((item: { id: any }) =>
          projectstatusid.includes(item.id)
        )
        .map((item: { name: any }) => item.name);
        setprojectstatusName(projectStatusArray);

        const companyNameArray = filterArray.companies
          .filter((item: { id: any }) => Companyid.includes(item.id))
          .map((item: { name: any }) => item.name);
        setCompanyName(companyNameArray);

        const projectNameArray = filterArray.projects
          .filter((item: { id: any }) => Projectid.includes(item.id))
          .map((item: { name: any }) => item.name);
        setProjectName(projectNameArray);

        const projectLeadArray = filterArray.peoples
          .filter((item: { id: any }) =>
            Peopletid.includes(item.id)
          )
          .map((item: { name: any }) => item.name);
        setPeopletName(projectLeadArray);

        const taskStatusArray = filterArray.task_status
          .filter((item: { id: any }) => taskstatusid.includes(item.id))
          .map((item: { name: any }) => item.name);
        settaskstatusName(taskStatusArray);
      
        const lineOfArray = filterArray?.lineOfBusiness?.data
          .filter((item: { id: any }) =>
            lineOfBusinessid.includes(item.id)
          )
          .map((item: { name: any }) => item.name);
        setlineOfBusinessName(lineOfArray);

        const projecttypeArray = filterArray?.projectTypes?.filter((item: { id: any }) =>
            projecttypeid.includes(item.id)
          )?.map((item: { name: any }) => item.name);
        setprojecttypeName(projecttypeArray);
    }
  }, [
    lineOfBusinessid, taskstatusid, Peopletid, Projectid, Companyid, projectstatusid, projecttypeid
  ]);

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id:any, reset = "true") => {
    if(reset === "true"){
      resetSelectedItems(id)
    }
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const renderProjectFilterTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
        Use the Project Screen Filters to view Projects and Tasks based on Company, Project Lead, Status, etc.
        </div>
      </div>
    </Popover>
  )
  return (
    <>
      <div className="dropDown d-flex align-items-center">
        <button
          onClick={() => toggleDropdown("main-project-filter")}
          className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-flex align-items-center"
          type="button"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-flip="bottom"
          id="project-filters"
        >
          <KTSVG
            path="/media/icons/duotune/new-icons/filter.svg"
            className="me-2"
            stylecode={{ color: "inherit" }}
            svgClassName="w-16px h-14px"
          />
          Filter
        </button>
        <div className="h-32px d-flex align-items-start">
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom-end"
            overlay={renderProjectFilterTooltip}
          >
            <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
          </OverlayTrigger>
        </div>
        {(showproject == "" ||
          showpeople == "" ||
          showcompany == "" ||
          showtaskstatus == "" ||
          showprojectstatus == "" ||
          showprojectView == "" ||
          showlineOfBusiness == "" ||
          showprojectStartDate == '' ||
          showprojectEndDate == '' || showprojecttype == "") && (
            <OverlayTrigger
              key="copy-to-clipboard"
              trigger={["hover", "focus"]}
              placement="bottom"
              overlay={
                <Tooltip
                  id="tooltip-copy-to-clipboard"
                  className="position-absulute"
                >
                  <span className="pe-2">Clear all</span>
                </Tooltip>
              }
            >
              <span>
                <button
                  value="All"
                  onClick={hideSelection}
                  className="p-0 bg-transparent border-0 w-auto h-auto ms-3 me-5"
                >
                  <KTSVG
                    path="/media/icons/duotune/extra/close.svg"
                    className="text-primary pe-none"
                    svgClassName="w-10px h-10px"
                  />
                </button>
              </span>
            </OverlayTrigger>
          )}
        {openDropdown === "main-project-filter" && 
        <div
          className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0"
          data-kt-menu="true"
        >
          <a
            onClick={ChangeFilter}
            data-name="projectlead"
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/resources.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project Lead
          </a>

          <a
            onClick={ChangeFilter}
            data-name="project_status"
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/mouse-square.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project Status
          </a>

          <a
            data-name="company"
            onClick={ChangeFilter}
            className="d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap"
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/company.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Company
          </a>

          <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="project"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/project.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project
          </a>
            <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="ProjectStartDate"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/calendar.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project start date
          </a>
           <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="ProjectEndDate"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/calendar.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project end date
          </a>
          <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary"
            data-name="ProjectView"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/calendar.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
              Project has items in current view
            </a>     
          <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="task_status"
            onClick={ChangeFilter}
            href="#"
            > 
            <KTSVG
              path="/media/icons/duotune/new-icons/service-call-center.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Task status
          </a>
          <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="LineOfBusiness"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/note.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Line of business
          </a>
          <a
            className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
            data-name="ProjectType"
            onClick={ChangeFilter}
            href="#"
          >
            <KTSVG
              path="/media/icons/duotune/extra/note.svg"
              stylecode={{ color: "#164387" }}
              className="w-20px me-1"
              svgClassName="w-16px h-auto"
            />
            Project Type
          </a>
        </div>
        }
      </div>
      {showpeople === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/profile-user-2.svg"
          inputHandler={inputHandler}
          inputText={peopleinputText}
          currentClick={currentClick}
          checked_ids={Peopletid}
          title="Project Lead"
          id="project_lead"
          data={filterArray?.peoples?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={false}
          showdropdown={showpeople}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={PeopletName}
          selectAll={selectAll}
          tooltipTitle="Filter with project lead."
          isOpen={openDropdown === 'project_lead'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showcompany === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/profile-user-1.svg"
          inputHandler={inputHandler}
          inputText={companyinputText}
          currentClick={currentClick}
          checked_ids={Companyid}
          title="Company"
          data={companylist?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isCompanyFetch}
          showdropdown={showcompany}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={CompanyName}
          selectAll={selectAll}
          tooltipTitle="Filter with company."
          id="Company"
          isOpen={openDropdown === 'Company'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showproject === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/note.svg"
          inputHandler={inputHandler}
          inputText={projectinputText}
          currentClick={currentClick}
          checked_ids={Projectid}
          title="Project"
          id="Project"
          data={projectlist}
          isLoading={filterArray?.isProjectFetch}
          // isLoading={false}
          showdropdown={showproject}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={ProjectName}
          selectAll={selectAll}
          tooltipTitle="Filter with project."
          isOpen={openDropdown === 'Project'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showprojectStartDate === "" && <DropDown
        imgpath="/media/icons/duotune/extra/calendar.svg"
        currentClick={currentClick}
        title="Project start date"
        id="ProjectStartDate"
        hasMonthValue={projectStartDateMonthValue}
        hasSelectedOption={projectStartDateOption}
        showdropdown={showprojectStartDate}
        storeFilterValue={storeFilterValue}
        hideSelection={hideSelection}
        handleDateChange={handleDateChange}
        isHighlited={highlightProjectStartDate}
        tooltipTitle="Filter with project start date."
        isOpen={openDropdown === 'ProjectStartDate'}
        toggleDropdown={toggleDropdown}
      ></DropDown>}

      {showprojectEndDate === "" && <DropDown
        imgpath="/media/icons/duotune/extra/calendar.svg"
        currentClick={currentClick}
        title="Project end date"
        id="ProjectEndDate"
        showdropdown={showprojectEndDate}
        storeFilterValue={storeFilterValue}
        hideSelection={hideSelection}
        handleDateChange={handleDateChange}
        hasMonthValue={projectEndDateMonthValue}
        hasSelectedOption={projectEndDateOption}
        isHighlited={highlightProjectEndDate}
        isOpen={openDropdown === 'ProjectEndDate'}
        tooltipTitle="Filter with project end date."
        toggleDropdown={toggleDropdown}
      ></DropDown>}

      {showprojectView === "" && <DropDown
        imgpath="/media/icons/duotune/extra/calendar.svg"
        currentClick={currentClick}
        title="Project has items in current view "
        id="ProjectView"
        showdropdown={showprojectView}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        isHighlited={highlightProjectView}
        projectView={projectView}
        handleViewChange={handleViewChange}
        tooltipTitle="Filter with project has items in current view."
        isOpen={openDropdown === 'ProjectView'}
        toggleDropdown={toggleDropdown}
      ></DropDown>}

      {showtaskstatus === "" && <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={taskstatusinputText}
        currentClick={currentClick}
        checked_ids={taskstatusid}
        title="Task Status"
        id="task_status"
        data={filterArray?.task_status?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isfetchstatus}
        showdropdown={showtaskstatus}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={taskstatusName}
        selectAll={selectAll}
        tooltipTitle="Filter with task status."
        isOpen={openDropdown === 'task_status'}
        toggleDropdown={toggleDropdown}
      ></DropDown>}

      {showprojectstatus === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/note.svg"
          inputHandler={inputHandler}
          inputText={projectstatusinputText}
          currentClick={currentClick}
          checked_ids={projectstatusid}
          title="Project Status"
          id="project_status"
          data={filterArray?.project_status?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isfetchstatus}
          showdropdown={showprojectstatus}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={projectstatusName}
          selectAll={selectAll}
          tooltipTitle="Filter with project status."
          isOpen={openDropdown === 'project_status'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showlineOfBusiness === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/note.svg"
          inputHandler={inputHandler}
          inputText={lineOfBusinessinputText}
          currentClick={currentClick}
          checked_ids={lineOfBusinessid}
          title="Line of business"
          id="LineOfBusiness"
          data={filterArray?.lineOfBusiness?.data?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.islineOfBusinessFetch}
          showdropdown={showlineOfBusiness}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={lineOfBusinessName}
          selectAll={selectAll}
          tooltipTitle="Filter data on their line of business."
          isOpen={openDropdown === 'LineOfBusiness'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showprojecttype === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/note.svg"
          inputHandler={inputHandler}
          inputText={projecttypeinputText}
          currentClick={currentClick}
          checked_ids={projecttypeid}
          title="Project Type"
          id="ProjectType"
          data={filterArray?.projectTypes?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isProjectTypeFetch}
          showdropdown={showprojecttype}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={projecttypeName}
          selectAll={selectAll}
          tooltipTitle="Filter data on their project type."
          isOpen={openDropdown === 'ProjectType'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
    </>
  );
};

export default FilterMain;
