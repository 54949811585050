import axios, { AxiosResponse } from "axios";
import { Response } from "../helpers";
import {
  GeneralSetting,
  GeneralSettingQueryResponse,
} from "../../app/modules/apps/settings/general/_models";
const API_URL = process.env.REACT_APP_THEME_API_URL;

const API_URL_NODE = process.env.REACT_APP_API_URL;
const GENERALSETTING_URL_NODE = `${API_URL_NODE}settings/general`;
const SUBSCRIPTIONLIST = `${API_URL_NODE}subscription/pricing`;
const CHECK_SECRET_URL = `${API_URL_NODE}auth/validate_organization_credentials`;
const CHECK_Card_URL = `${API_URL_NODE}superadmin/organisations/attach-card-customer`;
const Update_Card_URL = `${API_URL_NODE}subscription/payment`;
const GET_LOCATION_URL = `${API_URL_NODE}settings/location`;
const getGeneralSetting = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${GENERALSETTING_URL_NODE}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const checkValidCredentials = (query: any): Promise<any | undefined> => {

  return axios
    .post(`${CHECK_SECRET_URL}`,query)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const attachCardtocustomer = async (id: any,price:any,organization_id:any): Promise<any | undefined> => {
  let updatedOrganisation:any = { payment_method:id,organization_id:organization_id };          
  if(price !== ''){
      updatedOrganisation.price = price;
  }
  const response = await axios
        .post(`${CHECK_Card_URL}
            `, updatedOrganisation).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const updateGeneralSetting = (generalSetting: GeneralSetting): Promise<GeneralSetting | undefined> => {
  return axios
    .post(`${GENERALSETTING_URL_NODE}`, generalSetting)
    .then((response: AxiosResponse<Response<GeneralSetting>>) => response.data)
    .then((response: Response<GeneralSetting>) => response.data);
};

const createGeneralSetting = (generalSetting: GeneralSetting): Promise<GeneralSetting | undefined> => {
  return axios
    .post(GENERALSETTING_URL_NODE, generalSetting)
    .then((response: AxiosResponse<Response<GeneralSetting>>) => response.data)
    .then((response: Response<GeneralSetting>) => response.data);
};

const getSubscriptionList = (organizationData: any): Promise<any | undefined> => {
  return axios
    .post(`${SUBSCRIPTIONLIST}`,organizationData)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const updateCardHolderName = async (id: any,name:any): Promise<any | undefined> => {
  const response = await axios
        .post(`${Update_Card_URL}
            `, {payment_method:id,name:name}).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const getCutomerCard = async (): Promise<any | undefined> => {
  const response = await axios
        .get(`${Update_Card_URL}
            `).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};




const getLocationDetails = async (): Promise<any | undefined> => {
  const response = await axios
        .get(`${GET_LOCATION_URL}
            `).then((response) => {
            return response?.data;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

export {
  getGeneralSetting,
  createGeneralSetting,
  updateGeneralSetting,
  checkValidCredentials,
  attachCardtocustomer,
  getSubscriptionList,
  getCutomerCard,
  getLocationDetails, 
  updateCardHolderName
};
