import moment from 'moment';
import {FC, useState} from 'react'
import { ApiLog } from '../../../core/_model';


type Props = {
  user: ApiLog,
}

const ApiLogDateCell: FC<Props> = ({user}) => {
 return (
  <>
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{moment(user?.created_at).tz(localStorage.getItem('timezone')!).format("DD-MM-YYYY " + localStorage.getItem('time_format')!)}{}</span>
    </div>

  </div>
        </>
)}

export {ApiLogDateCell}
