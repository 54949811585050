import React, { useState, useCallback, FC, useMemo, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Select from "react-select";
import { Popup, confirm, toast } from "@mobiscroll/react";
import moment from "moment-timezone";
import {
  createPopupvalues,
  deleteServicecall,
  getTaskDetailCapsule,
  updatePopupvalues,
} from "../../../_metronic/requests/PlanningRequest";
import { AUTOTASK_ENTITY } from "../../constants/global";
import debounce from "../../helpers/debounce";
import { responsivePopup } from "../../helpers/popup";
import { checkBufferHours, relatedResources, splitedEventCall } from "../../helpers/servicecall";
import { hasOverlap } from "../../modules/apps/project/util/projectview";
import SelectCustomLockOption, { formatOptionLabel } from "./selectCustomLockOption";
import TaskTicketList from "./TaskTicketList";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CustomDatePicker from "../../helpers/customDatePicker";
type Props = {
  istooltipOpen: any;
  isEventDetailPopup: any;
  eventDetail: any;
  screenName: "project" | "planning";
  ontooptipClose: any;
  general_settings: any;
  timezone: any;
  seteventDetail: any;
  splitEventsFn: any;
  splitEventData: any;
  tempEvent: any;
  settempEvent: any;
  onEventClick: any;
  settempArgsEvent: any;
  onEventUpdate: any;
  tempArgsEvent: any;
  planningTask: any;
  settooltipOpen: any;
  setistaskupdated: any;
  isValidData: any;
  istaskupdated: any;
  issueslist: any;
  subIssueslist: any;
  worktype: any;
  prioritylist: any;
  time_format: any;
  onClose: any;
  todo_status_serviceCall: any;
  resource_settings: any;
  servicecallStatus: any;
  task_status: any;
  ticket_status: any;
  setPlanningTask: any;
  refetchMonthdata: any;
  toplanrefetch: any;
  view?:any,
  monthEvent?:any,
  setMonthEvent?:any,
  setexistServicecall?:any,
  existServicecall?:any,
  refetchPlanning?:any,
  openDropdown?:any,
  setOpenDropdown?:any,
  toggleDropdown?:any,
  filterArray?:any
  locationShow?: any;
  locations?:any;
  filterIds?:any;
  getNrDays?:any;
};

const ServicecallUpdate: FC<Props> = ({
  istooltipOpen,
  isEventDetailPopup,
  eventDetail,
  screenName,
  setPlanningTask,
  general_settings,
  timezone,
  seteventDetail,
  splitEventsFn,
  splitEventData,
  tempEvent,
  settempEvent,
  onEventClick,
  settempArgsEvent,
  onEventUpdate,
  tempArgsEvent,
  planningTask,
  settooltipOpen,
  setistaskupdated,
  istaskupdated,
  issueslist,
  subIssueslist,
  worktype,
  prioritylist,
  time_format,
  onClose,
  todo_status_serviceCall,
  resource_settings,
  task_status,
  ticket_status,
  refetchMonthdata,
  toplanrefetch,
  view,
  monthEvent,
  setMonthEvent,
  setexistServicecall,
  existServicecall,
  refetchPlanning,
  openDropdown,
  setOpenDropdown,
  toggleDropdown,
  filterArray,
  locationShow,
  locations,
  filterIds
}) => {
  const startvalue = eventDetail.start;
  const endvalue = eventDetail.end  ; 
  const [errorStartDate, seterrorStartDate] = useState("");
  const [errorEndDate, seterrorEndDate] = useState("");
  const [isfirstOpen, setisfirstOpen] = useState(true);
  let hideOptions = general_settings?.hide_options;
  const [isValidData, setValidData] = useState<any>(false);
  let servicecallStatus = JSON.parse(JSON.stringify(todo_status_serviceCall));
  const [showDropdown, setShowDropdown] = useState(false);
  const [taskList, setTaskList] = useState(false);
  const [taskListType, setTaskListType] = useState("");
  let [taskTicketId, setTaskTicketid] = useState<any>([])
  const [showTaskDropdown, setShowTaskDropdown] = useState<{ label: string, id: any } | null>(null);
  
  servicecallStatus.unshift({
    label: "Select status for ticket",
    value: "",
  });

  let taskRoleArrayWithDepartment: any = [];
  let taskRoleArray: any = [];
  let ServiceCallResources: any = [];
  let tasksacendaryRoleArray: any = [];
  let TicketRoleArray: any = [];

  resource_settings?.data?.filter((el: any) => {
    if (el.task_roles && el.task_roles.length > 0) {
      {
        el.task_roles?.map((et: any) => {
          let itemtask: any = {};
          var str = et.text;
          str = str.replace(/ \([\s\S]*?\)/g, "");
          itemtask.resourceID = el.id;
          itemtask.label =
            el.firstName + " " + el?.lastName + " (" + et.text + ")";
          itemtask.departmentID = et.departmentID;
          itemtask.roleID = et.value;
          itemtask.value = parseInt(el.id.toString() + et.value.toString());
          itemtask.isActive = el.isActive;
          itemtask.isDisabled = !el.isActive;
          itemtask.has_license = el.has_license || el.resource_only;
          itemtask.database_id = el.database_id;
          itemtask.resource_only = el.resource_only;
          itemtask.userName = el.username;
          taskRoleArrayWithDepartment.push(itemtask);
        });

        taskRoleArrayWithDepartment.filter(function (item: any) {
          let i = tasksacendaryRoleArray.findIndex(
            (x: any) =>
              x.roleID === item.roleID && x.resourceID === item.resourceID
          );
          if (i <= -1) {
            tasksacendaryRoleArray.push(item);
            taskRoleArray.push(item);
          }
          return null;
        });
      }
    }
    if (el.ticket_roles && el.ticket_roles.length > 0) {
      {
        el.ticket_roles?.map((eticket: any) => {
          let itemticket: any = {};
          itemticket.resourceID = el.id;
          itemticket.label =
            el.firstName + " " + el?.lastName + " (" + eticket.text + ")";
          itemticket.departmentID = eticket.departmentID;
          itemticket.roleID = eticket.value;
          itemticket.value = parseInt(
            el.id.toString() + eticket.value.toString()
          );
          itemticket.isActive = el.isActive;
          itemticket.isDisabled = !el.isActive;
          itemticket.has_license = el.has_license || el.resource_only;
          itemticket.database_id = el.database_id;
          itemticket.resource_only = el.resource_only;
          itemticket.userName = el.username;
          TicketRoleArray.push(itemticket);
        });
      }
    }
    let serviceResource: any = {};
    serviceResource.label = el.firstName + " " + el?.lastName;
    serviceResource.value = el.id;
    serviceResource.has_license = el.has_license || el.resource_only;
    serviceResource.userName = el.username;
    serviceResource.isActive = el.isActive;
    serviceResource.database_id = el.database_id;
    serviceResource.resource_only = el.resource_only;
    serviceResource.isDisabled = !el.isActive;
    ServiceCallResources.push(serviceResource);
  });

  const handleHideOptionalFunc = (item: any, value: any) => {
    const options = !hideOptions
      ? item
      : item?.filter(
          (trole: any) =>
            parseInt(trole.value) === parseInt(value) || trole.isActive === true
        );

    return options;
  };

  const changeStartDate = async (ev: any) => {
    setShowDropdown(false);
    setShowTaskDropdown(null)
    toggleDropdown("")
    seterrorStartDate("");
    seterrorEndDate("");
    let defaultstart = moment
      .tz(
        moment(ev.value).tz(timezone).format("YYYY-MM-DDT") +
        general_settings?.start_time,
        timezone
      )
      .utc()
      .format("YYYY-MM-DDTHH:mm");
      let start = moment
      .tz(moment(ev.value).format("YYYY-MM-DDTHH:mm"), timezone)
      .tz(timezone)
      .utc()
      .format("YYYY-MM-DDTHH:mm");
      let event = JSON.parse(JSON.stringify(eventDetail));
      const beforeStartDate =  moment
      .utc(startvalue)
      .format("YYYY-MM-DDTHH:mm");
      event.start = start + "Z";
    let duration = moment.duration(moment(event.end).diff(moment(event.start)));
    event.planned = duration.asHours();

    if (duration.asHours() < 0) {
      //seterrorStartDate('Please provide a start date less than end date.')
    } else {
      if (
        moment(start).isBefore(defaultstart) &&
        moment(event.start).utc().format("YYYY-MM-DD") !==
          moment(event.end).utc().format("YYYY-MM-DD")
      ) {
        event.start = defaultstart + "Z";
      }
      seterrorStartDate("");
      if (duration.asHours() > 730) {
        seterrorStartDate("You can not plan servicecall more than one month.");
        event.start = beforeStartDate + "Z";
        seteventDetail(event);
        setTimeout(() => {
          seterrorStartDate("");
        }, 3000);
      } else {
        if (
          general_settings?.allow_overlapping === false &&
          hasOverlap(event, false, planningTask)
        ) {
          toast({
            message: "Overlapping tasks is not allowed",
            display: "center",
          });
        } else {
          let valid: any = await checkBufferHours(
            event,
            general_settings?.start_time,
            general_settings?.end_time,
            general_settings?.timezone
          );
          if (valid[0]) {
            if(valid[1]){
              event.isOvertimeShow = true;
            }
            seteventDetail(event);
            splitEventsFn(event, "");
            popupvalidation(event);
          }
        }
      }
    }
  };

  const changeEndDate = async (ev: any) => {
    setShowDropdown(false);
    setShowTaskDropdown(null)
    toggleDropdown("")
    seterrorStartDate("");
    seterrorEndDate("");
    let end = moment
      .tz(moment(ev.value).format("YYYY-MM-DDTHH:mm"), timezone)
      .tz(timezone)
      .utc()
      .format("YYYY-MM-DDTHH:mm");
    let event = JSON.parse(JSON.stringify(eventDetail));
    const beforeEndDate =   moment
    .utc(endvalue)
    .format("YYYY-MM-DDTHH:mm");
    event.end = end + "Z";

    let defaultend = moment
      .tz(
        moment(event.end).tz(timezone).format("YYYY-MM-DDT") +
          general_settings?.end_time,
        timezone
      )
      .utc()
      .format("YYYY-MM-DDTHH:mm");
    let duration = moment.duration(moment(event.end).diff(moment(event.start)));  
    event.planned = duration.asHours();
    if (event.planned > 0) {
      if (duration.asHours() > 730) {
        seterrorEndDate("You can not plan servicecall more than one month.");
        event.end = beforeEndDate + "Z";
        seteventDetail(event);
        setTimeout(() => {
          seterrorEndDate("");
        }, 3000);
      } else {
        if (
          general_settings?.allow_overlapping === false &&
          hasOverlap(event, false, planningTask)
        ) {
          toast({
            message: "Overlapping tasks is not allowed",
            display: "center",
          });
        } else {
          if (
            moment(end).isAfter(defaultend) &&
            moment(event.start).utc().format("YYYY-MM-DD") !==
              moment(event.end).utc().format("YYYY-MM-DD")
          ) {
            event.end = defaultend + "Z";
          }
          let valid: any = await checkBufferHours(
            event,
            general_settings?.start_time,
            general_settings?.end_time,
            general_settings?.timezone
          );
          if (valid[0]) {
            if(valid[1]){
              event.isOvertimeShow = true;
            }
            seteventDetail(event);
            splitEventsFn(event, "");
            popupvalidation(event);
          }
          
        }
      }
    }
  };
  const assignpopupfieldchange = (
    e: any,
    id: any,
    type: any,
    field_type: any
  ) => {
    setShowDropdown(false);
    setShowTaskDropdown(null)
    toggleDropdown("")
    let event = JSON.parse(JSON.stringify(eventDetail));
    if (field_type === "servicecall_status") {
      let tstatus = e[e.length - 1];
      if (tstatus) {
        event.servicecall_status = tstatus.value;
        event.servicecall_status_name = tstatus.label;
        event.status = tstatus.label;
      } else {
        event.servicecall_status = "";
        event.servicecall_status_name = "";
        event.status = "";
      }
    }
    if (field_type === "task_status") {
      let tstatus = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.status = tstatus ? tstatus.value : "";
            item1.status_name = tstatus ? tstatus.label : "";
          }
        });
      }
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {
          if (item1.id === id) {
            item1.status = tstatus ? tstatus.value : "";
            item1.status_name = tstatus ? tstatus.label : "";
          }
        });
      }
    }
    if (field_type === "worktype") {
      let tstatus = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.billingCodeID = tstatus ? tstatus.value : "";
          }
        });
      }
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {
          if (item1.id === id) {
            item1.billingCodeID = tstatus ? tstatus.value : "";
          }
        });
      }
      if (event?.related_tasks && event?.related_tasks?.length > 0) {
        event.billing_code_id = event.related_tasks
          ? event.related_tasks[0]?.billingCodeID
          : "";
      } else {
        event.billing_code_id = event?.related_tickets
          ? event.related_tickets[0]?.billingCodeID
          : "";
      }
    }
    if (field_type === "priority") {
      let tpriority = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.priority = tpriority ? tpriority.value : "";
          }
        });
      }
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {
          if (item1.id === id) {
            item1.priority = tpriority ? tpriority.value : "";
          }
        });
      }
    }
    if (field_type === "issue") {
      let issue = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.issueType = issue ? issue.value : "";
            item1.subIssueType = "";
          }
        });
      }
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {
          if (item1.id === id) {
            item1.issueType = issue ? issue.value : "";
            item1.subIssueType = "";
          }
        });
      }
    }
    if (field_type === "subissue") {
      let issue = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.subIssueType = issue ? issue.value : "";
          }
        });
      }
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {
          if (item1.id === id) {
            item1.subIssueType = issue ? issue.value : "";
          }
        });
      }
    }
    if (field_type === "queue") {
      let tqueue = e[e.length - 1];
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {
          if (item1.id === id) {
            item1.queueID = tqueue ? tqueue.value : "";
          }
        });
      }
    }
    if (field_type === "location") {
      let LocationValue = e[e.length - 1];
      if (type === "task") {
        event.related_tasks?.filter((item1: any) => {    
          if (item1.id === id) {
            item1.companyLocationID = LocationValue ? LocationValue?.value : "";
          }
        });
      }
      if (type === "ticket") {
        event.related_tickets?.filter((item1: any) => {    
          if (item1.id === id) {
            item1.companyLocationID = LocationValue ? LocationValue?.value : "";
          }
        });
      }
    }

    if(field_type ==="servicecall_location"){
      let locationValue = e[e.length - 1];   
        event.companyLocationID = locationValue ? locationValue?.value : "";
    }
    if (field_type === "serviceCallLocationVisible") {
      event.serviceCallLocationVisible = e.target.checked === true ? true : false;
      if(event.serviceCallLocationVisible){
        event.companyLocationID = "";
      }
    }

    seteventDetail(event);
    splitEventsFn(event, "");
    popupvalidation(event);
  };

  const assignpopupfieldchangeinput = debounce(
    (e: any, id: any, type: any, field_type: any) => {
      setShowDropdown(false);
      setShowTaskDropdown(null)
      toggleDropdown("")
      let event = JSON.parse(JSON.stringify(eventDetail));
      if (field_type === "servicecall_description") {
        event.description = e.target.value;
        event.title = e.target.value;
        event.servcallTitle = e.target.value;
      }
      if (field_type === "task_description") {
        if (type === "ticket") {
          event.related_tickets?.filter((item1: any) => {
            if (item1.id === id) {
              item1.description = e.target.value;
            }
          });
        } else {
          event.related_tasks?.filter((item1: any) => {
            if (item1.id === id) {
              item1.description = e.target.value;
            }
          });
        }
      }
      if (field_type === "task_title") {
        if (type === "ticket") {
          event.related_tickets?.filter((item1: any) => {
            if (item1.id === id) {
              item1.title = e.target.value;
            }
          });
        } else {
          event.related_tasks?.filter((item1: any) => {
            if (item1.id === id) {
              item1.title = e.target.value;
            }
          });
        }
      }
      seteventDetail(event);
      splitEventsFn(event, "");
      if (field_type === "description" || field_type === "task_description") {
        popupvalidation(event);
      }
    },
    500
  );

  const changeservicecallresource = (e: any) => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    let item: any = [];
    e.filter((el: any) => {
      item.push(parseInt(el.value));
    });
    event.servicecall_resources = item;
    seteventDetail(event);
    popupvalidation(event);
  };

  const changeprimaryresid = (e: any, id: any) => {
    let primary = e[e.length - 1];
    let event = JSON.parse(JSON.stringify(eventDetail));
    event.related_tickets?.filter((item1: any) => {
      if (item1.id === id) {
        event.assigntoresource = event.assigntoresource.filter(
          (r: any) => r !== parseInt(item1.assignedResourceID)
        );
        event.servicecall_resources = event.servicecall_resources.filter(
          (r: any) => r !== parseInt(item1.assignedResourceID)
        );
        if (primary) {
          item1.assignedResourceID = primary.resourceID;
          item1.assignedResourceRoleID = primary.roleID;
          item1.departmentID = primary.departmentID;
          item1.primary = [primary];
          event.assigntoresource.push(parseInt(item1.assignedResourceID));
          event.servicecall_resources.push(parseInt(item1.assignedResourceID));
          item1.secondary_resources = item1.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(item1.assignedResourceID)
          );
        } else {
          item1.assignedResourceID = "";
          item1.assignedResourceRoleID = "";
          item1.departmentID = "";
          item1.primary = [];
        }
        if (item1.errorinDefaultrole === "in_primary") {
          item1.errorinDefaultrole = "";
        }
      } else {
        if (item1.assignedResourceID) {
          event.servicecall_resources.push(parseInt(item1.assignedResourceID));
          event.assigntoresource.push(parseInt(item1.assignedResourceID));
        }
        item1.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
      }
    });
    event.related_tasks?.filter((item2: any) => {
      if (item2.id === id) {
        event.assigntoresource = event.assigntoresource.filter(
          (r: any) => r !== parseInt(item2.assignedResourceID)
        );
        event.servicecall_resources = event.servicecall_resources.filter(
          (r: any) => r !== parseInt(item2.assignedResourceID)
        );
        if (primary) {
          item2.assignedResourceID = primary.resourceID;
          item2.assignedResourceRoleID = primary.roleID;
          item2.departmentID = primary.departmentID;
          item2.primary = [primary];
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
          event.servicecall_resources.push(parseInt(item2.assignedResourceID));
          item2.secondary_resources = item2.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(item2.assignedResourceID)
          );
        } else {
          item2.assignedResourceID = "";
          item2.assignedResourceRoleID = "";
          item2.departmentID = "";
          item2.primary = [];
        }
        if (item2.errorinDefaultrole === "in_primary") {
          item2.errorinDefaultrole = "";
        }
      } else {
        if (item2.assignedResourceID) {
          event.servicecall_resources.push(parseInt(item2.assignedResourceID));
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
        }
        item2.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
      }
      item2.secondary_resources?.filter((secondaryres: any) => {
        if(!item2.departmentID){
          let department = taskRoleArray?.find((x)=>(x.roleID === secondaryres?.roleID && x.resourceID === secondaryres?.resourceID))?.departmentID;
          item2.departmentID = department;
        }
      });
    });
    event.servicecall_resources = event.servicecall_resources?.filter(
      (value: any, index: any, array: any) => array.indexOf(value) === index
    );
    seteventDetail(event);
    if (splitEventData?.length > 0) {
      splitEventsFn(event, "");
    } else {
      popupvalidation(event);
    }
  };
  const assignedresources = (eventDetail:any) => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    event.assigntoresource = [];
    event.related_tickets?.filter((item1: any) => {
      if (item1.assignedResourceID) {
        event.assigntoresource.push(parseInt(item1.assignedResourceID));
      }
      item1.secondary_resources?.filter((secondaryres: any) => {
        event.assigntoresource.push(parseInt(secondaryres.resourceID));
      });
    });
    event.related_tasks?.filter((item2: any) => {
        if (item2.assignedResourceID) {
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
        }
        item2.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
        });
    });
    event.servicecall_resources = event?.servicecall_resources?.filter((value: any) =>
      event?.assigntoresource?.includes(value)
    )
    seteventDetail(event)
    popupvalidation(event)
  }

  const handleSecondaryResourceChange = (e: any, id: any, type: any) => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    let last_record = e[e.length - 1];
    let unique: any;
    if (last_record) {
      unique = e?.filter(
        (selectedresorce: any) =>
          parseInt(selectedresorce.resourceID) !==
          parseInt(last_record.resourceID)
      );
      unique.push(last_record);
    } else {
      unique = [];
    }
    event.servicecall_resources = [];
    event.assigntoresource = [];
    event.related_tickets?.filter((item1: any) => {
      if (item1.id === id) {
        if (item1.assignedResourceID) {
          event.servicecall_resources.push(parseInt(item1.assignedResourceID));
          event.assigntoresource.push(parseInt(item1.assignedResourceID));
        }
        item1.secondary_resources = unique;
        item1.secondary_resources = item1.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(item1.assignedResourceID)
        );
        item1.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
        if (item1.errorinDefaultrole === "in_secondary") {
          item1.errorinDefaultrole = "";
        }
      } else {
        if (item1.assignedResourceID) {
          event.servicecall_resources.push(parseInt(item1.assignedResourceID));
          event.assigntoresource.push(parseInt(item1.assignedResourceID));
        }
        item1.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
      }
    });
    event.related_tasks?.filter((item2: any) => {
      if (item2.id === id) {
        
        if (item2.assignedResourceID) {
          event.servicecall_resources.push(parseInt(item2.assignedResourceID));
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
        }else{
          item2.departmentID = "";
        }
        item2.secondary_resources = unique;
        item2.secondary_resources = item2.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(item2.assignedResourceID)
        );
        item2.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
        if (item2.errorinDefaultrole === "in_secondary") {
          item2.errorinDefaultrole = "";
        }
      } else {
        if (item2.assignedResourceID) {
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
          event.servicecall_resources.push(parseInt(item2.assignedResourceID));
        }
        item2.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
          event.servicecall_resources.push(parseInt(secondaryres.resourceID));
        });
      }
      item2.secondary_resources?.filter((secondaryres: any) => {
        if(!item2.departmentID){
          let department = taskRoleArray?.find((x)=>(x.roleID === secondaryres?.roleID && x.resourceID === secondaryres?.resourceID))?.departmentID
          item2.departmentID = department;
        }
      });
    });
    event.servicecall_resources = event.servicecall_resources?.filter(
      (value: any, index: any, array: any) => array.indexOf(value) === index
    );

    if (splitEventData?.length > 0) {
      splitEventsFn(event, "");
    } else {
      seteventDetail(event);
    }
    popupvalidation(event);
  };

  const copyServicecall = useCallback(() => {
    ontooptipClose("");
    toggleDropdown("")
    setShowDropdown(false);
    setShowTaskDropdown(null)
    let event: any;
    if (isEventDetailPopup) {
      event = JSON.parse(JSON.stringify(eventDetail));
      event.serviceCallId = "";
      event.from_copy = true;
      event.isOvertimeShow = false;
      event.id = (Math.random() + 1).toString(6).substring(6);
      event.split_generate_id = (Math.random() + 1).toString(6).substring(6);
      event.url = "";
      onEventClick(event?.resource, event);
    } else {
      event = tempArgsEvent;
      event.event = JSON.parse(JSON.stringify(eventDetail));
      event.event.from_copy = true;
      event.isOvertimeShow = false;
      event.event.id = (Math.random() + 1).toString(6).substring(6);
      event.event.split_generate_id = (Math.random() + 1)
        .toString(6)
        .substring(6);
      event.event.serviceCallId = "";
      event.event.url = "";
      settempArgsEvent(event);
      onEventUpdate(event, []);
    }
    setisfirstOpen(true);
  }, [eventDetail, tempEvent, tempArgsEvent, isEventDetailPopup]);

  const removeServicecall = useCallback(
    async (ev: any, args: any) => {
      settooltipOpen(false);
      toggleDropdown("")
      setShowDropdown(false);
      setShowTaskDropdown(null)
      let res: any = [];
      res = await deleteServicecall({
        id: args?.entity_id,
        type: args?.entity_type,
        mongo_id: args?.id,
      });
      if (!res || res?.status !== 200) {
        if (res?.length !== 0 && res?.status !== 200) {
          settooltipOpen(false);
        }
        return false;
      } else {

        let planTask: any = [];
        planTask = await planningTask?.filter(
          (item: any) => (item.id !== args.id)
        );
        if (view === "month" || view === "year") {
          let monthData: any = [];
          monthData = await monthEvent?.filter(
            (item: any) => (item.id !== args.id)
          );
          setMonthEvent(monthData);
        }
        setPlanningTask(planTask);
        if(screenName === "planning"){
          refetchMonthdata();
          refetchPlanning();
        }
        return true;
      }
    },
    [planningTask, monthEvent, view]
  );

  const updatePopupEvent = useCallback(async () => {
    let res: any = [];
    let planTask: any;
    let event = JSON.parse(JSON.stringify(eventDetail));
    if (splitEventData && splitEventData.length > 0) {
      let first_event = JSON.parse(JSON.stringify(splitEventData[0]));
      event.start = first_event.start;
      event.end = first_event.end;
      if (screenName === "planning") {
        event.resource = first_event.servicecall_resources;
      }
    }
    event.isProjectScreen = false;
    let payloadevent = JSON.parse(JSON.stringify(event));
    if (
      payloadevent.split_generate_id !== "" &&
      payloadevent.split_generate_id !== undefined
    ) {
      payloadevent.id = "";
    }
    let duration = moment.duration(
      moment(payloadevent.end).diff(moment(payloadevent.start))
    );
    payloadevent.planned = duration.asHours();
    payloadevent.duration = duration.asHours();

    if (payloadevent.duration > 0) {
      if (payloadevent.id === "") {
        res = await createPopupvalues(payloadevent);
      } else {
        res = await updatePopupvalues(payloadevent);
      }
    }
    setistaskupdated(true);

    if (!res || res?.status !== 200) {
      if (res?.length !== 0 && res?.status !== 200) {
        settooltipOpen(false);
      }
      return false;
    } else {
      planTask =
        planningTask?.filter((item: any) => item.id !== event.id) || [];
      let newServicecalls: any = [];
      if (payloadevent.id === "") {
        event.id = res.data?.data?.id;
      }else{
        if(res.data?.servicecall?.[0]){
          event.hoursLeft = res.data?.servicecall[0]?.hoursLeft;
          event.hoursToBeScheduled = res.data?.servicecall?.[0]?.hoursToBeScheduled;
          event.estimatedHours = res.data?.servicecall?.[0]?.estimatedHours;
          event.hoursWorked = res.data?.servicecall?.[0]?.hoursWorked;
          event.plannedHours = res.data?.servicecall?.[0]?.plannedHours;
        }
      }
      event.split_generate_id = "";
      event.from_copy = false;
      event.total_ticket = event?.related_tickets?.length
      event.total_task = event?.related_tasks?.length
      if (screenName === "planning") {
        event.resource = event?.servicecall_resources;
      } else {
        event.resource = event?.related_tasks?.map((element:any) => element.id);
      }
      event = await relatedResources(event, resource_settings?.data)
      newServicecalls.push(event);
      settooltipOpen(false);
      settempEvent(null);
      if (splitEventData?.length > 0) {
        let splitUpdateArray = await splitedEventCall(
          splitEventData,
          resource_settings?.data,
          screenName,
        );
        newServicecalls.push(...splitUpdateArray);
      }
      if (screenName === "project" && event?.resource) {
        let task: any = await getTaskDetailCapsule({
          autotask_id: event?.resource,
        });
        if (task) {
          planTask =
            planTask?.filter(
              (item: any) =>
                !(event.resource.includes(item.resource) && item.type === "task")
            ) || [];
          newServicecalls.push(...task);
        }
      }
      setPlanningTask([...planTask, ...newServicecalls]);
      if (screenName === "planning") {
        refetchMonthdata();
        refetchPlanning();
        toplanrefetch();
      }

      return true;
    }
  }, [planningTask, eventDetail, splitEventData, view, tempEvent]);

  const popupButtons = useMemo<any>(() => {
    const buttons = [
      "cancel",
      {
        handler: async function () {
          if (!isValidData) {
            if (
              general_settings?.allow_overlapping === false &&
              hasOverlap(eventDetail, false, planningTask)
            ) {
              toast({
                message: "Overlapping tasks is not allowed",
                display: "center",
              });
              // onClose()
              return false;
            } else {
              updatePopupEvent();
              toggleDropdown("")
              setShowDropdown(false);
              setShowTaskDropdown(null)
              setistaskupdated(false);
              if (tempEvent?.id) {
                let div = document.getElementById("avail_task_" + tempEvent.id);
                if (div?.style) {
                  div!.style.display = "";
                }
              }
            }
          } else {
            popupvalidation(eventDetail);
            const boxes: any =
              document.getElementsByClassName("accordion-collapse");
            for (const box of boxes) {
              box.classList.remove("show");
            }
            if (
              eventDetail?.whichopen === "servicecall" ||
              eventDetail?.whichopen === ""
            ) {
              let div = document.getElementById("kt_accordion_1_body_1");
              div!.classList.add("show");
            } else {
              let div = document.getElementById(
                "kt_accordion_1_body_" + eventDetail?.whichopen
              );
              div!.classList.add("show");
            }
          }
        },
        cssClass: `mbsc-popup-button-primary ${
          !istaskupdated || isValidData ? "isofflinebutton" : ""
        }`,
        keyCode: "enter",
        disabled: !istaskupdated,
        text:
          isEventDetailPopup || eventDetail?.serviceCallId !== ""
            ? "Update"
            : "Add to planner",
      }
    ];
  
    // Conditionally add the + New button if test is false
      buttons.unshift({
        handler: function (): void {
          setShowDropdown(!showDropdown);
          setShowTaskDropdown(null)
          toggleDropdown("")
        },
        cssClass: "me-auto text-primary bg-btnbggray border-btnbggray btnNew",
        text: "New",
        align: "left",
      });
    return buttons;
  }, [
    updatePopupEvent,
    planningTask,
    tempEvent,
    errorStartDate,
    isValidData,
    istaskupdated,
    showDropdown,
  ]);
  

  const popupvalidation = (event: any) => {
    let valid: any = false;
    setistaskupdated(true);
    if (Object.keys(event).length > 0) {
      event.has_license = false;
      event.whichopen = "";
      if (
        todo_status_serviceCall?.filter(
          (twork: any) =>
            parseInt(twork.value) === parseInt(event?.servicecall_status)
        )?.length === 0
      ) {
        event.servicecall_status = "";
      }
      if (event.servicecall_status === "") {
        valid = true;
        event.whichopen = "servicecall";
      }
      if (
        todo_status_serviceCall?.filter(
          (twork: any) =>
            parseInt(twork.value) === parseInt(event?.servicecall_status) &&
            twork.isActive === false
        )?.length !== 0
      ) {
        valid = true;
        event.whichopen = "servicecall";
      }
      if (event.servicecall_status !== "") {
        event.status_name = todo_status_serviceCall?.filter(
          (status: any) =>
            parseInt(status.id) === parseInt(eventDetail.servicecall_status)
        )?.[0]?.name;
      }
      if (
        ServiceCallResources?.filter((twork: any) =>
          event?.servicecall_resources?.includes(parseInt(twork.value))
        ).length === 0
      ) {
        valid = true;
        event.whichopen = "servicecall";
      }
   
      if (locations?.data &&
        locations?.data?.filter((twork: any) =>
          parseInt(twork.autotask_id) === parseInt(event?.companyLocationID) &&
           twork.isActive === false
        ).length !== 0
      ) {
        valid = true;
        event.whichopen = "servicecall";
      }

      if (event?.related_tickets && event?.related_tickets?.length > 0) {
        event?.related_tickets?.filter((sticket: any) => {
          if (sticket.title === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            ticket_status?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.status)
            )?.length === 0
          ) {
            sticket.status = "";
          }
          if (sticket.status === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            ticket_status?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.status) &&
                twork.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            prioritylist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.priority)
            )?.length === 0
          ) {
            sticket.priority = "";
          }
          if (sticket.priority === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            prioritylist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.priority) &&
                twork.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            issueslist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.issueType)
            )?.length === 0
          ) {
            sticket.issueType = "";
            sticket.subIssueType = "";
          }
          if (sticket.issueType === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            issueslist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.issueType) &&
                twork.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            subIssueslist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.subIssueType)
            )?.length === 0
          ) {
            sticket.subIssueType = "";
          }
          if (sticket.subIssueType === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            subIssueslist?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.subIssueType) &&
                twork.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            worktype?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(sticket?.billingCodeID)
            )?.length === 0
          ) {
            sticket.billingCodeID = "";
          }
          if (sticket.billingCodeID === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            worktype?.filter(
              (trole: any) =>
                parseInt(trole.value) === parseInt(sticket?.billingCodeID) &&
                trole.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            TicketRoleArray.filter((e: any) =>
              sticket.secondary_resources
                ?.map((axisavailability: any) => axisavailability.value)
                .includes(e.value)
            )?.filter((trole: any) => trole.isDisabled === true)?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            TicketRoleArray.filter((e: any) =>
              sticket.secondary_resources
                ?.map((axisavailability: any) => axisavailability.value)
                .includes(e.value)
            )?.filter((trole: any) => trole.has_license === false)?.length !== 0
          ) {
            valid = true;
            event.has_license = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            TicketRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                parseInt(sticket.assignedResourceID)
            )?.length === 0
          ) {
            sticket.assignedResourceID = "";
            sticket.assignedResourceRoleID = "";
          }

          if (
            TicketRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                  parseInt(sticket.assignedResourceID) &&
                parseInt(trole.roleID) ===
                  parseInt(sticket.assignedResourceRoleID) &&
                trole.isDisabled === true
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            TicketRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                  parseInt(sticket.assignedResourceID) &&
                parseInt(trole.roleID) ===
                  parseInt(sticket.assignedResourceRoleID) &&
                trole.has_license === false
            )?.length !== 0
          ) {
            valid = true;
            event.has_license = true;
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }
          if (
            sticket?.errorinDefaultrole &&
            sticket.errorinDefaultrole !== ""
          ) {
            if (event.whichopen === "") {
              event.whichopen = sticket.id;
            }
          }

          if (
            locations?.data?.filter((twork: any) =>
              parseInt(twork.autotask_id) === parseInt(sticket?.companyLocationID) &&
               twork.isActive === false
            ).length !== 0
          ) {
            valid = true;
            if(event.whichopen === ""){
              event.whichopen = sticket.id;
            }
          }

          if(filterArray?.queues?.data?.filter(
            (twork: any) =>
              parseInt(twork.id) ===
                parseInt(sticket?.queueID) &&
              twork.isActive === false
           )?.length !== 0  ){
            valid = true;
            if(event.whichopen === ""){
              event.whichopen = sticket.id;
            }
          }
        });
      }
      if (event?.related_tasks && event?.related_tasks?.length > 0) {
        event?.related_tasks?.filter((stask: any) => {
          if (stask.title === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }

          if (
            ticket_status?.filter(
              (twork: any) => parseInt(twork.value) === parseInt(stask?.status)
            )?.length === 0
          ) {
            stask.status = "";
          }

          if (stask.status === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            ticket_status?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(stask?.status) &&
                twork.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }

          if (
            worktype?.filter(
              (twork: any) =>
                parseInt(twork.value) === parseInt(stask?.billingCodeID)
            )?.length === 0
          ) {
            stask.billingCodeID = "";
          }
          if (stask.billingCodeID === "") {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            worktype?.filter(
              (trole: any) =>
                parseInt(trole.value) === parseInt(stask?.billingCodeID) &&
                trole.isActive === false
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            tasksacendaryRoleArray
              .filter((e: any) =>
                stask.secondary_resources
                  ?.map((axisavailability: any) => axisavailability.value)
                  .includes(e.value)
              )
              ?.filter((trole: any) => trole.isDisabled === true)?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            tasksacendaryRoleArray
              .filter((e: any) =>
                stask.secondary_resources
                  ?.map((axisavailability: any) => axisavailability.value)
                  .includes(e.value)
              )
              ?.filter((trole: any) => trole.has_license === false)?.length !==
            0
          ) {
            valid = true;
            event.has_license = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            taskRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                parseInt(stask.assignedResourceID)
            )?.length === 0
          ) {
            stask.assignedResourceID = "";
            stask.assignedResourceRoleID = "";
          }

          if (
            taskRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                  parseInt(stask.assignedResourceID) &&
                parseInt(trole.roleID) ===
                  parseInt(stask.assignedResourceRoleID) &&
                trole.isDisabled === true
            )?.length !== 0
          ) {
            valid = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (
            taskRoleArray?.filter(
              (trole: any) =>
                parseInt(trole.resourceID) ===
                  parseInt(stask.assignedResourceID) &&
                parseInt(trole.roleID) ===
                  parseInt(stask.assignedResourceRoleID) &&
                trole.has_license === false
            )?.length !== 0
          ) {
            valid = true;
            event.has_license = true;
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (stask?.errorinDefaultrole && stask.errorinDefaultrole !== "") {
            if (event.whichopen === "") {
              event.whichopen = stask.id;
            }
          }
          if (locations?.data &&
            locations?.data?.filter((twork: any) =>
              parseInt(twork.autotask_id) === parseInt(stask?.companyLocationID) &&
               twork.isActive === false
            ).length !== 0
          ) {
            valid = true;
            event.whichopen = stask.id;
          }
        });
      }
      seteventDetail(event);
      splitEventsFn(event, "");
      setValidData(valid);
    }
  };

  const ontooptipClose = useCallback(
    async (e: any) => {
      settooltipOpen(false);
      setShowDropdown(false);
      setShowTaskDropdown(null)
      toggleDropdown("")
      let planTask: any = [];
      planTask =
        (await planningTask?.filter(
          (item: any) => (item.id !== eventDetail.id)
        )) || [];
      if (
        (!eventDetail?.split_generate_id ||
          eventDetail?.split_generate_id === "") &&
        eventDetail?.from_where !== "plan_bucket"
      ) {
        setPlanningTask([...planTask, tempEvent]);
      } else {
        if (existServicecall?.length > 0) {
          setPlanningTask([...planTask, ...existServicecall]);
        } else {
          setPlanningTask(planTask);
        }
      }
      if (eventDetail?.from_where === "plan_bucket") {
        let div = document.getElementById("avail_task_" + tempEvent.id);
        if (div) {
          div!.style.display = "";
        }
      }
      if (screenName === "planning") {
        setexistServicecall([]);
      }
    },
    [eventDetail, planningTask, tempEvent, existServicecall]
  );

  const removeTaskTicket = (arg:any, event:any) => {
    let latestpayload = JSON.parse(JSON.stringify(eventDetail));
    if(event.type === "task"){
        let tasks = latestpayload?.related_tasks.filter((t:any) => (t._id !== event._id));
        latestpayload.related_tasks = tasks;
        if(!latestpayload.removed_tasks.includes(event.autotask_id)){
          latestpayload.removed_tasks.push(event.autotask_id)
        }
    }else{
      let tickets = latestpayload?.related_tickets.filter((t:any) => (t._id !== event._id));
      latestpayload.related_tickets = tickets;
      if(!latestpayload.removed_tickets.includes(event.autotask_id)){
        latestpayload.removed_tickets.push(event.autotask_id)
      }
    }
    setTaskTicketid(taskTicketId.filter((item: any) => item !== event._id));
    assignedresources(latestpayload);
};


  useEffect(() => {
    if (isfirstOpen) {
      popupvalidation(eventDetail);
      setisfirstOpen(false);
      setTaskList(false)
      setShowDropdown(false);
      setShowTaskDropdown(null)
    }
  }, [isfirstOpen]);

  const CustomOption = ({ data, ...props }: any) => {
    return <SelectCustomLockOption option={data} props={props} />;
  };

  const handleNewOption = (option: string) => {
    setTaskListType(option)
    setTaskList(true)
    setShowDropdown(false);
    setTaskTicketid([]);
  };

  const openMoveDeleteDropdown = (label: string, id: any) => {
    if (showTaskDropdown && showTaskDropdown.label === label && showTaskDropdown.id === id) {
      setShowTaskDropdown(null);
    } else {
      setShowTaskDropdown({ label, id });
    }
    setShowDropdown(false);
    toggleDropdown("")
  };

  const renderServiceCallTooltip = (
    <Popover id="renderServiceCallTooltip">
      <div className="p-5 bg-white card">
        <div>
        Click the title to open the service call it in Autotask.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/create-a-service-call-for-a-task"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  const renderAutoTaskTooltip = (
    <Popover id="renderServiceCallTooltip">
      <div className="p-5 bg-white card">
        <div>
        Click the title to open the task in Autotask.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/create-a-service-call-for-a-task"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  const renderAutoTaskTicketTooltip = (
    <Popover id="renderServiceCallTooltip">
      <div className="p-5 bg-white card">
        <div>
        Click the title to open the ticket in Autotask.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/create-a-service-call-for-a-ticket"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    <>
    <Popup
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      buttons={popupButtons}
      isOpen={istooltipOpen}
      scrollLock={false}
      onClose={isEventDetailPopup ? (e: any) => ontooptipClose(e) : onClose}
      responsive={responsivePopup}
      cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup"
    >
      <div className="accordion" id="kt_accordion_1">
        {/* Service call accordian start */}
        <div className={`fs-16 fontIner text-dark serRow`}>
          <div className="min-h-65px"></div>
          <div
            className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-3 align-items-center bg-white min-h-65px"
            id="kt_accordion_1_header_1"
            style={{ zIndex: "1" }}
          >
            <div
              className="position-absolute start-0 top-0 w-100 h-100"
              style={{
                zIndex: "-1",
                backgroundColor:
                  eventDetail?.related_tickets?.length === 1 &&
                  eventDetail?.related_tasks?.length === 0
                    ? "#DFDEFF"
                    : eventDetail?.related_tasks?.length === 1 &&
                      eventDetail?.related_tickets?.length === 0
                    ? "#FFE2E2"
                    : "#cfefcf",
              }}
            ></div>
            <div className="d-flex align-items-center text-ellipsis">
              <div className="h-32px d-flex align-items-center">
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={renderServiceCallTooltip}
                >
                  <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-0 me-2">i</button>
                </OverlayTrigger>
              </div>
              <a
                href={
                  eventDetail?.url ? eventDetail?.url : "javascript:void(0)"
                }
                target={eventDetail?.url ? "_blank" : ""}
                className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4"
                rel="noreferrer"
              >
                <span className="text-ellipsis lh-sm">
                  {general_settings?.show_task_type === true
                    ? AUTOTASK_ENTITY.ServiceCall.title +
                      ": " +
                      eventDetail?.title
                    : eventDetail?.title}
                </span>
                <KTSVG
                  path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                  className="m-0 ms-2"
                  svgClassName="w-auto h-auto"
                  stylecode={{ color: "inherit" }}
                />
              </a>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={`btn bg-white text-dark fw-normal rounded-pill px-3 py-0 me-5 min-h-24px fs-16 d-flex align-items-center text-nowrap ${eventDetail?.serviceCallId !== "" ? "" : "me-4"
                  }`}
              >
                {(
                  eventDetail.status_name?.toLowerCase() !== "complete"
                ) && (
                  <small
                    className="min-w-6px min-h-6px rounded-circle me-8px"
                    style={{
                      backgroundColor: `${
                        eventDetail.status_name === "New"
                          ? "#5195FC"
                          : eventDetail.status_name === "To Reschedule"
                          ? "#5195FC"
                          : "#5195FC"
                      }`,
                    }}
                  ></small>
                )}
                {(eventDetail.status_name?.toLowerCase() === "complete") && (
                  <KTSVG
                    path="/media/icons/duotune/new-icons/check-icon.svg"
                    className="me-8px"
                    svgClassName="w-auto h-auto"
                  />
                )}
                {eventDetail.status_name}
              </span>

                  {( eventDetail?.serviceCallId !== "") && (
                    <div className="position-relative me-5">
                      <button
                        className={`btn min-h-24px min-w-24px d-inline-flex align-items-center p-0 justify-content-center`}
                        type="button"
                        onClick={() => {
                          toggleDropdown("copy-delete-servicecall")
                          setShowDropdown(false)
                          setShowTaskDropdown(null)
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/new-icons/more-vert.svg"
                          className="d-inline-block m-0 text-primary valignTop"
                          svgClassName="w-auto h-auto"
                        />
                      </button>
                      {openDropdown === "copy-delete-servicecall" &&
                        <div className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 position-absolute top-100 end-0 mt-4 show" data-kt-menu="true">                      
                          { eventDetail?.serviceCallId !== "" && (
                            <><button
                              className={`d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap bg-transparent border-0 ${eventDetail?.has_license === true ? 'isoffline pe-none' : ''}`}
                              title="Copy"
                              onClick={copyServicecall}
                            >
                              <KTSVG
                                path="/media/icons/duotune/new-icons/copy.svg"
                                className="w-16px me-2 text-dark"
                                svgClassName="w-100 h-auto"
                              />
                              <small className="fs-100">Copy</small>
                            </button>
                            <button
                              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap bg-transparent border-0 ${eventDetail?.has_license === true ? 'isoffline pe-none' : ''}`}
                              title="Delete"
                              onClick={(e: any) => {
                                confirm({
                                  title: "Are you sure you want to delete this plan item?",
                                  okText: "Yes",
                                  cancelText: "No",
                                  callback: function (result) {
                                    if (result) {
                                      removeServicecall(e, eventDetail);
                                    }
                                  },
                                });
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/new-icons/trash.svg"
                                className="w-16px me-2 text-dark"
                                svgClassName="w-100 h-auto"
                              />
                              <small className="fs-100">Remove servicecall</small>
                            </button></>
                          )}
                        </div>    
                      } 
                    </div>
                  )}                    

              <button
                className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center"
                style={{ borderWidth: "1px" }}
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="true"
                aria-controls="kt_accordion_1_body_1"
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                  className="d-inline-block m-0 text-primary valignTop"
                  svgClassName="w-12px h-auto"
                />
              </button>
            </div>
          </div>

          <div
            className={`paddLR24 md-tooltip-info position-relative accordion-collapse collapse ${
              eventDetail?.whichopen === "servicecall" ||
              eventDetail?.whichopen === ""
                ? "show"
                : ""
            }`}
            id="kt_accordion_1_body_1"
            aria-labelledby="kt_accordion_1_header_1"
            data-bs-parent="#kt_accordion_1"
          >
            <div className="row row-cols-2">
              <div className="col mb-4">
                <div className="d-flex align-items-center">
                  <strong className="fw-bold required">Start:&nbsp;</strong>
                  <span
                    id="servicecall-start"
                    className="align-items-center position-relative  flex-fill creatDatepicker ms-2"
                  >
                  <CustomDatePicker 
                      controls={["calendar", "time"]}
                      touchUi={true}
                      display={"anchored"}
                      showOverlay={false}
                      onChange={changeStartDate}
                      dateFormat="DD-MM-YYYY"
                      buttons={[]}
                      timeFormat={time_format}
                      placeholder="dd-mm-yyyy hh:mm"
                      iconPath="/media/icons/duotune/new-icons/down-arrow.svg"
                      invalid={[
                        {
                          start: moment(eventDetail?.end)
                            .tz(timezone)
                            .add(1, "day")
                            .format("YYYY-MM-DDTHH:mm"),
                          end: "2100-04-25",
                        },
                      ]}
                      // max={moment(eventDetail?.end)
                      //   .tz(timezone)
                      //   .format("YYYY-MM-DDTHH:mm")}
                      value={moment(eventDetail?.start)
                        .tz(timezone).format("YYYY-MM-DDTHH:mm")}
                    />
                    </span>
                </div>
                <div className="w-100" style={{ color: "#f00" }}>
                  {errorStartDate}
                </div>
              </div>

              <div className="col mb-4 d-flex flex-column ">
                <div className="d-flex align-items-center justify-content-end">
                  <strong className="fw-bold required">End:&nbsp;</strong>
                  <span
                    id="servicecall-end"
                    className="align-items-center position-relative  flex-fill creatDatepicker ms-2"
                  >
                    <CustomDatePicker
                      controls={["calendar", "time"]}
                      touchUi={true}
                      display="anchored"
                      showOverlay={false}
                      onChange={changeEndDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={time_format}
                      buttons={[]}
                      placeholder="dd-mm-yyyy hh:mm"
                      iconPath="/media/icons/duotune/new-icons/down-arrow.svg"
                      min={moment(eventDetail?.start)
                        .tz(timezone)
                        .format("YYYY-MM-DDTHH:mm")}
                      value={moment(eventDetail?.end)
                        .tz(timezone)
                        .format("YYYY-MM-DDTHH:mm")}
                    />
                    {/* <input type="datetime-local" name='end_date' className='form-control form-control-solid h-32px p-0 pe-5px bg-transparent text-primary fw-bold fs-16 border-0 w-175px text-end' value={moment(eventDetail?.end).tz(timezone).format('YYYY-MM-DDTHH:mm')} onChange={changeEndDate} min={moment(eventDetail?.start).tz(timezone).format('YYYY-MM-DDTHH:mm')} onKeyDown={(event:any) => {event.preventDefault()}}/> */}
                    
                  </span>
                </div>
                <div className="text-end" style={{ color: "#f00" }}>
                  {errorEndDate}
                </div>
              </div>
              {/* <div className="col mb-4 d-flex align-items-center justify-content-end">   
                                  <span style={{color:'red'}}>{errorStartDate}</span>
                              </div> */}
              <div className="col col-12 mb-7 flex-column">
                <strong className="fw-bold">
                  {AUTOTASK_ENTITY.ServiceCall.title} description:
                </strong>
                <span className="fw-normal">
                  <textarea
                    placeholder="Some description for Service call"
                    className="form-control form-control-solid h-100px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                    rows={3}
                    name="popupdescription"
                    id="popupdescription"
                    onChange={(e: any) =>
                      assignpopupfieldchangeinput(
                        e,
                        "",
                        "",
                        "servicecall_description"
                      )
                    }
                  >
                    {eventDetail?.description}
                  </textarea>
                </span>
              </div>

              <div className="col mb-4">
                <strong className="fw-bold pe-8px">Company:</strong>
                <span className="fw-bold text-primary text-decoration-underline">
                  <a
                    href={eventDetail?.company_url}
                    target="_blank"
                    className="fw-bold text-primary"
                    rel="noreferrer"
                  >
                    {eventDetail?.company_name}
                  </a>
                </span>
              </div>

              {todo_status_serviceCall && (
                <>
                  <div className="col d-flex flex-column mb-4">
                    <div className="d-flex justify-content-end align-items-center">
                      <strong className="fw-bold pe-8px required">
                        Status:
                      </strong>
                      <span className="fw-normal">
                        <Select
                          value={todo_status_serviceCall?.filter(
                            (twork: any) =>
                              parseInt(twork.value) ===
                              parseInt(eventDetail?.servicecall_status)
                          )}
                          isMulti
                          isClearable={false}
                          name="Issues"
                          placeholder="Select status"
                          options={handleHideOptionalFunc(
                            servicecallStatus,
                            eventDetail?.servicecall_status
                          )}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          menuPlacement="top"
                          isOptionDisabled={(option: any) => !option.isActive}
                          onChange={(e: any) =>
                            assignpopupfieldchange(
                              e,
                              "",
                              "",
                              "servicecall_status"
                            )
                          }
                        />
                      </span>
                    </div>
                    {eventDetail?.servicecall_status === "" && (
                      <>
                        <div
                          className="w-100 text-end"
                          style={{ color: "#f00" }}
                        >
                          Please select status.
                        </div>
                      </>
                    )}
                    {todo_status_serviceCall?.filter(
                      (twork: any) =>
                        parseInt(twork.value) ===
                          parseInt(eventDetail?.servicecall_status) &&
                        twork.isActive === false
                    )?.length !== 0 && (
                      <>
                        <span style={{ color: "red" }}>
                          Selected status is inactive from autotask.
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}

              <div className="col-12 d-flex mb-4">
                <strong className="fw-bold text-nowrap pe-8px required min-w-110px">
                  Assigned To:{" "}
                </strong>
                <span className="fw-normal" style={{ width: "100%" }}>
                  <Select
                    value={ServiceCallResources?.filter((twork: any) =>
                      eventDetail?.servicecall_resources?.includes(
                        parseInt(twork.value)
                      )
                    )}
                    isMulti
                    isClearable={false}
                    name="Resources"
                    options={ServiceCallResources?.filter((twork: any) =>
                      eventDetail?.assigntoresource?.includes(twork.value)
                    )}
                    components={{ Option: CustomOption }}
                    formatOptionLabel={formatOptionLabel}
                    isOptionDisabled={(option: any) =>
                      option.isDisabled || !option.has_license
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select assigned to resources"
                    menuPlacement="top"
                    onChange={changeservicecallresource}
                  />
                </span>
              </div>
              {ServiceCallResources?.filter((twork: any) =>
                eventDetail?.servicecall_resources?.includes(
                  parseInt(twork.value)
                )
              ).length === 0 && (
                <>
                  <span className="text-nowrap" style={{ color: "red" }}>
                    This can not be saved. You need to assign a resource.
                  </span>
                </>
              )}

              {/* location feild  */}
              {locationShow && (
                <div className="col-12 d-flex">
                  <strong className="fw-bold text-nowrap pe-8px  min-w-110px">
                    Location:{" "}
                  </strong>
                  <span className="fw-normal" style={{ width: "50%" }}>
                    <Select
                      value={locations?.data ? locations?.data
                        ?.filter(
                          (twork: any) =>
                            parseInt(twork.autotask_id) ===
                            parseInt(eventDetail?.companyLocationID)
                        )
                        .map((option) => ({
                          label: option.name,
                          value: option.autotask_id,
                        })) :null}
                      isMulti
                      isClearable={false}
                      isDisabled={eventDetail?.serviceCallLocationVisible}
                      placeholder="Select Location"
                      name="Location"
                      menuPlacement="top"
                      options={
                        locations?.data
                          ? locations?.data
                              ?.filter(
                                (twork: any) =>
                                  twork.companyID === eventDetail?.companyID
                              ).filter((twork: any) => !hideOptions || twork.isActive) 
                              .map((option) => ({
                                label: option.name,
                                value: option.autotask_id,
                                isPrimary: option.isPrimary,
                                isActive: option.isActive,
                              }))
                          : []
                      }
                      isOptionDisabled={(option: any) =>
                        !option.isActive
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e: any) =>
                        assignpopupfieldchange(
                          e,
                          "",
                          "",
                          "servicecall_location"
                        )
                      }
                    />
                      {locations?.data ? locations?.data?.filter(
                      (twork: any) =>
                        parseInt(twork.autotask_id) ===
                          parseInt(eventDetail?.companyLocationID) &&
                        twork.isActive === false
                    )?.length !== 0 && (
                      <>
                        <span style={{ color: "red" }}>
                          Selected Location is inactive from autotask.
                        </span>
                      </>
                    ) :null}
                  </span>
                  <div className="form-check form-check-dark form-switch fv-row m-0 ms-3">
                    <input
                      className="form-check-input w-50px h-32px mt-0"
                      type="checkbox"
                      checked={eventDetail?.serviceCallLocationVisible}
                      title="Use Teams as location and show Teams link."
                      onChange={(e: any) =>
                        assignpopupfieldchange(
                          e,
                          "",
                          "",
                          "serviceCallLocationVisible"
                        )
                      }
                    />
                    <KTSVG
                      path="/media/icons/duotune/new-icons/ms-teams.svg"
                      className="text-dark ms-4"
                      svgClassName="w-20px h-auto"
                    />            
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Related Tickets end */}
        <>
          {eventDetail?.related_tickets?.length > 0 &&
            Array.from(eventDetail?.related_tickets)?.map(
              (related_ticket: any, key) => {
                let ticket_status1 = JSON.parse(JSON.stringify(ticket_status));
                ticket_status1.unshift({
                  label: "Select status for ticket",
                  value: "",
                });
                let worktype1 = JSON.parse(JSON.stringify(worktype));
                worktype1.unshift({ label: "Select work type", value: "" });
                let prioritylist1 = JSON.parse(JSON.stringify(prioritylist));
                prioritylist1.unshift({
                  label: "Select priority",
                  value: "",
                });
                let issueslist1 = JSON.parse(JSON.stringify(issueslist));
                issueslist1.unshift({ label: "Select issue", value: "" });
                let subIssueslist1 = JSON.parse(JSON.stringify(subIssueslist));
                subIssueslist1.unshift({
                  label: "Select sub issue",
                  value: "",
                  parentIssue: "",
                });
                let status_name = ticket_status1?.filter(
                  (status: any) =>
                    parseInt(status.id) === parseInt(related_ticket.status)
                );
                status_name = status_name?.[0]?.name;
               return (
                  <>
                    <div className="fs-16 fontIner text-dark serRow">
                      <div
                        className="md-tooltip-header d-flex justify-content-between px-7 position-relative py-3 align-items-center min-h-65px"
                        id={`kt_accordion_1_header_${related_ticket.id}`}
                      >
                        <div className="d-flex align-items-center text-ellipsis">
                        <div className="h-32px d-flex align-items-center">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={renderAutoTaskTicketTooltip}
                            >
                              <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-0 me-2">i</button>
                            </OverlayTrigger>
                          </div>
                          <a
                            href={related_ticket?.url}
                            target="_blank"
                            className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4"
                            rel="noreferrer"
                          >
                            <span className="text-ellipsis lh-sm">
                              {general_settings?.show_task_type === true
                                ? related_ticket?.type
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  related_ticket?.type?.slice(1) +
                                  ": " +
                                  related_ticket?.title
                                : related_ticket?.title}
                            </span>
                            <KTSVG
                              path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                              className="m-0 ms-2"
                              svgClassName="w-auto h-auto"
                              stylecode={{ color: "inherit" }}
                            />
                          </a>
                        </div>

                        <div className="d-flex align-items-center">
                          <span
                            className="btn text-dark fw-normal rounded-pill px-3 py-0 min-h-24px fs-16 d-flex align-items-center text-nowrap me-5"
                            style={{ backgroundColor: "#EDF1F5" }}
                          >
                            {(
                              status_name?.toLowerCase() !== "complete"
                            ) && (
                              <small
                                className="min-w-6px min-h-6px rounded-circle me-8px"
                                style={{ backgroundColor: "#5195FC" }}
                              ></small>
                            )}
                            {(status_name?.toLowerCase() === "complete") && (
                              <KTSVG
                                path="/media/icons/duotune/new-icons/check-icon.svg"
                                className="me-8px"
                                svgClassName="w-auto h-auto"
                              />
                            )}
                            {status_name}
                          </span>

                          <div className="position-relative me-5">
                            <button
                              className={`btn min-h-24px min-w-24px d-inline-flex align-items-center p-0 justify-content-center`}
                              type="button"
                              onClick={() => openMoveDeleteDropdown("move-delete-ticket", related_ticket.id)}
                            >
                              <KTSVG
                                path="/media/icons/duotune/new-icons/more-vert.svg"
                                className="d-inline-block m-0 text-primary valignTop"
                                svgClassName="w-auto h-auto"
                              />
                            </button>
                            {showTaskDropdown && showTaskDropdown.label === "move-delete-ticket" && showTaskDropdown.id === related_ticket.id && (
                              <div className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 position-absolute top-100 end-0 mt-4 show" data-kt-menu="true">                      
                                {(
                                  <button
                                    className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap bg-transparent border-0`}
                                    title="Remove"
                                    onClick={(e: any) => {
                                      confirm({
                                        title: "Are you sure you want to remove this plan item?",
                                        okText: "Yes",
                                        cancelText: "No",
                                        callback: function (result) {
                                          if (result) {
                                            removeTaskTicket(e, related_ticket);
                                          }
                                        },
                                      });
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/trash.svg"
                                      className="w-16px me-2 text-dark"
                                      svgClassName="w-100 h-auto"
                                    />
                                    <small className="fs-100">Remove ticket</small>
                                  </button>
                                )}
                              </div>    
                            )}
                          </div>

                          <button
                            className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center collapsed"
                            style={{ borderWidth: "1px" }}
                            data-bs-toggle="collapse"
                            data-bs-target={`#kt_accordion_1_body_${related_ticket.id}`}
                            aria-expanded="false"
                            aria-controls={`kt_accordion_1_body_${related_ticket.id}`}
                          >
                            <KTSVG
                              path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                              className="d-inline-block m-0 text-primary valignTop"
                              svgClassName="w-12px h-auto"
                            />
                          </button>
                        </div>
                      </div>

                      <div
                        className={`paddLR24 md-tooltip-info position-relative accordion-collapse collapse ${
                          eventDetail?.whichopen === related_ticket.id
                            ? "show"
                            : ""
                        }`}
                        id={`kt_accordion_1_body_${related_ticket.id}`}
                        aria-labelledby={`kt_accordion_1_header_${related_ticket.id}`}
                        data-bs-parent="#kt_accordion_1"
                      >
                        <div className="row row-cols-2 row-24">
                          <div className="col col-12 mb-25px d-flex flex-column">
                            <strong className="fw-bold pb-1 required">
                              Ticket Title:{" "}
                            </strong>
                            <input
                              type="text"
                              className="form-control form-control-solid bg-white border-graybor h-32px px-4 rounded-4px py-0 fw-normal fs-16 text-dark"
                              defaultValue={related_ticket?.title}
                              data-id={related_ticket.id}
                              data-type={related_ticket.type}
                              onChange={(e: any) =>
                                assignpopupfieldchangeinput(
                                  e,
                                  related_ticket.id,
                                  related_ticket.type,
                                  "task_title"
                                )
                              }
                              required
                            />
                            {related_ticket?.title === "" && (
                              <span style={{ color: "red" }}>
                                Please add title.
                              </span>
                            )}
                          </div>

                          <div className="col col-12 mb-22px">
                            <strong className="fw-bold pe-8px">Company:</strong>
                            <span className="fw-bold text-primary text-decoration-underline me-15">
                              <a
                                href={related_ticket?.company_url}
                                target="_blank"
                                className="fw-bold text-primary"
                                rel="noreferrer"
                              >
                                {eventDetail?.company_name}
                              </a>
                            </span>
                            <strong className="fw-bold pe-8px">Project:</strong>
                            <span className="fw-bold text-primary text-decoration-underline">
                              <a
                                href={related_ticket?.project_url}
                                target="_blank"
                                className="fw-bold text-primary"
                                rel="noreferrer"
                              >
                                {related_ticket?.project_name}
                              </a>
                            </span>
                          </div>

                          <div className="col mb-20px flex-column">
                            <strong className="fw-bold required">
                              Priority:&nbsp;
                            </strong>
                            {prioritylist && (
                              <Select
                                value={prioritylist?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_ticket?.priority)
                                )}
                                isMulti
                                isClearable={false}
                                name="Issues"
                                placeholder="Select priority"
                                options={handleHideOptionalFunc(
                                  prioritylist1,
                                  related_ticket?.priority
                                )}
                                // options={prioritylist1}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "priority"
                                  )
                                }
                              />
                            )}
                            {prioritylist?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                parseInt(related_ticket?.priority)
                            )?.length === 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select priority.
                                </span>
                              </>
                            )}
                            {prioritylist?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                  parseInt(related_ticket?.priority) &&
                                twork.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected priority is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-20px">
                            <strong className="fw-bold">Primary:</strong>
                            <div className="position-relative">
                              {(related_ticket?.type === "ticket" ||
                                related_ticket?.type === "servicecall") &&
                                TicketRoleArray && (
                                  <Select
                                    value={TicketRoleArray?.filter(
                                      (trole: any) =>
                                        parseInt(trole.resourceID) ===
                                          parseInt(
                                            related_ticket.assignedResourceID
                                          ) &&
                                        parseInt(trole.roleID) ===
                                          parseInt(
                                            related_ticket.assignedResourceRoleID
                                          )
                                    )}
                                    isMulti
                                    // isClearable={false}
                                    placeholder="Select primary resource"
                                    name="Primary Resource"
                                    options={
                                      hideOptions
                                        ? TicketRoleArray?.filter(
                                            (trole: any) =>
                                              (parseInt(trole.resourceID) ===
                                                parseInt(
                                                  related_ticket.assignedResourceID
                                                ) &&
                                                parseInt(trole.roleID) ===
                                                  parseInt(
                                                    related_ticket.assignedResourceRoleID
                                                  )) ||
                                              trole.isDisabled === false
                                          )
                                        : TicketRoleArray
                                    }
                                    components={{ Option: CustomOption }}
                                    formatOptionLabel={formatOptionLabel}
                                    className="basic-multi-select"
                                    isOptionDisabled={(option: any) =>
                                      option.isDisabled || !option.has_license
                                    }
                                    classNamePrefix="select"
                                    styles={{
                                      control: (
                                        baseStyles: any,
                                        state: any
                                      ) => ({
                                        ...baseStyles,
                                        background: state.isDisabled
                                          ? "red"
                                          : "",
                                      }),
                                    }}
                                    onChange={(e: any) =>
                                      changeprimaryresid(
                                        e,
                                        related_ticket.id,
                                        related_ticket.type
                                      )
                                    }
                                  />
                                )}
                              {TicketRoleArray?.filter(
                                (trole: any) =>
                                  parseInt(trole.resourceID) ===
                                    parseInt(
                                      related_ticket.assignedResourceID
                                    ) &&
                                  parseInt(trole.roleID) ===
                                    parseInt(
                                      related_ticket.assignedResourceRoleID
                                    ) &&
                                  trole.isDisabled === true
                              )?.length !== 0 && (
                                <>
                                  <span style={{ color: "red" }}>
                                    Selected resource is inactive from autotask.
                                  </span>
                                </>
                              )}
                              {related_ticket?.errorinDefaultrole ===
                                "in_primary" && (
                                <>
                                  <span>
                                    <b>Note: </b>Resource role is not selected
                                    by default. Please set default settings
                                    (roles) from the resource screen.
                                  </span>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="col mb-20px">
                            <strong className="fw-bold">
                              Secondary Resource(s):{" "}
                            </strong>
                            <div className="position-relative">
                              <div
                                className="dropDown position-relative"
                                style={{}}
                              >
                                <Select
                                  value={TicketRoleArray.filter((e: any) =>
                                    related_ticket.secondary_resources
                                      ?.map(
                                        (axisavailability: any) =>
                                          axisavailability.value
                                      )
                                      .includes(e.value)
                                  )}
                                  isMulti
                                  name="Secondary Resource"
                                  placeholder="Select secondary resource"
                                  options={
                                    hideOptions
                                      ? TicketRoleArray?.filter(
                                          (twork: any) =>
                                            parseInt(twork.resourceID) !==
                                              parseInt(
                                                related_ticket?.assignedResourceID
                                              ) && twork.isDisabled === false
                                        )
                                      : TicketRoleArray?.filter(
                                          (twork: any) =>
                                            parseInt(twork.resourceID) !==
                                            parseInt(
                                              related_ticket?.assignedResourceID
                                            )
                                        )
                                  }
                                  components={{ Option: CustomOption }}
                                  formatOptionLabel={formatOptionLabel}
                                  isOptionDisabled={(option: any) =>
                                    option.isDisabled || !option.has_license
                                  }
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(e: any) =>
                                    handleSecondaryResourceChange(
                                      e,
                                      related_ticket.id,
                                      related_ticket.type
                                    )
                                  }
                                />
                                {TicketRoleArray.filter((e: any) =>
                                  related_ticket.secondary_resources
                                    ?.map(
                                      (axisavailability: any) =>
                                        axisavailability.value
                                    )
                                    .includes(e.value)
                                )?.filter(
                                  (trole: any) => trole.isDisabled === true
                                )?.length !== 0 && (
                                  <>
                                    <span
                                      className="text-nowrap"
                                      style={{ color: "red" }}
                                    >
                                      Selected one or more resources are
                                      inactive from autotask.
                                    </span>
                                  </>
                                )}
                                <br />
                                {related_ticket?.errorinDefaultrole ===
                                  "in_secondary" && (
                                  <>
                                    <span>
                                      <b>Note: </b> Resource role is not
                                      selected by default. Please set default
                                      settings (roles) from the resource screen.
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col mb-20px">
                            <strong className="fw-bold required">
                              Worktype:
                            </strong>
                            {worktype && (
                              <Select
                                value={worktype1?.filter(
                                  (trole: any) =>
                                    parseInt(trole.value) ===
                                    parseInt(related_ticket?.billingCodeID)
                                )}
                                isMulti
                                isClearable={false}
                                placeholder="Select work type"
                                name="Work Type"
                                options={handleHideOptionalFunc(
                                  worktype,
                                  related_ticket?.billingCodeID
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "worktype"
                                  )
                                }
                              />
                            )}
                            {related_ticket?.billingCodeID === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select work type.
                                </span>
                              </>
                            )}
                            {worktype1?.filter(
                              (trole: any) =>
                                parseInt(trole.value) ===
                                  parseInt(related_ticket?.billingCodeID) &&
                                trole.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected worktype is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-20px">
                            <strong className="fw-bold required">
                              Status:
                            </strong>
                            {ticket_status && (
                              <Select
                                value={ticket_status1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_ticket?.status)
                                )}
                                isMulti
                                isClearable={false}
                                placeholder="Select status"
                                name="Status"
                                options={handleHideOptionalFunc(
                                  ticket_status1,
                                  related_ticket?.status
                                )}
                                // options={ticket_status1}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "task_status"
                                  )
                                }
                              />
                            )}
                            {related_ticket?.status === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select status.
                                </span>
                              </>
                            )}
                            {ticket_status1?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                  parseInt(related_ticket?.status) &&
                                twork.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected status is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-20px flex-column">
                            <strong className="fw-bold required">
                              Issue Type:
                            </strong>
                            {issueslist && (
                              <Select
                                value={issueslist1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_ticket?.issueType)
                                )}
                                isMulti
                                isClearable={false}
                                name="Issues"
                                options={handleHideOptionalFunc(
                                  issueslist1,
                                  related_ticket?.issueType
                                )}
                                // options={issueslist1}
                                placeholder="Select issue type"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "issue"
                                  )
                                }
                              />
                            )}
                            {related_ticket?.issueType === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select issue type.
                                </span>
                              </>
                            )}

                            {issueslist1?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                  parseInt(related_ticket?.issueType) &&
                                twork.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected issue is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-25px flex-column">
                            <strong className="fw-bold required">
                              Sub Issue Type:
                            </strong>
                            {subIssueslist && (
                              <Select
                                value={subIssueslist1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_ticket?.subIssueType)
                                )}
                                isMulti
                                isClearable={false}
                                placeholder="Select sub-issue type"
                                name="Issues"
                                options={
                                  hideOptions
                                    ? subIssueslist1?.filter(
                                        (twork: any) =>
                                          (parseInt(twork.parentIssue) ===
                                            parseInt(
                                              related_ticket?.issueType
                                            ) ||
                                            twork.parentIssue === "") &&
                                          twork.isActive === true
                                      )
                                    : subIssueslist1?.filter(
                                        (twork: any) =>
                                          parseInt(twork.parentIssue) ===
                                            parseInt(
                                              related_ticket?.issueType
                                            ) || twork.parentIssue === ""
                                      )
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "subissue"
                                  )
                                }
                              />
                            )}
                            {related_ticket?.subIssueType === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select sub issue type.
                                </span>
                              </>
                            )}
                            {subIssueslist1?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                  parseInt(related_ticket?.subIssueType) &&
                                twork.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected sub issue is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          {/* location feild */}

                          {locationShow && (
                            <div className="col mb-4">
                              <strong className="fw-bold">Location:</strong>
                              <Select
                                value={locations?.data ? locations?.data
                                  ?.filter(
                                    (twork: any) =>
                                      parseInt(twork.autotask_id) ===
                                      parseInt(
                                        related_ticket?.companyLocationID
                                      )
                                  )
                                  .map((option) => ({
                                    label: option.name,
                                    value: option.autotask_id,
                                  })) :null}
                                isMulti
                                isClearable={false}
                                placeholder="Select Location"
                                name="Location"
                                options={
                                  locations?.data
                                    ? locations?.data
                                        ?.filter(
                                          (twork: any) =>
                                            twork.companyID ===
                                            eventDetail?.companyID
                                        ).filter((twork: any) => !hideOptions || twork.isActive)
                                        .map((option) => ({
                                          label: option.name,
                                          value: option.autotask_id,
                                          isPrimary: option.isPrimary,
                                         isActive: option.isActive,
                                        }))
                                    : []
                                }
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket?.id,
                                    related_ticket?.type,
                                    "location"
                                  )
                                }
                              />
                             {locations?.data ? locations?.data?.filter(
                      (twork: any) =>
                        parseInt(twork.autotask_id) ===
                          parseInt(related_ticket?.companyLocationID) &&
                        twork.isActive === false
                    )?.length !== 0 && (
                      <>
                        <span style={{ color: "red" }}>
                          Selected Location is inactive from autotask.
                        </span>
                      </>
                    ) :null}
                            </div>
                          )}
                          {/* Queue div   */}
                          <div className="col mb-20px flex-column">
                            <strong className="fw-bold ">Queue:</strong>
                            {filterArray?.queues && (
                              <Select
                                isMulti
                                isClearable={false}
                                value={filterArray?.queues?.data
                                  ?.filter((twork: any) => {
                                    return (
                                      parseInt(twork?.id) ===
                                      parseInt(related_ticket?.queueID)
                                    );
                                  })
                                  .map((item: any) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))}
                                placeholder="Select queue"
                                name="Queue"
                                options={filterArray?.queues?.data
                                  .filter((twork: any) => !hideOptions || twork.isActive).map(
                                  (item) => ({
                                    label: item.name,
                                    value: item.id,
                                    isActive: item.isActive,
                                  })
                                )}
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "queue"
                                  )
                                }
                              />
                            )}
                             {filterArray?.queues?.data?.filter(
                                              (twork: any) =>
                                                parseInt(twork.id) ===
                                                  parseInt(related_ticket?.queueID) &&
                                                twork.isActive === false
                                            )?.length !== 0 && (
                                              <>
                                                <span style={{ color: "red" }}>
                                                  Selected Queue is inactive from autotask.
                                                </span>
                                              </>
                                            )}  


                          </div>
                          <div className="col col-12 mb-4">
                            <strong className="fw-bold pe-8px">
                              First response:
                            </strong>
                            <span className="fw-bold text-primary me-15">
                              {related_ticket?.firstResponseDateTime
                                ? moment(related_ticket?.firstResponseDateTime)
                                    .tz(timezone)
                                    .format("ddd DD-MM-YYYY")
                                : ""}{" "}
                              {related_ticket?.firstResponseDateTime
                                ? moment(related_ticket?.firstResponseDateTime)
                                    .tz(timezone)
                                    .format(time_format)
                                : "-"}
                              <KTSVG
                                path="/media/icons/duotune/new-icons/datepicker.svg"
                                className="d-inline-block m-0 ms-2 text-dark d-none"
                                svgClassName="w-20px h-auto"
                              />
                            </span>
                            <strong className="fw-bold pe-8px">
                              Due date:
                            </strong>
                            <span className="fw-bold text-primary">
                              {related_ticket?.endDateTime
                                ? moment(related_ticket?.endDateTime)
                                    .tz(timezone)
                                    .format("ddd DD-MM-YYYY")
                                : ""}{" "}
                              {related_ticket?.endDateTime
                                ? moment(related_ticket?.endDateTime)
                                    .tz(timezone)
                                    .format(time_format)
                                : "-"}
                              <KTSVG
                                path="/media/icons/duotune/new-icons/datepicker.svg"
                                className="d-inline-block m-0 ms-2 text-dark d-none"
                                svgClassName="w-20px h-auto"
                              />
                            </span>
                          </div>

                          <div className="col col-12 flex-column">
                            <strong className="fw-bold">Description:</strong>
                            <span>
                              <textarea
                                placeholder="Ticket / Task Description"
                                className="form-control form-control-solid h-55px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                                rows={3}
                                name="popuptaskdescription"
                                id="popuptaskdescription"
                                data-id={related_ticket.id}
                                data-type={related_ticket.type}
                                onChange={(e: any) =>
                                  assignpopupfieldchangeinput(
                                    e,
                                    related_ticket.id,
                                    related_ticket.type,
                                    "task_description"
                                  )
                                }
                              >
                                {related_ticket?.description}
                              </textarea>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
        </>
        {/* Related Task end */}
        <>
          {eventDetail?.related_tasks?.length > 0 &&
            Array.from(eventDetail?.related_tasks)?.map(
              (related_task: any, key) => {
                let task_status1 = JSON.parse(JSON.stringify(task_status));
                task_status1.unshift({
                  label: "Select status for task",
                  value: "",
                });
                let worktype1 = JSON.parse(JSON.stringify(worktype));
                worktype1.unshift({ label: "Select work type", value: "" });
                let prioritylist1 = JSON.parse(JSON.stringify(prioritylist));
                prioritylist1.unshift({
                  label: "Select priority",
                  value: "",
                });
                let issueslist1 = JSON.parse(JSON.stringify(issueslist));
                issueslist1.unshift({
                  label: "Select priority",
                  value: "",
                });
                let subIssueslist1 = JSON.parse(JSON.stringify(subIssueslist));
                subIssueslist1.unshift({
                  label: "Select priority",
                  value: "",
                  parent: "",
                });
                let status_name = task_status?.filter(
                  (status: any) =>
                    parseInt(status.id) === parseInt(related_task.status)
                );
                status_name = status_name?.[0]?.name;
                return (
                  <>
                    <div className="fs-16 fontIner text-dark serRow ">
                      <div
                        className="md-tooltip-header d-flex justify-content-between px-7 position-relative py-3 align-items-center min-h-65px"
                        id={`kt_accordion_1_header_${related_task?.id}`}
                      >
                        <div className="d-flex align-items-center text-ellipsis">
                          <div className="h-32px d-flex align-items-center">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={renderAutoTaskTooltip}
                            >
                              <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-0 me-2">i</button>
                            </OverlayTrigger>
                          </div>
                          <a
                            href={related_task?.url}
                            target="_blank"
                            className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100"
                            rel="noreferrer"
                          >
                            <span className="text-ellipsis lh-sm">
                              {general_settings?.show_task_type === true
                                ? related_task?.type?.charAt(0).toUpperCase() +
                                  related_task?.type?.slice(1) +
                                  ": " +
                                  related_task?.title
                                : related_task?.title}
                            </span>
                            <KTSVG
                              path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                              className="m-0 ms-2"
                              svgClassName="w-auto h-auto"
                              stylecode={{ color: "inherit" }}
                            />
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <span
                            className="btn text-dark fw-normal rounded-pill px-3 py-0 min-h-24px fs-16 d-flex align-items-center text-nowrap me-5"
                            style={{ backgroundColor: "#EDF1F5" }}
                          >
                            {(
                              status_name?.toLowerCase() !== "complete"
                            ) && (
                                <small
                                  className="min-w-6px min-h-6px rounded-circle me-8px"
                                  style={{ backgroundColor: "#5195FC" }}
                                ></small>
                              )}
                            {(status_name?.toLowerCase() === "complete") && (
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/check-icon.svg"
                                  className="me-8px"
                                  svgClassName="w-auto h-auto"
                                />
                              )}
                            {status_name}
                          </span>
                          <div className="position-relative me-5">
                            <button
                              className={`btn min-h-24px min-w-24px d-inline-flex align-items-center p-0 justify-content-center`}
                              type="button"
                              onClick={() => openMoveDeleteDropdown("move-delete-task", related_task?.id)}
                            >
                              <KTSVG
                                path="/media/icons/duotune/new-icons/more-vert.svg"
                                className="d-inline-block m-0 text-primary valignTop"
                                svgClassName="w-auto h-auto"
                              />
                            </button>
                            {showTaskDropdown && showTaskDropdown.label === "move-delete-task" && showTaskDropdown.id === related_task?.id && (
                              <div className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 position-absolute top-100 end-0 mt-4 show" data-kt-menu="true">                      
                                {(
                                  <button
                                    className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap bg-transparent border-0`}
                                    title="Remove"
                                    onClick={(e: any) => {
                                      confirm({
                                        title: "Are you sure you want to remove this plan item?",
                                        okText: "Yes",
                                        cancelText: "No",
                                        callback: function (result) {
                                          if (result) {
                                            removeTaskTicket(e, related_task);
                                          }
                                        },
                                      });
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/new-icons/trash.svg"
                                      className="w-16px me-2 text-dark"
                                      svgClassName="w-100 h-auto"
                                    />
                                    <small className="fs-100">Remove task</small>
                                  </button>
                                )}
                              </div>    
                            )}
                          </div>

                          <button
                            className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center collapsed"
                            style={{ borderWidth: "1px" }}
                            data-bs-toggle="collapse"
                            data-bs-target={`#kt_accordion_1_body_${related_task?.id}`}
                            aria-expanded="false"
                            aria-controls={`kt_accordion_1_body_${related_task?.id}`}
                          >
                            <KTSVG
                              path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                              className="d-inline-block m-0 text-primary valignTop"
                              svgClassName="w-12px h-auto"
                            />
                          </button>
                        </div>
                      </div>

                      <div
                        className={`paddLR24 md-tooltip-info position-relative accordion-collapse collapse ${
                          eventDetail?.whichopen === related_task?.id
                            ? "show"
                            : ""
                        }`}
                        id={`kt_accordion_1_body_${related_task?.id}`}
                        aria-labelledby={`kt_accordion_1_header_${related_task?.id}`}
                        data-bs-parent="#kt_accordion_1"
                      >
                        <div className="row row-cols-2">
                          <div className="col col-12 mb-25px d-flex flex-column">
                            <strong className="fw-bold pb-1 required">
                              Task Title:{" "}
                            </strong>
                            <input
                              type="text"
                              className="form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                              defaultValue={related_task?.title}
                              data-id={related_task?.id}
                              data-type={related_task?.type}
                              onChange={(e: any) =>
                                assignpopupfieldchangeinput(
                                  e,
                                  related_task?.id,
                                  related_task?.type,
                                  "task_title"
                                )
                              }
                            />
                            {related_task?.title === "" && (
                              <span style={{ color: "red" }}>
                                Please add title.
                              </span>
                            )}
                          </div>

                          <div className="col col-12 mb-22px">
                            <strong className="fw-bold pe-8px">Company:</strong>
                            <span className="fw-bold text-primary text-decoration-underline me-15">
                              <a
                                href={related_task?.company_url}
                                target="_blank"
                                className="fw-bold text-primary"
                                rel="noreferrer"
                              >
                                {eventDetail?.company_name}
                              </a>
                            </span>
                            <strong className="fw-bold pe-8px">Project:</strong>
                            <span className="fw-bold text-primary text-decoration-underline">
                              <a
                                href={related_task?.project_url}
                                target="_blank"
                                className="fw-bold text-primary"
                                rel="noreferrer"
                              >
                                {related_task?.project_name}
                              </a>
                            </span>
                          </div>

                          <div className="col mb-4 flex-column">
                            <strong className="fw-bold">Priority:&nbsp;</strong>
                            {prioritylist && (
                              <Select
                                value={prioritylist1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_task?.priority)
                                )}
                                isMulti
                                isDisabled={true}
                                isClearable={false}
                                name="Issues"
                                options={handleHideOptionalFunc(
                                  prioritylist1,
                                  related_task?.priority
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "priority"
                                  )
                                }
                              />
                            )}
                          </div>

                          <div className="col mb-4">
                            <strong className="fw-bold">Primary:</strong>
                            <div className="position-relative primeDiv">
                              {(related_task?.type === "task" ||
                                related_task?.type === "servicecall") &&
                                taskRoleArray && (
                                  <Select
                                    value={taskRoleArray?.filter(
                                      (trole: any) =>
                                        parseInt(trole.resourceID) ===
                                          parseInt(
                                            related_task?.assignedResourceID
                                          ) &&
                                        parseInt(trole.roleID) ===
                                          parseInt(
                                            related_task?.assignedResourceRoleID
                                          )
                                    )}
                                    isMulti
                                    isClearable={false}
                                    placeholder="Select primary resource"
                                    name="Primary Resource"
                                    options={
                                      hideOptions
                                        ? taskRoleArray?.filter(
                                            (trole: any) =>
                                              (parseInt(trole.resourceID) ===
                                                parseInt(
                                                  related_task?.assignedResourceID
                                                ) &&
                                                parseInt(trole.roleID) ===
                                                  parseInt(
                                                    related_task?.assignedResourceRoleID
                                                  )) ||
                                              trole.isDisabled === false
                                          )
                                        : taskRoleArray
                                    }
                                    components={{ Option: CustomOption }}
                                    formatOptionLabel={formatOptionLabel}
                                    isOptionDisabled={(option: any) =>
                                      option.isDisabled || !option.has_license
                                    }
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    // styles = {{option: (provided:any, state:any) => ({
                                    //     ...provided,
                                    //     backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
                                    // })}}
                                    // styles={{
                                    //     control: (baseStyles:any, state:any) => ({
                                    //       ...baseStyles,
                                    //       background: state.isDisabled ? 'red' : '',
                                    //     }),
                                    // }}
                                    onChange={(e: any) =>
                                      changeprimaryresid(
                                        e,
                                        related_task?.id,
                                        related_task?.type
                                      )
                                    }
                                  />
                                )}
                              {taskRoleArray?.filter(
                                (trole: any) =>
                                  parseInt(trole.resourceID) ===
                                    parseInt(
                                      related_task?.assignedResourceID
                                    ) &&
                                  parseInt(trole.roleID) ===
                                    parseInt(
                                      related_task?.assignedResourceRoleID
                                    ) &&
                                  trole.isDisabled === true
                              )?.length !== 0 && (
                                <>
                                  <span style={{ color: "red" }}>
                                    Selected resource is inactive from autotask.
                                  </span>
                                </>
                              )}
                              {related_task?.errorinDefaultrole ===
                                "in_primary" && (
                                <>
                                  <span>
                                    <b>Note: </b>Resource role is not selected
                                    by default. Please set default settings
                                    (roles) from the resource screen.
                                  </span>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="col mb-4">
                            <strong className="fw-bold">
                              Secondary Resource(s):
                            </strong>
                            <div className="position-relative">
                              <div
                                className="dropDown position-relative"
                                style={{}}
                              >
                                <Select
                                  value={tasksacendaryRoleArray.filter(
                                    (e: any) =>
                                      related_task?.secondary_resources
                                        ?.map(
                                          (axisavailability: any) =>
                                            axisavailability.value
                                        )
                                        .includes(e.value)
                                  )}
                                  isMulti
                                  placeholder="Select secondary resource"
                                  name="Secondary Resource"
                                  options={
                                    hideOptions
                                      ? tasksacendaryRoleArray?.filter(
                                          (twork: any) =>
                                            parseInt(twork.resourceID) !==
                                              parseInt(
                                                related_task?.assignedResourceID
                                              ) && twork.isActive === true
                                        )
                                      : tasksacendaryRoleArray?.filter(
                                          (twork: any) =>
                                            parseInt(twork.resourceID) !==
                                            parseInt(
                                              related_task?.assignedResourceID
                                            )
                                        )
                                  }
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  components={{ Option: CustomOption }}
                                  formatOptionLabel={formatOptionLabel}
                                  isOptionDisabled={(option: any) =>
                                    option.isDisabled || !option.has_license
                                  }
                                  onChange={(e: any) =>
                                    handleSecondaryResourceChange(
                                      e,
                                      related_task?.id,
                                      related_task?.type
                                    )
                                  }
                                />

                                {tasksacendaryRoleArray
                                  .filter((e: any) =>
                                    related_task?.secondary_resources
                                      ?.map(
                                        (axisavailability: any) =>
                                          axisavailability.value
                                      )
                                      .includes(e.value)
                                  )
                                  ?.filter(
                                    (trole: any) => trole.isDisabled === true
                                  )?.length !== 0 && (
                                  <>
                                    <span
                                      className="text-nowrap"
                                      style={{ color: "red" }}
                                    >
                                      Selected one or more resources are
                                      inactive from autotask.
                                    </span>
                                  </>
                                )}
                                <br />
                                {related_task?.errorinDefaultrole ===
                                  "in_secondary" && (
                                  <>
                                    <span>
                                      <b>Note: </b>Resource role is not selected
                                      by default. Please set default settings
                                      (roles) from the resource screen.
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col mb-4">
                            <strong className="fw-bold required">
                              Worktype:
                            </strong>

                            {worktype && (
                              <Select
                                value={worktype1?.filter(
                                  (trole: any) =>
                                    parseInt(trole.value) ===
                                    parseInt(related_task?.billingCodeID)
                                )}
                                isMulti
                                isClearable={false}
                                placeholder="Select work type"
                                name="Work Type"
                                options={handleHideOptionalFunc(
                                  worktype,
                                  related_task?.billingCodeID
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "worktype"
                                  )
                                }
                              />
                            )}
                            {related_task?.billingCodeID === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select work type.
                                </span>
                              </>
                            )}
                            {worktype1?.filter(
                              (trole: any) =>
                                parseInt(trole.value) ===
                                  parseInt(related_task?.billingCodeID) &&
                                trole.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected worktype is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-20px">
                            <strong className="fw-bold required">
                              Status:
                            </strong>
                            {task_status1 && (
                              <Select
                                value={task_status1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_task?.status)
                                )}
                                isMulti
                                isClearable={false}
                                placeholder="Select status"
                                name="Status"
                                options={handleHideOptionalFunc(
                                  task_status1,
                                  related_task?.status
                                )}
                                // options={task_status1}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "task_status"
                                  )
                                }
                              />
                            )}
                            {related_task?.status === "" && (
                              <>
                                <span style={{ color: "red" }}>
                                  Please select status.
                                </span>
                              </>
                            )}
                            {task_status1?.filter(
                              (twork: any) =>
                                parseInt(twork.value) ===
                                  parseInt(related_task?.status) &&
                                twork.isActive === false
                            )?.length !== 0 && (
                              <>
                                <span style={{ color: "red" }}>
                                  Selected status is inactive from autotask.
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col mb-4">
                            <strong className="fw-bold">Issue Type:</strong>
                            {issueslist && (
                              <Select
                                value={issueslist1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_task?.issueType)
                                )}
                                isMulti
                                isDisabled={true}
                                isClearable={false}
                                placeholder="Select issue type"
                                name="Issues"
                                options={handleHideOptionalFunc(
                                  issueslist1,
                                  related_task?.subIssueType
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "issue"
                                  )
                                }
                              />
                            )}
                          </div>

                          <div className="col mb-20px flex-column">
                            <strong className="fw-bold">Sub Issue Type:</strong>
                            {subIssueslist && (
                              <Select
                                value={subIssueslist1?.filter(
                                  (twork: any) =>
                                    parseInt(twork.value) ===
                                    parseInt(related_task?.subIssueType)
                                )}
                                isMulti
                                isDisabled={true}
                                placeholder="Select sub-issue type"
                                isClearable={false}
                                name="Issues"
                                options={
                                  hideOptions
                                    ? subIssueslist1?.filter(
                                        (twork: any) =>
                                          parseInt(twork.parentIssue) ===
                                            parseInt(related_task?.issueType) &&
                                          twork.isActive === true
                                      )
                                    : subIssueslist1?.filter(
                                        (twork: any) =>
                                          parseInt(twork.parentIssue) ===
                                          parseInt(related_task?.issueType)
                                      )
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "subissue"
                                  )
                                }
                              />
                            )}
                          </div>

                          {/* location feild  */}
                          {locationShow && (
                            <div className="col mb-4">
                              <strong className="fw-bold">Location:</strong>
                              <Select
                                value={
                                  locations?.data
                                    ? locations?.data
                                        ?.filter(
                                          (twork: any) =>
                                            parseInt(twork?.autotask_id) ===
                                            parseInt(
                                              related_task?.companyLocationID
                                            )
                                        )
                                        .map((option) => ({
                                          label: option.name,
                                          value: option.autotask_id,
                                        }))
                                    : []
                                }
                                isMulti
                                isClearable={false}
                                placeholder="Select Location"
                                name="Location"
                                options={
                                  locations?.data
                                    ? locations?.data
                                        ?.filter(
                                          (twork: any) =>
                                            twork.companyID ===
                                            eventDetail?.companyID
                                        )
                                        .filter((twork: any) => !hideOptions || twork.isActive) 
                                        .map((option) => ({
                                          label: option.name,
                                          value: option.autotask_id,
                                          isPrimary: option.isPrimary,
                                          isActive: option.isActive,
                                        }))
                                    : []
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isOptionDisabled={(option: any) =>
                                  !option.isActive
                                }
                                onChange={(e: any) =>
                                  assignpopupfieldchange(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "location"
                                  )
                                }
                              />
                               {locations?.data ? locations?.data?.filter(
                      (twork: any) =>
                        parseInt(twork.autotask_id) ===
                          parseInt(related_task?.companyLocationID) &&
                        twork.isActive === false
                    )?.length !== 0 && (
                      <>
                        <span style={{ color: "red" }}>
                          Selected Location is inactive from autotask.
                        </span>
                      </>
                    ) :null}
                            </div>
                          )}

                          <div className="col col-12 mb-4">
                            <strong className="fw-bold pe-8px">
                              First response:
                            </strong>
                            <span className="fw-bold text-primary me-15">
                              {related_task?.firstResponseDateTime
                                ? moment(related_task?.firstResponseDateTime)
                                    .tz(timezone)
                                    .format("ddd DD-MM-YYYY")
                                : ""}{" "}
                              {related_task?.firstResponseDateTime
                                ? moment(related_task?.firstResponseDateTime)
                                    .tz(timezone)
                                    .format(time_format)
                                : "-"}
                              <KTSVG
                                path="/media/icons/duotune/new-icons/datepicker.svg"
                                className="d-inline-block m-0 ms-2 text-dark d-none"
                                svgClassName="w-20px h-auto"
                              />
                            </span>
                            <strong className="fw-bold pe-8px">
                              Due date:
                            </strong>
                            <span className="fw-bold text-primary">
                              {related_task?.endDateTime
                                ? moment(related_task?.endDateTime)
                                    .tz(timezone)
                                    .format("ddd DD-MM-YYYY")
                                : ""}{" "}
                              {related_task?.endDateTime
                                ? moment(related_task?.endDateTime)
                                    .tz(timezone)
                                    .format(time_format)
                                : "-"}
                              <KTSVG
                                path="/media/icons/duotune/new-icons/datepicker.svg"
                                className="d-inline-block m-0 ms-2 text-dark d-none"
                                svgClassName="w-20px h-auto"
                              />
                            </span>
                          </div>

                          <div className="col col-12 flex-column">
                            <strong className="fw-bold">Description:</strong>
                            <span>
                              <textarea
                                placeholder="Description"
                                className="form-control form-control-solid h-55px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                                rows={3}
                                name="popuptaskdescription"
                                id="popuptaskdescription"
                                data-id={related_task?.id}
                                data-type={related_task?.type}
                                onChange={(e: any) =>
                                  assignpopupfieldchangeinput(
                                    e,
                                    related_task?.id,
                                    related_task?.type,
                                    "task_description"
                                  )
                                }
                              >
                                {related_task?.description}
                              </textarea>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
        </>
      </div>
      {showDropdown && (
        <div className="dropdown-menu show position-absolute start-0 ms-7 fs-14 py-3 border-0 rounded-4px min-w-100px" style={{ boxShadow: '0 0 50px rgba(82, 63, 195, 0.15)' }}>
          
          <button className="dropdown-item py-1 px-4 mb-1 text-dark" onClick={() => handleNewOption("Task")}>
            Task
          </button>
          {(screenName === "planning") &&
            <button className="dropdown-item py-1 px-4 text-dark" onClick={() => handleNewOption("Ticket")}>
              Ticket
            </button>
          }
        </div>
      )}
    </Popup>
    {taskList && (
      <TaskTicketList
        taskList={taskList}
        setTaskList={setTaskList}
        eventDetail={eventDetail}
        seteventDetail={seteventDetail}
        filterArray={filterArray} 
        view={view} 
        openDropdown={openDropdown} 
        setOpenDropdown = {setOpenDropdown}
        toggleDropdown={toggleDropdown} 
        general_settings={general_settings}
        taskListType={taskListType}
        taskTicketId={taskTicketId}
        setTaskTicketid={setTaskTicketid}
        filterIds={filterIds}
        assignedresources={assignedresources}
      />
    )}
    </>
  );
};

export default ServicecallUpdate;
