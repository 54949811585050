import { FC, useState } from 'react'
import { ApiLog } from '../../../core/_model';

type Props = {
  user: ApiLog,
}

const ApiLogInfoApiCell: FC<Props> = ({ user }) => {

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <span>{user?.type}</span>
        </div>
      </div>
    </>
  )
}

export { ApiLogInfoApiCell }
