import { FC, useEffect, useState } from "react";
import { ActivityTabs } from "../../../components/taskdetailScreen/ActivityTabcontent/ActivityTabs";
import { ActivityTags } from "../../../components/taskdetailScreen/ActivityTabcontent/ActivityTags";
import { ActivityTaskHeader } from "../../../components/taskdetailScreen/ActivityTabcontent/ActivityTaskStatus";

const TaskDetailDrawer: FC = () => {
 
    const data  = [
        {
            start: '2022-10-10T00:00',
            end: '2022-10-12T01:00',
            title: 'Service Call 1,Client C',
            resource: 4,
            color: '#FFC1E6'
        },
    ];

    const [tasks,setTasks] = useState(data);
    useEffect(() => {

    },[tasks]);

  return (
    <div
      id="kt_taskdetail"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="taskdetail"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'100%', 'md': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_taskdetail_toggle"
      data-kt-drawer-close="#kt_taskdetail_close"
    >
      <div className="card shadow-none rounded-0 w-100">
     
        <div className="card-body position-relative py-0 px-3" id="kt_taskdetail_body">
          <div
            id="kt_taskdetail_scroll"
            className="position-relative me-n5 pe-5 d-flex flex-column"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_taskdetail_body"
            data-kt-scroll-dependencies="#kt_taskdetail_header, #kt_taskdetail_footer"
            data-kt-scroll-offset="5px"
          >
            <ActivityTaskHeader
              descriptionnote="Go ahead and create your project from here."
              taskdata={tasks[0]?.title}
            />            
            <div className="actBtm h-100 scroll-y">                          
              <ActivityTags
                lefttab="Assignees"
                useroverlay="PS"
                duedate="Sep 21, 2022 at 11:30 AM"
                righttab="Tags"
                prioritybutton="High Priority"
                addbutton="Add"
                dependancy="Dependancy"
                setbutton="Set"
              />
              <div className="separator border-gray-101 mb-4"></div>
              <ActivityTabs 
                  taskadd="Add a new Subtask...." 
              />
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
};

export { TaskDetailDrawer };