/* eslint-disable @typescript-eslint/no-unused-vars */
import { Page } from '@mobiscroll/react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { AdminMedia } from './setting-manage/AdminMedia'
import { SettingSideBar } from './setting-manage/components/SettingSideBar'
import { EmailSetting } from './setting-manage/EmailSetting'
import { GeneralSettingView } from './setting-manage/General'
import { InvoiceSettingView } from './setting-manage/InvoiceSetting'
import { Security } from './setting-manage/Security'

const emailBreadcrumbs: Array<PageLink> = [
  {
    title: 'General Setting',
    path: '/superadmin/general-settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

let GeneralSetting = {
  _id: undefined,
  daily_availability: undefined,
  planning_screen_view: undefined,
  task_screeen_view: undefined,
  service_call_duration: undefined,
  task_duration: undefined,
  ticket_duration: undefined,
  task_color: undefined,
  ticket_color: undefined,
  service_call_color: undefined,
  planning_first_day_of_week: undefined,
  task_first_day_of_week: undefined
}

const GeneralSettingPage = () => {
  return (
    <div className='row m-0 px-6 py-6'>
      <Routes>
        <Route
          element={
            <>
              <SettingSideBar />
              <Outlet />
            </>
          }
        >
          <Route
            path='general'
            element={
              <>
                <PageTitle breadcrumbs={emailBreadcrumbs}>General</PageTitle>
                <GeneralSettingView />
              </>
            }
          />
          <Route
            path='security'
            element={
              <>
                <PageTitle breadcrumbs={emailBreadcrumbs}>Security</PageTitle>
                <Security />
              </>
            }
          />
          <Route
            path='admin-media'
            element={
              <>
                <PageTitle breadcrumbs={emailBreadcrumbs}>Admin Media</PageTitle>
                <AdminMedia />
              </>
            }
          />
          <Route
            path='email-setting'
            element={
              <>
                <PageTitle breadcrumbs={emailBreadcrumbs}>Email Setting</PageTitle>
                <EmailSetting />
              </>
            }
          />
          <Route
            path='invoice'
            element={
              <>
                <PageTitle breadcrumbs={emailBreadcrumbs}>Invoice</PageTitle>
                <InvoiceSettingView />
              </>
            }
          />
        </Route>
        <Route index element={<Navigate to='/superadmin/general-settings/general'/>} />
      </Routes>
    </div>
  )
}

export default GeneralSettingPage
