import React, { useState, FC, useEffect } from "react";
import * as Yup from "yup";
import { Loading } from "../../../components/loading/Loading";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ProfileDetail, initialProfileDetail } from "./_model";
import { useFormik } from "formik";
import { alert } from "@mobiscroll/react";
import {
  createProfileDetail,
  getProfileDetailUser,
  updateProfileDetailUser,
} from "../../../../_metronic/requests/ProfiledetailRequest";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const profileDetailSchema = Yup.object().shape({
  username: Yup.string()
    .nullable()
    .required("Username is required")
    .matches(/^(\S+$)/g, "Username cannot contain blankspaces"),

  email: Yup.string()
    .nullable()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),

  phone_no: Yup.string()
    .nullable()
    .matches(/^\S*$/, "Phone number is not valid")
    .min(5, "Phone number is not valid")
    .max(15, "Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid"),

  mobile_no: Yup.string()
    .nullable()
    .matches(/^\S*$/, "Mobile number is not valid")
    .min(5, "Mobile number is not valid")
    .max(15, "Mobile number is not valid")
    .matches(phoneRegExp, "Mobile number is not valid"),

  is_active: Yup.boolean().nullable(),
});

const ProfilePage: FC = () => {
  const [is_user_active, setUseractive] = useState<any>(null);
  const [background] = useState<any>();
  const [imageValidation, setimageValidation] = useState("");

  const navigate = useNavigate();

  const { refetch, data: profileDetail } = useQuery(
    `profile-detail`,
    async () => {
      return await getProfileDetailUser("");
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  let profileDetailData = profileDetail ? profileDetail : initialProfileDetail;

  let userForEdit = profileDetail ? profileDetail : initialProfileDetail;

  const [image, setimage] = useState<any>("");
  const [url, seturl] = useState<any>("");
  const [imglength, setImglen] = useState(false);

  useEffect(() => {
    seturl(profileDetail?.avatar);
    if (is_user_active === null || is_user_active === undefined) {
      setUseractive(profileDetail?.is_active);
    }
  }, [profileDetail, profileDetailData, is_user_active, background]);

  const selectBackground = (e: any) => {
    const imageFile = e.currentTarget.files[0];

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setimageValidation("Uploaded file has unsupported format.");
      return false;
    }
    setImglen(true);
    setimageValidation("");
    setimage(e.currentTarget.files[0]);
  };

  const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");

  const updateTasktypeData = (fieldsToUpdate: Partial<ProfileDetail>): void => {
    const updatedData = Object.assign(profileDetailData, fieldsToUpdate);
    setUseractive(fieldsToUpdate?.is_active);
  };

  /* Moment timezone */
  var moment = require("moment-timezone");
  var timeZones = moment.tz.names();
  var offsetTmz = [];
  for (var i in timeZones) {
    offsetTmz.push(timeZones[i]);
  }

  const getUserPic = (pic: any) => {
    if (image == null) {
      return `url(${toAbsoluteUrl(`${blankImg}`)})`;
    }
    if (image) {
      return `url(${URL.createObjectURL(image)})`;
    }
    let path = `${url}`;
    return `url(${path})`;
  };

  const removeImg = (e: any) => {
    setimage(null);
    setImglen(false);
    setimageValidation("");
    seturl("");
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik<ProfileDetail>({
    enableReinitialize: true,
    initialValues: userForEdit,
    validationSchema: profileDetailSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        values.is_active = is_user_active;

        let fdata: any = new FormData();
        Object.keys(values).forEach((key) => {
          if (
            key != "avatar" &&
            values[key as keyof ProfileDetail] !== "undefined"
          ) {
            fdata.append(key, values[key as keyof ProfileDetail]);
          }
        });
        fdata.append("avatar", image!);

        if (image != "") {
          if (isNotEmpty(values._id)) {
            await updateProfileDetailUser(fdata);
          } else {
            await createProfileDetail(fdata);
          }
        } else {
          if (isNotEmpty(values._id)) {
            await updateProfileDetailUser(values);
          } else {
            await createProfileDetail(values);
          }
        }
        navigate("/apps/profile");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        alert({
          message: "Changes saved successfully!",
          callback: function (result) {
            window.location.reload();
          },
        });
      } catch (ex) {
        navigate("/apps/profile");
        setSubmitting(true);
        setLoading(false);
        alert({
          message: "Something went wrong",
          callback: function (result) {
          },
        });
      } finally {
      
      }
    },
  });

  return (
    <>
      <div className="card h-100 py-6 px-7 bg-transparent notifyHeight">
        <div id="kt_profile_details" className="h-100 bg-white rounded">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form d-flex flex-column h-100 overflow-auto"
          >
            <div className="card-header">
              <div className="card-title m-0 w-100 justify-content-between">
                <h3 className="text-dark fw-bold fs-18 m-0">My profile</h3>
                <button
                  type="submit"
                  className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16"
                  disabled={loading || imageValidation != "" || !formik.isValid}
                >
                  {!loading && "Save changes"}
                  <Loading
                    isLoading={loading}
                    text={"Please wait..."}
                  ></Loading>
                </button>
              </div>
            </div>
            <div className="card-body border-top py-5 overflow-auto mh-100">
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Profile image
                </label>

                <div className="fv-row col-md-8">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{ backgroundImage: `url('${blankImg}')` }}
                  >
                    {/* begin::Preview existing avatar */}
                    {image != "" || url != "" ? (
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{ backgroundImage: `${getUserPic(image)}` }}
                      ></div>
                    ) : (
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{ backgroundImage: `url('${blankImg}')` }}
                      ></div>
                    )}
                    {/* end::Preview existing avatar */}

                    {/* begin::Label */}
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change profile image"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>

                      <input
                        type="file"
                        name="file"
                        id="file"
                        accept="image/*"
                        onChange={(event: any) => {
                          selectBackground(event);
                        }}
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    {imglength == true || url != "" || imageValidation != "" ? (
                      <div>
                        <label
                          onClick={(e: any) => removeImg(e)}
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          title=""
                          data-original-title="Remove avatar"
                        >
                          <i className="bi bi-x fs-2 fs-ls-25"></i>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {imageValidation != ""}
                  <div
                    className="fv-plugins-message-container"
                    style={{ marginTop: "3px" }}
                  >
                    <div className="fv-help-block fs-8">{imageValidation}</div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Full name
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("name")}
                    disabled={true}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Username
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("username")}
                    disabled
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.username}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Email
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("email")}
                    disabled
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.email}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Phone number
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("phone_no")}
                  />
                  {formik.touched.phone_no && formik.errors.phone_no && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.phone_no}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Mobile number
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("mobile_no")}
                  />
                  {formik.touched.mobile_no && formik.errors.mobile_no && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block fs-8">
                        {formik.errors.mobile_no}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Note
                </label>

                <div className="fv-row col-md-8">
                  <input
                    type="text"
                    className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                    {...formik.getFieldProps("note")}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Active?
                </label>
                <div className="d-flex fv-row fv-row col-md-8">
                  <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-dark form-switch fv-row">
                      <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="is_active"
                        defaultChecked={
                          is_user_active === null
                            ? profileDetail?.is_active
                            : is_user_active
                        }
                        onClick={() => {
                          updateTasktypeData({
                            is_active:
                              is_user_active === null
                                ? !profileDetail?.is_active
                                : !is_user_active,
                          });
                        }}
                      ></input>
                      <label className="form-check-label"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                  Tour/Hint
                </label>
                <div className="col-md-8">
                  <label className="col-form-label fw-bold fs-16 text-dark">
                    Please select the tour/hint of the page which you want to
                    view
                  </label>
                  <div className="col-12">
                    <div className="row row-cols-2">
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="Dashboard" className="min-w-210px">
                            Dashboard
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Dashboard"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.Dashboard === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Dashboard"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="Planning" className="min-w-210px">
                            Planning
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Planning"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.Planning === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Planning"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="Users" className="min-w-210px">
                            Users
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Users"}
                              checked={
                                formik.values.showTooltipVisibility?.Users ===
                                true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Users"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="Projects" className="min-w-210px">
                            Projects
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Projects"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.Projects === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Projects"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="Notifications"
                            className="min-w-210px"
                          >
                            Notifications
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Notifications"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.Notifications === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Notifications"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="LogsLogin" className="min-w-210px">
                            Logs-Login
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"LogsLogin"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.LogsLogin === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.LogsLogin"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="LogsAPIs" className="min-w-210px">
                            Logs-Api
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"LogsAPIs"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.LogsAPIs === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.LogsAPIs"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="SettingsGeneral"
                            className="min-w-210px"
                          >
                            Settings-General
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"SettingsGeneral"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.SettingsGeneral === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.SettingsGeneral"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="SettingsEmails"
                            className="min-w-210px"
                          >
                            Settings-Emails
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"SettingsEmails"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.SettingsEmails === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.SettingsEmails"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="SettingsSSO" className="min-w-210px">
                            Settings-SSO
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"SettingsSSO"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.SettingsSSO === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.SettingsSSO"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="AISettings" className="min-w-210px">
                            AI Settings
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"AISettings"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.AISettings === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.AISettings"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="MyOrganisation"
                            className="min-w-210px"
                          >
                            My Organisation
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"MyOrganisation"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.MyOrganisation === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.MyOrganisation"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="BillingAndSubscription_Invoice"
                            className="min-w-210px"
                          >
                            Billing & Subscription - Invoice
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"BillingAndSubscription_Invoice"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.BillingAndSubscription_Invoice === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.BillingAndSubscription_Invoice"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="BillingAndSubscription_Payment"
                            className="min-w-210px"
                          >
                            Billing & Subscription - Payment
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"BillingAndSubscription_Payment"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.BillingAndSubscription_Payment === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.BillingAndSubscription_Payment"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label
                            htmlFor="BillingAndSubscription_Subscription"
                            className="min-w-210px"
                          >
                            Billing & Subscription - Subscription
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"BillingAndSubscription_Subscription"}
                              checked={
                                formik.values.showTooltipVisibility
                                  ?.BillingAndSubscription_Subscription === true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.BillingAndSubscription_Subscription"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="d-flex align-item-center">
                          <label htmlFor="Profile" className="min-w-210px">
                            My Profile
                          </label>
                          <div className="form-check form-check-dark form-switch fv-row">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id={"Profile"}
                              checked={
                                formik.values.showTooltipVisibility?.Profile ===
                                true
                              }
                              {...formik.getFieldProps(
                                "showTooltipVisibility.Profile"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ProfilePage;
