import { Authenticator, Heading, Button, useAuthenticator } from '@aws-amplify/ui-react';
import { signInWithRedirect, signOut,  fetchAuthSession } from "aws-amplify/auth";
import { Col, Row } from 'react-bootstrap';
import React, { FC, useEffect,  useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { Error404Cognito } from '../../modules/errors/components/Error404Cognito';

type Props = {
    cognitoData: any;
};

export const Auth: FC<Props> = ({ cognitoData }) => {

    const [continueurl, setContinueUrl] = useState<any>('')
    const [showMicrosoftButton] = useState<boolean>(cognitoData?.data?.outlook_sync_is_active_mfo || false)
    const canProceed = window.location.search.includes('continueurl') && cognitoData?.data?.cognito?.poolId && cognitoData?.data?.cognito?.clientid && cognitoData?.data?.cognito?.userPoolUri;
    const provider = {
        custom: 'Microsoft'
    }

    const subdomainUrl = new URLSearchParams(window.location.search);
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: cognitoData?.data?.cognito?.clientid,
                userPoolId: cognitoData?.data?.cognito?.poolId,
                loginWith: { // Optional
                    oauth: {
                        domain: cognitoData?.data?.cognito?.userPoolUri,
                        scopes: ["openid", "email", "profile"],
                        redirectSignIn: [window.location.origin + "/?continueurl=" + decodeURIComponent(subdomainUrl.get('continueurl'))],
                        redirectSignOut: [window.location.origin + "/?continueurl=" + decodeURIComponent(subdomainUrl.get('continueurl'))], 
                        responseType: 'code',
                    },
                    username: true,
                    email: true, // Optional
                    phone: false, // Optional
                },

            }
        }
    });

    function handleSignInClick() {
        signInWithRedirect({
            provider
        })
    }

    const disableSplashScreen = () => {
        const splashScreen = document.getElementById("splash-screen");
        if (splashScreen) {
            splashScreen.style.setProperty("display", "none");
        }
    };
    const listener = async (data) => {
        console.log("listner", JSON.stringify(data?.payload));
        if (data?.payload?.event === "signedIn") {
            const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
            if (localStorage.getItem("continueurl") !== null) {
                window.location.replace(String(decodeURIComponent(localStorage.getItem('continueurl'))).replace(/['"]+/g, '') + '?accessToken=' + idToken.toString() + '&poolId=' + cognitoData?.data?.cognito?.poolId);
            } else {
                window.location.replace(String(decodeURIComponent(continueurl)).replace(/['"]+/g, '') + '?accessToken=' + idToken.toString() + '&poolId=' + cognitoData?.data?.cognito?.poolId);
            }
        };
    }

    Hub.listen('auth', listener);

    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search);
        if (params.has('continueurl')) {
            setContinueUrl(String(params.get('continueurl')).replace(/['"]+/g, ''))
            disableSplashScreen()
            if (params.has('signout')) {
                handleSignOut();
                localStorage.clear();
                window.location.replace(window.location.origin + '?continueurl=' + params.get('continueurl'));
            }
            if (localStorage.getItem("continueurl") === null) {
                localStorage.setItem('continueurl', String(params.get('continueurl')).replace(/['"]+/g, ''))
            } else if (localStorage.getItem("continueurl") !== params.get('continueurl') && params.has('code') === false) {
                localStorage.clear();
            }
        }

        const user = async () => {
            const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
            if (idToken) {
                window.location.replace(String(decodeURIComponent(params.get('continueurl'))).replace(/['"]+/g, '') + '?accessToken=' + idToken.toString() + '&poolId=' + cognitoData?.data?.cognito?.poolId);
            }
        }
        user();

    }, [])


    async function handleSignOut() {
        try {
            await signOut({ global: true });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        canProceed ? (
            <Row className="m-0 h-100">
                <Col md="3" style={{ width: '40%' }} className='bg-primary position-relative'>
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <img
                            alt='Proxuma'
                            className='h-auto logo w-250px'
                            src='/media/logos/horizontal-dark-bg.svg'
                        />
                    </div>

                    <div className="position-absolute start-0 bottom-0 fs-16 text-white ms-4 ps-4 mb-10 pb-10 fw-bold">{process.env.REACT_APP_RELEASE_VERSION}</div>
                </Col>
                <Col md="9" className='d-flex align-items-center justify-content-center flex-column' style={{ width: '60%', backgroundColor: '#E8EDF4' }}>

                    <Authenticator hideSignUp={true}
                        components={{
                            SignIn: {
                                Footer() {
                                    const { toForgotPassword } = useAuthenticator();
                                    return (
                                        <Heading>
                                            <Button
                                                className='forgotLink'
                                                onClick={toForgotPassword}
                                                size="small"
                                                variation="link"
                                            >
                                                Forgot your password?
                                            </Button>
                                            {showMicrosoftButton && <div className='d-flex align-items-center justify-content-center mt-3'>
                                                <Button
                                                    className='loginMicro w-100 h-45px p-0 d-flex align-items-center justify-content-center bg-white'
                                                    onClick={handleSignInClick}>
                                                    Login with Microsoft
                                                </Button>
                                            </div>}
                                        </Heading>
                                    );
                                }
                            }
                        }}
                    >
                        {() => (
                            <>  <h1>Redirecting...</h1>
                            </>

                        )}
                    </Authenticator>
                </Col>
            </Row>
        ) : <Error404Cognito></Error404Cognito>
    )

}