import React, { FC, useEffect, useState,useMemo,useCallback } from "react";

const FilterTooltip = () => {
  
  return (
    <>
      <div className="tooltip bs-tooltip-bottom cusTooltip">
        <div className="tooltip-arrow position-absolute start-50 translate-middle-x"></div>         
        <div className="tooltip-inner">
          <span className="text-nowrap">Clear filter</span>
        </div>             
      </div>   
    </>
  )
};
export default FilterTooltip;