import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { isNotEmpty, KTSVG } from "../../../../../../_metronic/helpers";
import { ApiLog } from "../core/_model";
import { updateResourceSetting } from "../../../../../../_metronic/requests/ResourceSettingRequest";
import { Loading } from "../../../../../components/loading/Loading";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { ListLoading } from "../../../../../components/loading/ListLoading";
import moment from "moment";

type Props = {
  show: boolean;
  handleClose: () => void;
  userdata?: any;
};

const ViewModal = ({ show, handleClose, userdata }: Props) => {
  const [loading, setLoading] = useState(false);
  const [jsonpayload, setJsonpayload] = useState(null);
  const [jsonresponse, setJsonresponse] = useState(null);
  const { refetch } = useQueryResponse();
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
      handleClose();
    }
    handleClose();
  }
  useEffect(() => {
    setJsonpayload(userdata?.original?.params);
    setJsonresponse(userdata?.original?.response_data);
  }, [userdata])

  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-1000px fullHeightmodal"
        show={show}
        onHide={handleClose}
      >

        <div className="modal-header">
          <h2 className="fs-3">{userdata?.original?.autotask_entiry}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
            onClick={handleClose}
          >
            <KTSVG
              className="text-dark"
              svgClassName="w-12px h-12px"
              path="/media/icons/duotune/extra/close.svg"
            />
          </div>
        </div>

        <div className='modal-body'>
          <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
            <Tab eventKey="home" title="Summary">
              <div className='modal-body'>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Action</label>

                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.action}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Autotask Entiry</label>

                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.autotask_entiry}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Created at</label>

                  <div className='col-lg-8 scroll-y'>
                    <span>{moment(userdata?.original?.created_at).tz(localStorage.getItem('timezone')!).format("DD-MM-YYYY " + localStorage.getItem('time_format')!)}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>IP address</label>
                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.ip_address}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>API type</label>
                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.type}</span>
                  </div>
                </div>

              </div>
            </Tab>
            <Tab eventKey="profile" title="Request">
              <div className='modal-body'>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Request URL: </label>
                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.request_url}</span>
                  </div>
                </div>
                {jsonpayload &&
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bolder fs-6 text-dark'>Payload: </label>
                    <div className='col-lg-8 scroll-y'>
                      <pre>{JSON.stringify((JSON.parse(jsonpayload)), null, 2)}
                      </pre>
                    </div>
                  </div>
                }
              </div>
            </Tab>
            <Tab eventKey="contact" title="Response">
              <div className='modal-body'>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Response status: </label>
                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.resoponse_status}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bolder fs-6 text-dark'>Response Type: </label>
                  <div className='col-lg-8 scroll-y'>
                    <span>{userdata?.original?.resoponse_type}</span>
                  </div>
                </div>
                {jsonresponse &&
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bolder fs-6 text-dark'>Response Data: </label>
                    <div className='col-lg-8 scroll-y'>

                      <pre>{JSON.stringify((JSON.parse(jsonresponse)), null, 2)}</pre>
                    </div>
                  </div>
                }
              </div>
            </Tab>
          </Tabs>
        </div>


      </Modal>
    </>
  );
};

export { ViewModal }
