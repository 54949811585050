import React, { useState, FC, useEffect } from "react";
import * as Yup from "yup";
import { Loading } from "../../../components/loading/Loading";
import { useQuery } from "react-query";
import {KTSVG} from "../../../../_metronic/helpers";
import { useFormik } from "formik";
import { alert } from "@mobiscroll/react";
import {  createInvoiceSetting, getInvoiceSetting, getRoles } from "./core/_requests";

interface role_pricing  {
    _id?: string,
    roleId: string,
    description: string,
    monthly_price: number,
}

interface subscription_type {
    _id?: string,
    name: string,
    discount_percentage: number,
    months: number,
}
  
interface initialSettingType {
    role_pricings: role_pricing[],
    subscription_types: subscription_type[]
    deleted_items: []
}

const InvoiceSetting: FC = () => {
    const [deletedItems, setdeletedItems] = useState<any>([]);
    const {
        isFetching: isFetchingInvoice,
        refetch:refetchinvoice,
        data: invoiceSettingDetail } = useQuery(
        `invoice-setting-detail`,
        async () => {
            return await getInvoiceSetting('');
        },
        {
            cacheTime: 0,
            onError: (err) => {
                console.error(err);
            },
        }
    );

    const {
        isFetching: isFetchingRole,
        refetch:refetchrole,
        data: roleList } = useQuery(
        `role-list-detail-invoice`,
        async () => {
            return await getRoles('');
        },
        {
            cacheTime: 0,
            onError: (err) => {
                console.error(err);
            },
        }
    );
    const InvoiceSchema = Yup.object().shape({
        role_pricings: Yup.array().of(
            Yup.object().shape({
              roleId: Yup.string().required('Role is required')
              .test('unique', 'Duplicate option found for role', function (value) {
                const currentFieldPath = this.path;
                const role_pricings:any = formik.values.role_pricings;
                return (
                    role_pricings?.filter((item:any) => item.roleId === value).length === 1 ||
                    this.createError({ path: `${currentFieldPath}` })
                );
              }),
              description: Yup.string().required('Description is required'),
              monthly_price: Yup.number()
                .typeError('Monthly price must be a number')
                .required('Monthly price is required')
                .positive('Monthly price must be a positive number')
                .test(
                    "is-decimal",
                    "Please enter a valid number with two decimal places",
                    (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
                  )
                .max(9999.99, "Maximum monthly fee should not be more than 9999.99"),
            })
          ),
        subscription_types: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Subscription type is required'),
              months: Yup.number()
                .typeError('Months must be a number')
                .required('Months is required')
                .positive('Months must be a positive number')
                .max(9999, "Months should not be more than 9999"),
              discount_percentage: Yup.number()
              .typeError('Discount percentage must be a number')
              .required('Discount percentage is required')
              .max(
                Yup.ref('$price'), // Reference the price field for comparison
                'Discount must not be greater than the price'
              ).test(
                "is-decimal",
                "Please enter a valid number with two decimal places",
                (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
              )
              .max(99, 'Discount must not be greater than 99')
              //.positive('Discount percentage must be a positive number'),
            })
          ),
    });

    // 
    let initialSetting:any = {
        'role_pricings':[{
            roleId: '',
            description: '',
            monthly_price: 0,
          }] as initialSettingType['role_pricings'],
        'subscription_types':[{
            name: '',
            price: 0,
            discount_percentage: 0,
        }] as unknown as initialSettingType['subscription_types']
    }
    let invoiceDetailData = invoiceSettingDetail ? invoiceSettingDetail : initialSetting;
    invoiceDetailData.role_pricings = invoiceSettingDetail && invoiceSettingDetail?.role_pricings?.length >0 ? invoiceSettingDetail?.role_pricings : initialSetting?.role_pricings;
     invoiceDetailData.subscription_types = invoiceSettingDetail && invoiceSettingDetail?.subscription_types?.length >0 ? invoiceSettingDetail?.subscription_types : initialSetting?.subscription_types;

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetchinvoice();
            formik.resetForm();
        }
    };
    
    const [loading, setLoading] = useState(false);
    const formik = useFormik<initialSettingType>({
        enableReinitialize: true,
        initialValues: invoiceDetailData,
        validationSchema: InvoiceSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoading(true);
            values.deleted_items = deletedItems;
            try {
                 await createInvoiceSetting(values);
            } catch (ex) {
                setLoading(false);
            } finally {
                //navigate("/superadmin/invoice-settings");
                setSubmitting(true);
                setLoading(false);
                cancel(true);
                alert({
                    message: 'Changes saved successfully!',
                    callback: function (result) {
                        //window.location.reload();
                    }
                });
            }
        },
    });

      const getError = (fieldName: keyof role_pricing, index: number) => {
        const fieldErrors = formik.errors.role_pricings as unknown as role_pricing[];
        return formik.touched.role_pricings && fieldErrors
        ? fieldErrors[index]?.[fieldName]
        : undefined;
      };
      const getsubError = (fieldName: keyof subscription_type, index: number) => {
        return true;
        // const fieldErrors = formik.errors.subscription_types as unknown as subscription_type[];
        // return formik.touched.subscription_types && fieldErrors
        // ? fieldErrors[index]?.[fieldName]
        // : undefined;
      };

      

      const handleAddMoreDiscount = () => {
        formik.setTouched({});
        formik.setErrors({});

        const newItems: subscription_type[] = [...formik.values.subscription_types, {name: '',months: 0,discount_percentage: 0}];
        formik.setFieldValue('subscription_types', newItems);
      };

      const handleAddMore = () => {
        
        formik.setTouched({});
        formik.setErrors({});

        const newItems: role_pricing[] = [...formik.values.role_pricings, {roleId: '',description: '',monthly_price: 0}];
        formik.setFieldValue('role_pricings', newItems);
      };

      const handleDelete = (index: number) => {
        if(formik.values.role_pricings.filter((_, i) => i === index)[0]?._id){
            setdeletedItems([...deletedItems,formik.values.role_pricings.filter((_, i) => i === index)[0]?._id])
        }
        const updatedItems = formik.values.role_pricings.filter((_, i) => i !== index);
        formik.setFieldValue('role_pricings', updatedItems);
      };

      const handleDiscountDelete = (index: number) => {
        if(formik.values.subscription_types.filter((_, i) => i === index)[0]?._id){
            setdeletedItems([...deletedItems,formik.values.subscription_types.filter((_, i) => i === index)[0]?._id])
        }
        const updatedItems = formik.values.subscription_types.filter((_, i) => i !== index);
        formik.setFieldValue('subscription_types', updatedItems);
      };

      useEffect(() => {
    }, [invoiceSettingDetail,isFetchingInvoice]);

    return (

        <>
            <div className='card h-100 py-6 px-7 bg-transparent notifyHeight'>
                <div id='kt_profile_details' className="h-100 bg-white rounded">
                        <form
                        onSubmit={formik.handleSubmit}
                        noValidate className='form d-flex flex-column h-100 overflow-auto'>
                        <div
                            className='card-header'
                        >
                            <div className='card-title m-0 w-100 justify-content-between'>
                                <h3 className='text-dark fw-bold fs-18 m-0'>Invoice Settings</h3>
                            </div>
                        </div>
                        <div className='card-body border-top py-5 overflow-auto mh-100'>
                            <div className="d-flex justify-content-between">                            
                                <div className="card border w-49">
                                <div className="card-header px-10 py-2 align-items-center justify-content-between">
                                    <h1 className="text-dark fw-bold fs-18 m-0">Monthly Price</h1>
                                    <button type="button" id="handleAddMore" className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16"
                                        onClick={() => handleAddMore()}>
                                        Add
                                    </button>                                  
                                </div>
                                <div className="card-body px-10 pt-5 pt-6" style={{caretColor:'transparent'}}>
                                    <table className="table align-middle dataTable gy-5 w-100">
                                        <thead>
                                            <tr>
                                                <th className="pb-0 ps-0">
                                                    <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                        <span className="lblSpan">Role</span>
                                                    </label>                                                    
                                                </th>
                                                <th className="pb-0">
                                                    <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                        <span className="lblSpan">Description</span>
                                                    </label>                                                                                                        
                                                </th>
                                                <th className="pb-0">
                                                    <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                        <span className="lblSpan">Monthly Price €</span>
                                                    </label>                                                    
                                                </th>
                                                <th className="pb-0">&nbsp;</th>
                                            </tr>    
                                        </thead>                                        
                                        <tbody>
                                            {formik.values.role_pricings.map((element: any, i: number) => (
                                                <tr className="" key={i}>
                                                    <td width="33%" className="ps-0" valign="top">
                                                        <div>
                                                        <select
                                                            name={`role_pricings[${i}].roleId`}
                                                            value={element.roleId}
                                                            className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            onChange={formik.handleChange}
                                                            //onBlur={formik.handleBlur}
                                                            onClick={formik.handleChange}
                                                        >
                                                            <option value="">Select an option</option>
                                                            {!isFetchingRole && roleList?.data?.map((option:any) => {
                                                                if(option?.isActive == true){
                                                                    return <><option key={option} value={option?._id}>
                                                                        {option?.name}
                                                                    </option></>
                                                                }
                                                            })}
                                                        </select>
                                                            {getError('roleId', i) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getError('roleId', i)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="33%" valign="top">
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name={`role_pricings[${i}].description`}
                                                                value={element.description}
                                                                onChange={formik.handleChange}
                                                                style={{caretColor:'#000000'}}
                                                                //onBlur={formik.handleBlur}
                                                                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            />
                                                            {getError('description', i) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getError('description', i)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="33%" valign="top">
                                                        <div>
                                                            <input
                                                                type="number"
                                                                name={`role_pricings[${i}].monthly_price`}
                                                                value={element.monthly_price}
                                                                onChange={formik.handleChange}
                                                                //onBlur={formik.handleBlur}
                                                                style={{caretColor:'#000000'}}
                                                                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            />
                                                            {getError('monthly_price', i) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getError('monthly_price', i)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="1%" valign="top" align="right">
                                                        {formik.values.role_pricings?.length >1 &&
                                                            <button type='button' className='border-0 m-0 p-0 bg-transparent mt-3'
                                                                onClick={() => handleDelete(i)}>
                                                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1 m-auto text-danger' />
                                                            </button>   
                                                        }                                                     
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                <div className="card border w-49">
                                    <div className="card-header px-10 py-2 align-items-center justify-content-between">
                                            <h1 className="text-dark fw-bold fs-18 m-0">Discount Percentages</h1>
                                            {/* <button type="button" id="handleAddMoreDiscount" className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16"
                                                onClick={() => handleAddMoreDiscount()}>
                                                Add
                                            </button>                                   */}
                                    </div>
                                    <div className="card-body px-10 pt-5 pt-6" style={{caretColor:'transparent'}}>
                                        <table className="table align-middle dataTable gy-5 w-100">
                                            <thead>
                                                <tr>
                                                    <th className="pb-0 ps-0">
                                                        <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                            <span className="lblSpan">Subscription type</span>
                                                        </label>                                                    
                                                    </th>
                                                    <th className="pb-0">
                                                        <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                            <span className="lblSpan">Months</span>
                                                        </label>                                                                                                        
                                                    </th>
                                                    <th className="pb-0">
                                                        <label className="col-form-label fw-bold fs-16 text-dark p-0 required">
                                                            <span className="lblSpan">Discount (%)</span>
                                                        </label>                                                    
                                                    </th>
                                                    <th className="pb-0">&nbsp;</th>
                                                </tr>    
                                            </thead>
                                            <tbody>
                                            {formik.values.subscription_types.map((item: any, index: number) => (
                                                <tr className="" key={index}>
                                                    <td width="33%" className="ps-0" valign="top">
                                                        <div>
                                                            <input
                                                                type="text"
                                                                disabled={true}
                                                                name={`subscription_types[${index}].name`}
                                                                value={item.name}
                                                                onChange={formik.handleChange}
                                                                //onBlur={formik.handleBlur}
                                                                style={{caretColor:'#000'}}
                                                                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            />
                                                            {getsubError('name', index) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getsubError('name', index)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="33%" valign="top">
                                                        <div>
                                                            <input
                                                                type="number"
                                                                disabled={true}
                                                                name={`subscription_types[${index}].months`}
                                                                value={item.months}
                                                                onChange={formik.handleChange}
                                                                //onBlur={formik.handleBlur}
                                                                style={{caretColor:'#000'}}
                                                                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            />
                                                            {getsubError('months', index) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getsubError('months', index)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="33%" valign="top">
                                                        <div>
                                                            <input
                                                                type="number"
                                                                disabled={true}
                                                                name={`subscription_types[${index}].discount_percentage`}
                                                                value={item.discount_percentage}
                                                                onChange={formik.handleChange}
                                                                //onBlur={formik.handleBlur}
                                                                style={{caretColor:'#000'}}
                                                                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                                                            />
                                                            {getsubError('discount_percentage', index) && <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{getsubError('discount_percentage', index)}</div></div>}
                                                        </div>
                                                    </td>
                                                    <td width="1%" valign="top" align="right" >
                                                        {formik.values.subscription_types?.length >1 &&
                                                            <div></div>
                                                            // <button type='button' className='border-0 m-0 p-0 bg-transparent mt-3'
                                                            //         onClick={() => handleDiscountDelete(index)}
                                                            //         data-bs-toggle="modal" data-bs-target="#kt_modal_3">
                                                            //         <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1 m-auto text-danger' />
                                                            // </button> 
                                                        }                                                       
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>    

                            <div className="d-flex pt-6">
                                <button
                                    type="submit"
                                    className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16 me-3"
                                    disabled={loading}
                                >
                                    {!loading && "Save"}
                                    <Loading isLoading={loading} text={"Please wait..."}></Loading>
                                </button>
                                <button className="btn btn-dark h-35px py-0 px-5 rounded-4px fs-16" type="button" onClick={()=>cancel(true)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                        </form>
                </div>
            </div ></>
    );
}
export default InvoiceSetting;
