import { Formik, useFormik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
import * as Yup from "yup";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { Loading } from "../../../../components/loading/Loading";
import { EmailCredSetting, initialEmailCredSetting } from "../core/_models";
import {
  updateEmailCredSetting,
  createEmailCredSetting,
  getSmtpSetting,
} from "../core/_requests";
import { alert } from "@mobiscroll/react";
import { SendTestMail } from "./Testmail";

const emailSettingSchema = Yup.object().shape({
  sender_mail_address: Yup.string()
    .min(3, "Minimum 3 letters are required.")
    .max(50, "Maximum 50 letters are required.")
    .email("Please enter an email in proper format.")
    .required("E-mail send from is required."),
  smtp_server: Yup.string()
    .trim()
    // .min(3, 'Minimum 3 letters are required.')
    // .max(50, 'Maximum 50 letters are required.')
    .required("SMTP server is required."),
  smtp_port: Yup.string()
    .trim()
    // .min(3, 'Minimum 3 letters are required.')
    // .max(50, 'Maximum 50 letters are required.')
    .required("Portnumber is required."),
  smtp_user: Yup.string()
    .trim()
    // .min(3, 'Minimum 3 letters are required.')
    // .max(50, 'Maximum 50 letters are required.')
    .required("SMTP username is required."),
  smtp_password: Yup.string()
    .required("SMTP password is required."),
});

const EmailSetting = () => {
  const { refetch, data: emailSetting } = useQuery(
    `email-setting`,
    async () => {
      return await getSmtpSetting("");
    },
    {
      cacheTime: 0,
      onError: (err) => {
        //   console.error(err);
      },
    }
  );

  let settingForEdit = emailSetting
    ? {
        id: emailSetting._id,
        sender_mail_address: emailSetting.sender_mail_address,
        smtp_server: emailSetting.smtp_server,
        smtp_port: emailSetting.smtp_port,
        smtp_user: emailSetting.smtp_user,
        smtp_password: emailSetting.smtp_password,
        smtp_type: emailSetting.smtp_type,
      }
    : initialEmailCredSetting;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
  };
  const formik = useFormik<EmailCredSetting>({
    enableReinitialize: true,
    initialValues: settingForEdit,
    validationSchema: emailSettingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        if (isNotEmpty(values._id)) {
          await updateEmailCredSetting(values);
        } else {
          await createEmailCredSetting(values);
        }
      } catch (ex) {
        setLoading(false);
      } finally {
        navigate("/superadmin/general-settings/email-setting");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        alert({
          message: "Changes saved successfully!",
          callback: function () {
            window.location.reload();
          },
        });
      }
    },
  });

  return (
    <>
      <div className="col">
        <div className="card card-custom card-stretch h-100">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form d-flex flex-column"
          >
            {/* begin::Header */}
            <div className="card-header">
              <div className="card-title align-items-start flex-column">
                <h1 className="fw-bolder m-0 fs-4 fs-lg-2">
                  Mail server settings
                </h1>
              </div>
              <div className="card-toolbar">
                <button
                  type="submit"
                  className="btn btn-primary h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14"
                  disabled={loading}
                >
                  {!loading && "Save changes"}
                  <Loading
                    isLoading={loading}
                    text={"Please wait..."}
                  ></Loading>
                </button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
              <div className="card-body">
                {/* E-mail send from */}
                <div className="row mb-6">
                  <label className="col-form-label required fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">E-mail send from</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <div className="input-group">
                      <span
                        className="input-group-text border-0 rounded-start bg-secondary-subtle"
                        id="inputGroupPrepend"
                      >
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        {...formik.getFieldProps("sender_mail_address")}
                      />
                    </div>
                    {formik.touched.sender_mail_address &&
                      formik.errors.sender_mail_address && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.sender_mail_address}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {/* SMTP server */}
                <div className="row mb-6">
                  <label className="col-form-label required fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">SMTP server</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <input
                      type="text"
                      placeholder="SMTP server"
                      className="form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("smtp_server")}
                    />
                    {formik.touched.smtp_server && formik.errors.smtp_server && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block fs-8">
                          {formik.errors.smtp_server}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* SMTP Username*/}
                <div className="row mb-6">
                  <label className="col-form-label required fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">SMTP username</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <input
                      type="text"
                      placeholder="SMTP username"
                      className="form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("smtp_user")}
                    />
                    {formik.touched.smtp_user && formik.errors.smtp_user && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block fs-8">
                          {formik.errors.smtp_user}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* SMTP password*/}
                <div className="row mb-6">
                  <label className="col-form-label required fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">SMTP password</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <input
                      placeholder="SMTP password"
                      className="form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                      type="password"
                      {...formik.getFieldProps("smtp_password")}
                    />
                    {formik.touched.smtp_password &&
                      formik.errors.smtp_password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.smtp_password}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {/* Portnumber*/}
                <div className="row mb-6">
                  <label className="col-form-label required fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">Portnumber</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <input
                      placeholder="SMTP port"
                      className="form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                      type="text"
                      {...formik.getFieldProps("smtp_port")}
                    />
                    {formik.touched.smtp_port && formik.errors.smtp_port && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block fs-8">
                          {formik.errors.smtp_port}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* SMTP Authentication  */}
                <div className="row mb-6">
                  <label className="col-form-label fw-bold fs-6 mw-250px fs-ls-14">
                    <span className="lblSpan">Authentication</span>
                  </label>
                  <div className="fv-row col-md-8">
                    <select
                      className="form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("smtp_type")}
                    >
                      <option value="TLS">TLS</option>
                      <option value="SSL">SSL</option>
                      <option value="STARTTLS">STARTTLS</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Form */}
          </form>
          <SendTestMail />
        </div>
      </div>
    </>
  );
};

export { EmailSetting };
