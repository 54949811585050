import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loading } from "../../../components/loading/Loading";

type Props = {
  countData?: {
    isShowCount: boolean,
    count: string | number | undefined
  }
  data: Array<{
    title?: string
    count: string | number | undefined
  }>,
  isLoading: boolean
  title: string
  icon: string
  cardClassName?: string
  redirect?:any
};

const Cards: FC<Props> = ({ data, isLoading, cardClassName, title, icon, countData,redirect }) => (  <>
    {/* begin::Row */}
    <div>
      
    </div>
    <div className={`${cardClassName ? cardClassName : 'col-sm-12'}  mb-6`}>
    <Link id="RouterNavLink" className={`card hoverable card-xl-stretch h-100`} to={redirect}>
      <div className="card-header flex-nowrap border-0 ps-6 pe-4 py-4 min-h-1px mt-5">	
          {title &&  <div className="card-title m-0 fw-bold fs-18">
            {title}
          </div> }

        </div>
        <div className="card-body p-4 ps-0">
          <div className="row ms-0">
            {data.map(({ count }, i) => {
              return (
                <div key={i} className={`col-${12 / data.length} ps-6 ${i === 0 ? '' : 'border-start border-gray-300'}`}>
                  <div className={`fw-bold fs-6 text-dark mb-3 fs-ls-16`}>
                    {/* {title} */}
                  </div>
                  {
                    !isLoading && <div className={`fw-bold fs-2qx lh-1 fs-ls-40 text-end`}>
                          <OverlayTrigger 
                          key="copy-to-clipboard" 
                          placement="bottom"          
                          overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">{title}</span></Tooltip>}
                          ><span>{count ? count : 0}</span></OverlayTrigger>
                    </div>
                  }
                </div>
              );
            })}
          </div>
          <Loading isLoading={isLoading}></Loading>
        </div>
      </Link>
    </div>
    {/* end::Row */}
  </>
);
export { Cards };
