import React from 'react'
import PlanningAISettings from './PlanningAISettings';
import ProjectAISettings from './ProjectAISettings';
import { useQuery } from 'react-query';
import { createAISettings, getAISettings } from '../../../../_metronic/requests/AISettings';
import { useFormik } from 'formik';
import { IaiSetting, initalSettingData } from './_model';
import { alert } from "@mobiscroll/react";

const AISettings = () => {

    const {
        isFetching: isfetchAISettingsData,
        refetch: refetchAISettingsData,
        data: aiSettingsData,
      } = useQuery(
        `get-planning-ai-data`,
        async () => {
          return await getAISettings();
        },
        {
          cacheTime: 0,
          onError: (err) => {
            console.error(err);
          },
        }
      );

    const getSettingData = aiSettingsData?.data.length !== 0 ? aiSettingsData?.data : initalSettingData
      
    const formik = useFormik<IaiSetting>({
        enableReinitialize: true,
        initialValues: getSettingData,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            try{
                createAISettings(values)
            } catch(error: any) {
                console.log(error?.message);
            } finally {
                setSubmitting(true)
                alert({
                    message: "Changes saved successfully!",
                  });
            }
        },
    });
    
    return (
        <div id="kt_general_setting_details" className="px-4 py-6 notifyHeight overflow-auto">
            <form onSubmit={formik.handleSubmit} noValidate className="form">
                <PlanningAISettings formik={formik}/>
                <ProjectAISettings formik={formik}/>
                <div className="col-12 text-start">
                <button
                    type="submit"
                    className="btn btn-primary h-35px py-0 px-5 rounded-4px fs-16 me-10"
                >
                    Save changes
                </button>
                </div>
            </form>
        </div>
    )
}

export default AISettings