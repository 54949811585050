/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, FC, useEffect } from "react";
import * as Yup from "yup";
import { Loading } from "../../../../components/loading/Loading";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, KTSVG } from "../../../../../_metronic/helpers";
import { InvoiceSetting, initialInvoiceSetting} from "../core/_models"
import { useFormik } from "formik";
import { alert } from "@mobiscroll/react";
import { createInvoiceSetting, getGeneralSetting, updateInvoiceSetting } from "../core/_requests";

const generalSettingSchema = Yup.object().shape({

    approval_email: Yup.string()
    .email("Please enter a valid email address")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email address is required"),
});

const InvoiceSettingView: FC = () => {

  const navigate = useNavigate();

  const {
    refetch,
    data: invoiceSetting } = useQuery(
      `invoice-setting`,
      async () => {
        return await getGeneralSetting('');
      },
      {
        cacheTime: 0,
        onError: (err) => {
          console.error(err);
        },
      }
    );

  let invoiceSettingData = invoiceSetting ? 
  {            
      id: invoiceSetting._id,
      approval_email: invoiceSetting.approval_email,
  }
: initialInvoiceSetting;

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
  };

  const [loading, setLoading] = useState(false);

  const formik = useFormik<InvoiceSetting>({
    enableReinitialize: true,
    initialValues: invoiceSettingData,
    validationSchema: generalSettingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        if (isNotEmpty(values._id)) {
          await updateInvoiceSetting(values);
        } else {
          await createInvoiceSetting(values);
        }
      } catch (ex) {
        setLoading(false);
      } finally {
        navigate("/superadmin/general-settings/invoice");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        alert({
          message: 'Changes saved successfully!',
          callback: function (result: any) {
              window.location.reload();
          }
        });
      }
    },
  });

  return (

    <>
      <div className="col">
      <div className='card card-custom card-stretch h-100'>
        <div id='kt_general_setting_details' className="h-100 bg-white rounded">
          <form
            onSubmit={formik.handleSubmit}
            noValidate className='form d-flex flex-column h-100 overflow-auto'>
            <div
              className='card-header border-0'
            >
              <div className='card-title m-0 w-100 justify-content-between'>
                <h1 className='fw-bolder m-0 fs-4 fs-lg-2'>
                    Invoice settings</h1>
                <button
                  type="submit"
                  className="btn btn-primary h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14"
                  disabled={loading}
                >
                  {!loading && "Save changes"}
                  <Loading isLoading={loading} text={"Please wait..."}></Loading>
                </button>
              </div>
            </div>
            <div className='card-body border-top py-5 overflow-auto mh-100'>
              <div className="row mb-6">
              <label className="'col-form-label required fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14">Approval email needs to be sent to</label>
              <div className='fv-row col-md-8'>
              <input
                type="email"
                autoComplete="off"
                {...formik.getFieldProps("approval_email")}
                className = "form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
              />
              {formik.touched.approval_email && formik.errors.approval_email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.approval_email}</span>
                  </div>
                </div>
              )}
              </div>
            </div>

            </div>
          </form>
        </div>
      </div >
      </div></>
  );
}
export {InvoiceSettingView}
