/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMsal } from "@azure/msal-react";
import { useState , useEffect, useContext } from "react";
import { DataLoadedContext } from "../../../context/DataLoadedContext";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import '../../../../_metronic/assets/sass/login.scss';
import { Loading } from "../../../components/loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { encryptData, generateEncryptionKey } from "./encryptionUtils";
import axios from "axios";
import {mftLogin} from '../core/_requests';
import React from "react";

const APP_URL:any = process.env.REACT_APP_HOST_URL;

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required"),
  password: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
});

const initialValues = {
  username: "",
  password: "",
};





export function Login() {
  
  const [loading, setLoading] = useState(false);
  const [isMFA, setIsMFA] = useState(null);
  const [errorMessage,setErrorMessage] = useState();
  const [isMFTLoggingProgress, setIsMFTLoggingProgress] = useState(false);
  const [allUsers,setAllUsers] = useState<any[]>([]);
  const [ssoLoginClicked,setSSOLoginClicked] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  let isNavigating = localStorage.getItem('navigatingTo');
  const navigate = useNavigate();
  const isDataLoaded = useContext(DataLoadedContext);
  
  let currentRunningStatus = sessionStorage.getItem('currentStatusChange');
  const getSubdomain = ():string  => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const hostname = urlObject.hostname;
    const parts = hostname.split('.');
    if(parts[0]==='www' || parts[0] ==='' || parts[0]==='localhost'){
    localStorage.removeItem('kt-auth-react-v');
    localStorage.setItem('subdomainExists','false');
    }  
    return parts[0];
  };


 
const { instance, accounts, inProgress } = useMsal();
 
useEffect(()=>{
      
  const setMFA = async()=>{
      let subdomain = getSubdomain();
      if(subdomain === 'localhost' || subdomain ==='www' || subdomain===''){
        localStorage.clear();
        sessionStorage.clear();
        setIsMFA(false);
      }else{
       const result = await axios.get(`${process.env.REACT_APP_API_URL}outlook/organization/${subdomain}`);

        if(result.status === 201){
          setIsMFA(false);
        }

        if(result?.data?.data?.outlook_sync_is_active_mfo){
          setIsMFA(true);
        }else{
          setIsMFA(false);
        }

      }
     
    }

    setMFA();
},[])

useEffect(()=>{
  
  const checkSessionAndHandleLogin = async () => {
    let currentStatusChange = sessionStorage.getItem('currentStatusChange');
 
    let previousUrl = sessionStorage.getItem('previousUrl') ;
    if(accounts.length > 0){
      
      try {
         if(currentStatusChange==='loggingIn' && accounts.length > 0 && isMFA){
         
            let subdomain = getSubdomain();
            let email = accounts[0].username;
            let homeAccountId = accounts[0].homeAccountId;
            localStorage.setItem(`homeAccountId`,accounts[0].homeAccountId);
            localStorage.setItem(`tenantId`,accounts[0].tenantId);
            
            try {
              const data = await mftLogin(email, subdomain);
             
             
              if (data?.data?.allUsers) {
                  
                if(data?.data?.allUsers.isSSOLoggedIn!==true){
                 
                  // first update the status of the user here
                  localStorage.setItem('flowCompleted','true');
                  createAccountSession(data.data.allUsers); 
                 
                }else{
                  
                  
                  if(previousUrl === 'null' || previousUrl!=='normalToSSOLogin'){
                    let homeAccountId = localStorage.getItem('homeAccountId');
                    const currentAccount = instance.getAccountByHomeId(homeAccountId);
                    await instance.logoutPopup({ account: currentAccount});
                    sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
                  }
                }
              }else if(data.data.allUsers===undefined){
                localStorage.setItem('flowCompleted','true');
              
                const currentAccount = instance.getAccountByHomeId(homeAccountId);
                let currentStatusChange = localStorage.getItem('currentStatusChange');
                
                if(currentStatusChange!=='loggedOutbyNormal'){
                  await instance.logoutPopup({ account: currentAccount});
                  localStorage.clear();
                  localStorage.setItem('flowCompleted','true');
                  setErrorMessage('No user found or user is not active');
                } 
              }
              // Update the state
              setAllUsers(data?.data?.allUsers);
            } catch (error) {
              setErrorMessage("This account is not active.");
              let homeAccountId = localStorage.getItem('homeAccountId');
              const currentAccount = instance.getAccountByHomeId(homeAccountId);
              setIsMFA(true);
              sessionStorage.setItem('currentStatusChange','loggedOutByMicrosoft');
              currentRunningStatus = sessionStorage.getItem('currentStatusChange');
              await instance.logoutPopup({ account: currentAccount});
            
            
            }
          }
          
        } catch (error) {
          console.error("Silent token acquisition failed", error);
          
        }
      }
     
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const params = new URLSearchParams(url.search);
      const urlParam = params.get('url');
      if(currentStatusChange==='loggedOutByMicrosoft' && urlParam==='normalToSSOLogin'){
        currentStatusChange=null;
      }
      
    if(accounts.length === 0 && inProgress==='none' && isMFA && currentStatusChange !== 'loggedOutByMicrosoft'){
     
      if(currentStatusChange == null ){
          handleMicrosoftLogin(); 
      }
    }
    
  }

  checkSessionAndHandleLogin();


},[isDataLoaded,isMFA,accounts])






const handleMicrosoftButtonClick = () => {
  localStorage.setItem('microsoftButtonClicked',true);
  
  handleMicrosoftLogin();
}

  const handleMicrosoftLogin =  () => {
    //get subdomain from url 
    const valueToEncode = getSubdomain();
    setSSOLoginClicked(true);
    
    localStorage.setItem('navigatingTo','microsoft');
    localStorage.setItem(`mftLogging`, 'true');
    localStorage.setItem("ssoLoginTracker", "true");
    localStorage.setItem("currentStatusChange","loggingIn");
    localStorage.setItem("loginType","MFT");
    localStorage.setItem('subdomainExists','true');
    localStorage.setItem('flowCompleted','started');
    sessionStorage.setItem('currentStatusChange','loggingIn');
    sessionStorage.setItem('navigatingTo','microsoft');
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const urlParam = params.get('url');
  
    if(urlParam !=null || urlParam != undefined || urlParam !==''){
      sessionStorage.setItem('previousUrl',urlParam);
    }
      setTimeout(() => {
        instance.loginRedirect({
            scopes: ["user.read", "openid", "profile"],
            state: valueToEncode
        });
      }, 1500);
    
    
    
  }
  
  const resetErrorMessage = ()=>{
    setIsMFTLoggingProgress(false);
    setErrorMessage('');
  }



  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

      let subdomainURL = getSubdomain();
      if(subdomainURL==='www' || subdomainURL ==='' || subdomainURL==='localhost'){
        localStorage.clear();
        sessionStorage.clear();
      } else{
        localStorage.setItem("currentStatusChange","loggingIn");
        localStorage.removeItem("justLoggedOut");
        
      }
        setLoading(true);
      try {
        const { data: auth } = await login(values.username, values.password, "admin",subdomainURL);
        
        if(auth?.subDomainToRedirectTo!=='' && auth?.subDomainToRedirectTo!=null ){
          setStatus('');
          let url = APP_URL;
          let parts = url.split("www.")
          let subadmin = `https://${auth.subDomainToRedirectTo}.${parts[1]}/auth?url=normalToSSOLogin`;
          const redirectURL =  subadmin;
           window.location.replace(redirectURL);
           window.location.href = redirectURL
        }
        
        let redirectTo = auth.result.redirectTo;
        saveAuth(auth);
        const {data: user}= await getUserByToken(auth.token);
        setCurrentUser(user)
        localStorage.setItem("starttime", user?.data?.setting?.start_time);
        localStorage.setItem("endtime", user?.data?.setting?.end_time);
        localStorage.setItem("extendedMorningStart", user?.data?.setting?.extended_morning_start);
        localStorage.setItem("extendedMorningEnd", user?.data?.setting?.extended_morning_end);
        localStorage.setItem("extendedEveningStart", user?.data?.setting?.extended_evening_start);
        localStorage.setItem("extendedEveningEnd", user?.data?.setting?.extended_evening_end);
        localStorage.setItem("time_format", user?.data?.setting?.time_format);
        localStorage.setItem("timezone", user?.data?.setting?.timezone);
        localStorage.setItem('timeline_view',user?.data?.setting?.planning_screen_view);
        localStorage.setItem("show_companyinfo", '1');
        localStorage.setItem('loginType','normallogin');
        localStorage.setItem('navigatingTo','normallogin');
        sessionStorage.setItem('loginType','normallogin');
        
        const key = generateEncryptionKey();
        const encodedKey = encodeURIComponent(key);
        const encryptedAuth = encryptData(auth, key);
        const encodedAuth = encodeURIComponent(encryptedAuth);

        const parsedUrl = new URL(window.location.href);
        let host = parsedUrl.host;
        host = host.replace("www.", "");
        host = host.replace(/^https?:\/\//, "");

        const subdomain = auth?.result?.subdomain;
        if(subdomain === undefined || subdomain === ''){
          navigate('/error/404');
        }
        else{
            let subadmin = '';
            if (subdomain && !host.includes(subdomain)) {
              subadmin = 'http://' + subdomain + '.' + host;
            } else {
              subadmin = 'http://' + host;
            }
            const usersData = JSON.stringify({
              start_time: user?.data?.setting?.start_time,
              end_time: user?.data?.setting?.end_time,
              time_format: user?.data?.setting?.time_format,
              timezone: user?.data?.setting?.timezone
            })
            const encodedUser = encodeURIComponent(usersData); 
            const redirectURL = subadmin + `/${redirectTo}?mydata=${encodedAuth}&user=${encodedUser}&key=${encodedKey}`;
            
            window.location.replace(redirectURL);
            window.location.href = redirectURL;
          }
        
      } catch (error:any) {
        console.log(error);
        let ErrorMsg = error?.response?.data?.message
        saveAuth(undefined);
        setStatus(ErrorMsg);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });


return (
  <>
      <div className="w-100 w-sm-400px">
        {/* existing form code */}
        {errorMessage ? (
                <div className="mb-8 alert alert-danger p-2 px-3">
                  <div className="alert-text font-weight-bold fs-14">{errorMessage}</div>
                </div>
              ) : (
                <div></div>
        )}
        
        {isMFA !== true && isMFA !== null ? (
           <form
           className="form w-100"
           onSubmit={formik.handleSubmit}
           noValidate
           id="kt_login_signin_form"
         >
           <div className="mb-8">
             <h1 className="text-dark fw-bold mb-0 h2 fs-1 fs-ls-30">Welcome to Proxuma, please login</h1>
           </div>
           {errorMessage ? (
             <div className="mb-8 alert alert-danger p-2 px-3">
               <div className="alert-text font-weight-bold fs-14">{errorMessage}</div>
             </div>
           ) : (
             <div></div>
           )}
           {/* begin :: Message */}
           {formik.status ? (
             <div className="mb-8 alert alert-danger p-2 px-3">
               <div className="alert-text font-weight-bold fs-14">{formik.status}</div>
             </div>
           ) : (
             <div></div>
           )}
           {/* End :: Message */}
 
           {/* begin::Form group */}
           <div className="fv-row mb-8">
           <label className="form-label fs-16 text-dark">User name</label>
             <input
               {...formik.getFieldProps("username")}
               className={clsx(
                 "form-control h-45px px-6 fs-6 rounded-1",
                 { "is-invalid": formik.touched.username && formik.errors.username },
                 {
                   "is-valid": formik.touched.username && !formik.errors.username,
                 }
               )}
               type="text"
               name="username"
               autoComplete="off"
               onChange={(e) => {
                 formik.getFieldProps("username").onChange(e); // Formik's default onChange
                 resetErrorMessage(); // Your custom function
               }}  
             />
             {formik.touched.username && formik.errors.username && (
               <div className="fv-plugins-message-container">
                 <div className="fv-help-block">
                   <span role="alert" className="fs-14">{formik.errors.username}</span>
                 </div>
               </div>
             )}
           </div>
           {/* End::Form group */}
 
           {/* begin::Form group */}
           <div className="fv-row mb-3">
             <label className="form-label fs-16 text-dark">Password</label>
             <input
               type="password"
               autoComplete="off"
               {...formik.getFieldProps("password")}
               className={clsx(
                 "form-control h-45px px-6 fs-6 rounded-1",
                 {
                   "is-invalid": formik.touched.password && formik.errors.password,
                 },
                 {
                   "is-valid": formik.touched.password && !formik.errors.password,
                 }
               )}
             />
             {formik.touched.password && formik.errors.password && (
               <div className="fv-plugins-message-container">
                 <div className="fv-help-block">
                   <span role="alert" className="fs-14">{formik.errors.password}</span>
                 </div>
               </div>
             )}
           </div>
           {/* end::Form group */}
 
           {/* begin::Link */}
           <Link to="/auth/forgot-password" className="d-flex justify-content-end w-100 mb-6 text-end text-primary text-decoration-underline fs-16 fw-bold">Forgot password?</Link>
           {/* end::Link */}
 
           <div className="">
             {/* begin::Action */}
             <OverlayTrigger
               key="copy-to-clipboard"
               placement="bottom"
               overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Click here to login</span></Tooltip>}
             >
             <button
               type="submit"
               id="kt_sign_in_submit"
               className="btn w-100 btn-primary h-45px fs-6 px-10 fs-ls-20 h-ls-60"   /* text-toggle-hover */
               disabled={formik.isSubmitting}
             >
               {!loading && <span className="indicator-label"><small className="fs-100">Log In</small><small className="fs-100 hoverSmall d-none">Click here to login</small></span>}
               <Loading
                 isLoading={loading}
                 text="Please wait..."
                 isHideSpinner={false}
               />
             </button>
             </OverlayTrigger>
             {/* end::Action */}
           </div>
         
           </form>
   
      ) : isMFA === true  && (currentRunningStatus!=='loggingIn' )? (
    <div className="mt-10">
      <OverlayTrigger
        key="copy-to-clipboard"
        placement="bottom"
        overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Click here to login with Microsoft</span></Tooltip>}
      >
        <button onClick={handleMicrosoftButtonClick} className="btn w-100 btn-primary h-45px fs-6 px-10 fs-ls-20 h-ls-60">Login with Microsoft</button>
      </OverlayTrigger>
    </div>
  ) : (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )}
                
      </div>
     
  </>
);
}