/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavItem } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
// import { Security } from "../Security";
// import { AdminMedia } from "../AdminMedia";
// import { EmailSetting } from "../EmailSetting";
// import { General } from "../General";


export function SettingSideBar() {
	return (
		<>
			{/* <div
				className="mw-300px mw-xxl-400px"
				id="kt_profile_aside"
			>
				<div className="card card-custom card-stretch h-100">

					<div className="card-body py-8">

						<div className="navi navi-bold navi-hover navi-active navi-link-rounded fs-5 fs-ls-16 fw-bold">
							<div className="navi-item mb-6">
								<NavLink
									to="/superadmin/general-settings/general"
									className="navi-link py-4 text-gray-800"
								>
									<span className="navi-icon mr-2">
										<span className="svg-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/User.svg"
												)}
											></SVG>{" "}
										</span>
										<i className="text-gray-700 fs-4 me-2 fas fa-shield-halved"></i>
									</span>
									<span className="navi-text font-size-lg">
										General
									</span>
								</NavLink>
							</div>
							<div className="navi-item mb-6">
								<NavLink
									to="/superadmin/general-settings/admin-media"
									className="navi-link py-4 text-gray-800"
								>
									<span className="navi-icon mr-2">
										<span className="svg-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/User.svg"
												)}
											></SVG>{" "}
										</span>
										<i className="text-gray-700 fs-4 me-2 fas fa-user"></i>
									</span>
									<span className="navi-text font-size-lg">
										Admin Media
									</span>
								</NavLink>
							</div>
							<div className="navi-item mb-6">
								<NavLink
									to="/superadmin/general-settings/security"
									className="navi-link py-4 text-gray-800"
								>
									<span className="navi-icon mr-2">
										<span className="svg-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Mail.svg"
												)}
											></SVG>{" "}
										</span>
										<i className="text-gray-700 fs-4 me-2 fas fa-envelope"></i>
									</span>
									<span className="navi-text font-size-lg">
										Security
									</span>
								</NavLink>
							</div>
							<div className="navi-item mb-6">
								<NavLink
									to="/superadmin/general-settings/email-setting"
									className="navi-link py-4 text-gray-800"
								>
									<span className="navi-icon mr-2">
										<span className="svg-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Mail.svg"
												)}
											></SVG>{" "}
										</span>
										<i className="text-gray-700 fs-4 me-2 fas fa-envelope"></i>
									</span>
									<span className="navi-text font-size-lg">
										SMTP-server
									</span>
								</NavLink>
							</div>
						</div>

					</div>

				</div>
			</div> */}

			<ul className="nav nav-pills  px-3" id="myTab" role="tablist">
				<li className="nav-item">
					<NavLink className="nav-link"  to="general">General</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link"  to="admin-media">Media</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="security">Security</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="email-setting">SMTP-gegevens</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="invoice">Invoice</NavLink>
				</li>
			</ul>

		</>
	);
}
