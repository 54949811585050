import { Formik, useFormik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
import * as Yup from "yup";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { Loading } from "../../../../components/loading/Loading";
import { TestMail, initialTestMail } from "../core/_models";
import { sendTestmail } from "../core/_requests";
import { alert } from "@mobiscroll/react";


const emailSettingSchema = Yup.object().shape({
    email: Yup.string()
        .min(3, 'Minimum 3 letters are required.')
        .max(50, 'Maximum 50 letters are required.')
        .email('Please enter an email in proper format.')
        .required('Sender email address field is required.'),


});

const SendTestMail = () => {

    let settingForEdit = initialTestMail;

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik<TestMail>({
        enableReinitialize: true,
        initialValues: settingForEdit,
        validationSchema: emailSettingSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoading(true);
            try {
                await sendTestmail(values);
                alert({
                    message: 'Test mail send successfully!',
                    callback: function (result: any) {
                        window.location.reload();
                    }
                });
            } catch (e:any) {
                const message = e.response?.data?.message ?? 'Something went wrong. Please try again.'
                alert({
                    message
                });
                setLoading(false);
            } finally {
                navigate("/superadmin/general-settings/email-setting");
                setSubmitting(true);
                setLoading(false);
                // cancel(true);
               /*  alert({
                    message: 'Test mail send successfully!',
                    callback: function (result: any) {
                        window.location.reload();
                    }
                }); */
            }
        },
    });

    return (
                    <>
                         <div className="col">
                            <div className="card card-custom card-stretch h-100">
                            <form
                                onSubmit={formik.handleSubmit}
                                noValidate className='form d-flex flex-column'>
                                {/* begin::Form */}
                                 <div className="form">
                                    <div className="card-body">

                                        {/* Test Email */}
                                          <div className="row mb-6">
                                            <label className="col-form-label fw-bold fs-6 mw-250px fs-ls-14">
                                                Test-email
                                            </label>
                                            <div className="row col-md-8 flex-wrap m-0 px-0 flex-xxl-nowrap">
                                                <div className="input-group m-0">
                                                    <span className="input-group-text border-0 rounded-start bg-secondary-subtle" id="inputGroupPrepend">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid "
                                                        {...formik.getFieldProps("email")}
                                                    />
                                                </div>

                                                <div className="row mt-5 mt-xxl-0">

                                                    <div className="fv-row">
                                                    <button
                                                    type="submit"
                                                    className="btn btn-primary rounded w-7rem"
                                                    // disabled={loading}
                                                >
                                                    {!loading && "Send"}
                                                    <Loading isLoading={loading} text={"Please wait..."}></Loading>
                                                </button>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formik.touched.email && formik.errors.email && (
                                            <div className="fv-plugins-message-container" style={{marginTop: '-13px', marginLeft: '251px'}}>
                                                <div className="fv-help-block fs-8">{formik.errors.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* end::Form */}
                                  </form>
                            </div>
                        </div>
                        </>
    );
}

export { SendTestMail }