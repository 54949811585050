/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { getSuperadminUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/AuthCognito";
import '../../../../_metronic/assets/sass/login.scss';
import { Loading } from "../../../components/loading/Loading";
import { useNavigate,Link } from "react-router-dom";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required"),
  password: Yup.string()
    // .min(3, "Minimum 3 symbols")
    // .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});


export function Login() {
  let initialValues = {
    username: "",
    password: ""
  };


  const [loading, setLoading] = useState(false);
  const [isRemember, setisRemember] = useState<any>((localStorage.getItem('isRemember') == "false" || localStorage.getItem('isRemember') == null) ? false : true);
  const [rememberuser, setrememberuser] = useState<any>(localStorage.getItem('username') != null ? localStorage.getItem('username') : "");
  const [rememberpassword, setrememberpassword] = useState<any>(localStorage.getItem('password') != null ? localStorage.getItem('password') : "");
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  if (localStorage.getItem('username') != null) {
    initialValues = {
      username: rememberuser,
      password: rememberpassword,
    };
  }
  const isRememberChange = (event: any) => {
    setisRemember(event.target.checked);
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setHasErrors(undefined);
      setLoading(true);
      localStorage.setItem("loginMode","superadmin");
      try {
        if (isRemember) {
          localStorage.setItem("username", values.username);
          localStorage.setItem("password", values.password);
          localStorage.setItem("isRemember", isRemember);
        } else {
          localStorage.setItem("username", "");
          localStorage.setItem("password", "");
          localStorage.setItem("isRemember", isRemember);
        }
        const { data: auth } = await login(values.username, values.password, "superadmin");
        saveAuth(auth);
        const { data: user } = await getSuperadminUserByToken(auth.token);
        setCurrentUser(user);
        navigate('/superadmin/dashboard')
      } catch (error:any) {
        let ErrorMsg = error?.response?.data?.message
        setHasErrors(true)
        saveAuth(undefined);
        setStatus(ErrorMsg ?? "Incorrect username and password");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div className="w-100 w-sm-350px">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="mb-8">
          <h1 className="text-dark fw-bold mb-0 h2 fs-1 fs-ls-30">Log In</h1>
        </div>

        {/* begin :: Message */}
        {hasErrors && formik.status ? (
          <div className="mb-8 alert alert-danger p-2 px-3">
            <div className="alert-text font-weight-bold fs-9">{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}
        {/* End :: Message */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-label fs-6 fs-ls-20">Username</label>
          <input
            {...formik.getFieldProps("username")}
            className={clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
              { "is-invalid": formik.touched.username && formik.errors.username },
              {
                "is-valid": formik.touched.username && !formik.errors.username,
              }
            )}
            onKeyDown={()=>setHasErrors(undefined)}
            type="text"
            name="username"
            autoComplete="off"
          />
          {formik.touched.username && formik.errors.username && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert" className="fs-14">{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        {/* End::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-4">
          <label className="form-label fs-6 fs-ls-20">Password</label>
          <input
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
            onKeyDown={()=>setHasErrors(undefined)}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert" className="fs-14">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Link */}
        <div className="d-flex align-items-center justify-content-between">
          {/* begin::Action */}
          <div className="form-check form-check-custom form-check-white ">
            <input type="checkbox" className="form-check-input" checked={isRemember} onChange={isRememberChange} />
            <label className="form-check- fw-bold ms-2 fs-ls-16">Remember Me</label>
          </div>
          <Link to="/superadmin/auth/forgot-password" className="fw-bold text-decoration-underline fs-ls-16">Forgot password?</Link>
          {/* end::Action */}
        </div>
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-dark h-45px fs-6 px-10 mt-6 fs-ls-20 h-ls-60"
          disabled={formik.isSubmitting}
        >
          {!loading && <span className="indicator-label">Log In</span>}
          <Loading
            isLoading={loading}
            text="Please wait..."
            isHideSpinner={false}
          />
        </button>
        {/* end::Link */}
      </form>
    </div>
  );
}
