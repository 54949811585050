import { useLocation, useNavigate } from "react-router-dom";

export function MenuInner() {
  const location  = useLocation()
  let path = location.pathname;
  return (
    <>
    {/* {(path == '/apps/planning/resources' || path == '/apps/task/resources') &&
      <>
        <MenuFilter />
      </>
    } */}
    </>
  );
}


