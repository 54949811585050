import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {IUpdatePassword, Organisation, OrganisationsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}superadmin/organisations`
const SUBSCRIPTION_URL = `${API_URL}superadmin/subscription/trial/extend`

const getOrganisations = (query: string): Promise<OrganisationsQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<OrganisationsQueryResponse>) => d.data)
}

const getOrganisationById = (id: ID): Promise<Organisation | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data)
}

const createOrganisation = async (organisation: Organisation): Promise<Organisation | undefined> => {
  let organisationData: any
  organisationData = await axios
    .post(USER_URL, organisation)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data)

  return organisationData
}

const updateOrganisation = (organisation: Organisation): Promise<Organisation | undefined> => {
  return axios
    .patch(`${USER_URL}/${organisation.id || organisation._id}`, organisation)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data)
}

const extendTrial = (organizationId: any): Promise<any | undefined> => {
  return axios
    .post(`${SUBSCRIPTION_URL}`, organizationId)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data)
}
// const resetPasswod = (id : any,organisation: IUpdatePassword): Promise<IUpdatePassword | undefined> => {
//   return axios
//     .patch(`${USER_URL}/reset-password/${id}`, { passwordData : organisation} )
//     .then((response: AxiosResponse<Response<IUpdatePassword>>) => response.data)
//     .then((response: Response<IUpdatePassword>) => response.data)
// }

const resetPasswod = (organisationId: ID): Promise<void> => {
  return axios.patch(`${USER_URL}/reset-password/${organisationId}`)
}

const setLicenseKey = (organisationId: ID): Promise<void> => {
  return axios.patch(`${USER_URL}/set-license-email/${organisationId}`)
}

const deleteOrganisation = (organisationId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${organisationId}`).then(() => {})
}

const deleteSelectedOrganisations = (organisationIds: Array<ID>): Promise<void> => {
  const requests = organisationIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const passwordVerify = (organisation: Organisation): Promise<Organisation | undefined> => {
  return axios
    .post(`${API_URL}users/verify-password`, organisation)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data);
};

const adminPasswordVerify = (organisation: Organisation): Promise<Organisation | undefined> => {
  return axios
    .post(`${API_URL}superadmin/organisations/verify-password`, organisation)
    .then((response: AxiosResponse<Response<Organisation>>) => response.data)
    .then((response: Response<Organisation>) => response.data);
};


export {
  getOrganisations,
  deleteOrganisation,
  deleteSelectedOrganisations,
  getOrganisationById,
  createOrganisation,
  updateOrganisation,
  resetPasswod,
  passwordVerify,
  extendTrial,
  setLicenseKey,
  adminPasswordVerify
}
