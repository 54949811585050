import { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap';
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { useQueryRequest } from '../../../../../components/core/QueryRequestProvider'
import { storeRefreshTime } from '../../../../../counterSlice';
import { useAppSelector, useAppDispatch } from "../../../../../hook";

const RefreshApi = () => {
  const dispatch = useAppDispatch()
  const count = useAppSelector((state) => state.counter)
  const { updateState } = useQueryRequest()
  const [refreshtime, setRefreshtime] = useState<any>(localStorage.getItem("refreshtime") ? localStorage.getItem("refreshtime") : count.refreshtime)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  function onChange(event: any) {
    setRefreshtime(event.target.value)
    dispatch(storeRefreshTime(event.target.value))
  }

  useEffect(() => {
    localStorage.setItem("refreshtime", refreshtime)
    dispatch(storeRefreshTime(refreshtime))
  }, [refreshtime]);

  return (
    <>

      {/* begin::Filter Button */}
      <div className="card-header w-100 min-h-auto m-0 col-md-3 justify-content-between align-items-center py-4 apiHeader">
        <h3 className="text-dark fw-bold fs-18 m-0">API logs</h3>
        <Row className='m-0 align-items-center'>
          <label className='col-form-label fw-bold fs-6 mw-300px mw-xxl-400px fs-ls-14 w-auto py-0'>Refresh Time:</label>
          <select className='form-select form-select-solid bg-white border border-graybor form-select-small h-32px py-0 px-3 fw-normal fs-16 text-dark w-auto min-w-250px' name='refreshtime' onChange={onChange}>
            <option value=''>Select refresh time</option>
            <option value={5} selected={refreshtime == 5 ? true : false}>5 Second</option>
            <option value={10} selected={refreshtime == 10 ? true : false}>10 Second</option>
            <option value={15} selected={refreshtime == 15 ? true : false}>15 Second</option>
            <option value={20} selected={refreshtime == 20 ? true : false}>20 Second</option>
            <option value={25} selected={refreshtime == 25 ? true : false}>25 Second</option>
            <option value={30} selected={refreshtime == 30 ? true : false}>30 Second</option>
            <option value={35} selected={refreshtime == 35 ? true : false}>35 Second</option>
            <option value={40} selected={refreshtime == 40 ? true : false}>40 Second</option>
            <option value={45} selected={refreshtime == 45 ? true : false}>45 Second</option>
            <option value={50} selected={refreshtime == 50 ? true : false}>50 Second</option>
            <option value={55} selected={refreshtime == 55 ? true : false}>55 Second</option>
            <option value={60} selected={refreshtime == 1 * 60 ? true : false}>1 Minute</option>
            <option value={2 * 60} selected={refreshtime == 2 * 60 ? true : false}>2 Minute</option>
            <option value={3 * 60} selected={refreshtime == 3 * 60 ? true : false}>3 Minute</option>
            <option value={4 * 60} selected={refreshtime == 4 * 60 ? true : false}>4 Minute</option>
            <option value={5 * 60} selected={refreshtime == 5 * 60 ? true : false}>5 Minute</option>
          </select>
        </Row>
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { RefreshApi }
