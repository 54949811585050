import axios, { AxiosResponse } from "axios";
import { Notification,NotificationQueryResponse } from "../../app/modules/apps/notification/_model";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const USER_URL_NODE = `${API_URL_NODE}notifications`;

const getNotification = (id: string | null): Promise<any> => {
    return axios
    .get(`${USER_URL_NODE}`)
    .then((d: AxiosResponse<any>) => d.data);
  };

  const getNotificationcount = (query: string | null): Promise<any> => {
    return axios
    .get(`${USER_URL_NODE}/unread/count`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => {
      if (e.response.status === 403) {
        window.location.reload();
      }
    });
  };
  const getInfo = (query: string | null): Promise<any> => {
    return axios
    .get(`${API_URL_NODE}users/info`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => {
      if (e.response.status === 403) {
        window.location.reload();
      }
    });
  };

export {
    getNotification,
    getNotificationcount,
    getInfo
};