/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { SuperadminRoutes } from "./SuperadminRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { RedirectMSFT } from '../modules/auth/components/RedirectMSFT';
import { AuthPage, LogoutCognito } from "../modules/auth";
import { SuperadminAuthPage } from "../modules/auth/SuperadminAuthPage";
import { App } from "../App";
import RegistrationPage from "../modules/apps/registration/RegistrationPage";
import ConfirmRegister from "../modules/apps/registration/ConfirmRegister";
import ApproveRegister from "../modules/apps/registration/ApproveRegister";
import Subscription from "../modules/apps/registration/Subscription";
import axios from "axios";
import { Loading } from "../components/loading/Loading";
import { useAuth } from "../modules/auth/core/AuthCognito";
import { AuthPageCognito } from "../modules/auth/AuthPageCognito";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import { SetupPassword } from "../modules/auth/components/SetupPassword";
import { ResetPassword } from "../modules/auth/components/ResetPassword";
import { permissionArray } from "../modules/superadmin/role-management/roles-list/role-edit-modal/permission";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const [isSubdomainValidated, setIsSubdomainValidated] = useState(false);
  let redirectUrl:any = "/apps/planning/resources";
  if(currentUser?.data?.user?.permissions && currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Planning" && item.value === false)){
    let permissionFirst = currentUser?.data?.user?.permissions?.filter((item: any) => item.value === true && (item?.subkey ? item?.subkey?.some(
      (subItem: any) =>
        subItem?.value === true
    ): true))
    if(permissionFirst){
      let getUrl:any = ""
      if(permissionFirst[0]?.subkey){
        let subArray = permissionArray?.filter((item: any) => item.name === permissionFirst[0]?.name)
        permissionFirst = permissionFirst[0]?.subkey?.filter((item: any) => item.value === true)
        getUrl = subArray[0]?.subkey?.filter((item: any) => item.name === permissionFirst[0]?.name)
      }else{
        getUrl = permissionArray?.filter((item: any) => item.name === permissionFirst[0]?.name)
      } 
      if(getUrl?.[0]){
        redirectUrl = getUrl[0]?.url
      }
    }
  }
  
  const isValidSubdomain = async (subdomain: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}validateSubdomain`, { subdomain });
      return response.data.response.isValid;
    } catch (error) {
      console.error("Error validating subdomain:", error);
      throw error;  // Re-throw the error to maintain consistency
    }
  };
  
  useEffect(() => {
    const fetchSubdomainValidation = async () => {
      let subdomain = window.location.hostname.split(".")[0];
      
      try {

        if(subdomain==='www' || subdomain ==='' || subdomain ==='localhost'){
         setIsSubdomainValidated(true);
        } else {
          const isSubDomainValid = await isValidSubdomain(subdomain);
          if (!isSubDomainValid && !window.location.pathname.includes("/error/404")) {
            localStorage.setItem('isRedirectOnMainDomain', "true");
            window.location.href = `${PUBLIC_URL}/error/404`;
          } else {
            setIsSubdomainValidated(true);
          }
        }
        
      } catch (error) {
        console.error("Error fetching subdomain validation:", error);
        setIsSubdomainValidated(true);
      }
    };
  
    if (!isSubdomainValidated) {
      fetchSubdomainValidation();
    }
  }, [isSubdomainValidated]);
  
if (!isSubdomainValidated) {
    return (
      <div className="splash-screen">
        <Loading isLoading={!isSubdomainValidated} isHideSpinner={true} />
      </div>
    );
  }
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path="error/*" element={<ErrorsPage />} />
        <Route element={<App />}>
          <Route path="logout" element={<LogoutCognito />} />
          <Route path="register" element={<RegistrationPage />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="auth/confirm_registration/*" element={<ConfirmRegister />} />
          <Route path="/auth/approve_registration/*" element={<ApproveRegister />} />

          {(currentUser && currentUser?.data.type === 'superadmin') ?
            <>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="*" element={<Navigate to="/auth" />} />
              <Route path="superadmin/*" element={<SuperadminRoutes />} />
              <Route index element={<Navigate to="/superadmin/dashboard" />} />
            </> :
            (currentUser) ? (
              <>
                  <Route path="superadmin/auth/*" element={<SuperadminAuthPage />} />
                  <Route path="superadmin/*" element={<Navigate to="/superadmin/auth" />} />
                  <Route path="/*" element={<PrivateRoutes />} />
                  <Route index element={<Navigate to={redirectUrl} />} />
              </>
            ) : (
              <>

              </>
            )}
            <Route path="superadmin/auth/*" element={<SuperadminAuthPage />} />
            <Route path="auth/forgot-password/*" element={<ForgotPassword />} />
            <Route path="auth/setup-password/*" element={<SetupPassword />} />
            <Route path="auth/reset_password/*" element={<ResetPassword />} />
            <Route path="auth/*" element={<AuthPageCognito />} />
            <Route path="*" element={<Navigate to="/auth" />} />
            
            <Route path="superadmin/*" element={<Navigate to="/superadmin/auth" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
