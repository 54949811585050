import {  Popup } from "@mobiscroll/react";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import Select from "react-select";
import { KTSVG } from "../../../../_metronic/helpers";
import { createPhaseApi } from "../../../../_metronic/requests/PlanningRequest";
import { responsivePopup } from "../../../helpers/popup";
import CustomDatePicker from "../../../helpers/customDatePicker";
type Props = {
  isOpen?: any;
  setisCreatePhaseOpen?: any;
  timezone?: any;
  projectId?: any;
  phaseId?: any;
  projectList?: any;
  phaseList?: any;
  setIsPopupOpen?: any;
  setIsRefatchTable?: any;
  setIsFormDirty?: any;
  isEditScreen?: boolean
};
const PhaseCreate: FC<Props> = ({
  isOpen,
  setisCreatePhaseOpen,
  timezone,
  projectList,
  projectId,
  phaseList,
  phaseId,
  setIsPopupOpen,
  setIsRefatchTable,
  isEditScreen,
  setIsFormDirty
}) => {
  let phaseListData: any = [
    {
      label: "Phase",
      value: "",
      projectID: projectId ? projectId : "",
      parentPhaseID: phaseId ? phaseId : "",
    },
  ];

  const deepCopyPhaseList = phaseList && JSON.parse(JSON.stringify(phaseList));

  // Filter and modify the deep copy
  deepCopyPhaseList && deepCopyPhaseList.forEach((el) => {
    el.label = 'Subphase of "' + el?.name + '"';
    phaseListData.push(el);
  });

  const [createPhase, setcreatePhase] = useState<any>({
    title: "",
    projectID: projectId ? projectId : "",
    startDate: null,
    dueDate: null,
    parentPhaseID: phaseId ?? "",
    isDraft : isEditScreen ? true : false,
  });
  const [isValidCreateData, setValidCreateData] = useState<any>(true);
  const [showCreateError, setshowCreateError] = useState<any>(false);
  const [showSuccessMessage, setshowSuccessMessage] = useState<any>("");
  const [errorMessage] = useState("");
  const [isSubmit, setSubmit] = useState(false);
  const onClose = useCallback(async () => {
    setcreatePhase({
      title: "",
      projectID: projectId ? projectId : "",
      startDate: null,
      dueDate: null,
      parentPhaseID: phaseId ?? "",
    });
    setisCreatePhaseOpen(false);
    setshowCreateError(false);
    setshowSuccessMessage("");
    setSubmit(false);
    setIsPopupOpen && setIsPopupOpen(false);
  }, [isOpen, createPhase, showCreateError, showSuccessMessage, isSubmit]);

  const CreatePhaseAutotask = async () => {
    if (!isValidCreateData && createPhase.title !== "") {
      setIsFormDirty && setIsFormDirty(true);
      setSubmit(true);
      let res: any = await createPhaseApi(createPhase);
        if(res){
          setisCreatePhaseOpen(false);
          setshowCreateError(false);
          setcreatePhase({
            title: "",
            projectID: projectId ? projectId : "",
            startDate: null,
            dueDate: null,
            parentPhaseID: phaseId ?? "",
          });
          setSubmit(false);
          setIsPopupOpen && setIsPopupOpen(false);
          setIsRefatchTable && setIsRefatchTable(true);
      }
    } else {
      setshowCreateError(true);
    }
  };

  const changeCreatetaskValue = (e: any, type: any) => {
    let event: any = JSON.parse(JSON.stringify(createPhase));
    if (type === "title") {
      if (e.target.value?.length <= 255) {
        event.title = e.target.value.trim();
      } else {
        event.title = e.target.value?.substr(0, 255).trim();
      }
    }
    if (type === "start_date") {
      if (e.value != null) {
        let start = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .format("YYYY-MM-DD");
        event.startDate = start;
      }
    }
    if (type === "end_date") {
      if (e.value != null) {
        let end = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .format("YYYY-MM-DD");
        event.dueDate = end;
      }
    }
    if (type === "project") {
      event.projectID = projectId;
      event.parentPhaseID = phaseId ? phaseId : "";
    }

    if (type === "phase") {
      let tworktype = e[e.length - 1];
      event.parentPhaseID = (phaseId) || tworktype ? tworktype.value : "";
    }
    setcreatePhase(event);
    setshowCreateError(true);
    createpopupvalidation(event);
  };

  const createpopupvalidation = (event: any) => {

    let valid: any = false;
    if (event.startDate === undefined || event.startDate === "" || event.startDate === null) {
      valid = true;
    }
    if (event.dueDate === undefined || event.dueDate === "" || event.dueDate === null) {
      valid = true;
    }
    if (event.title === undefined || event.title === "") {
      valid = true;
    }
    if (event.title !== "" && event?.title?.length > 255) {
      valid = true;
    }
    setshowCreateError(true);
    setValidCreateData(valid);
  };

  return (
    <>
      <Popup
        display="bottom"
        fullScreen={true}
        contentPadding={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        scrollLock={false}
        onClose={onClose}
        // onClose={onClose}
        responsive={responsivePopup}
        cssClass="md-tooltip md-tooltip-detail newPopup popup1000"
      >
        <div className="fs-16 fontIner text-dark h-100">
          {/* <div className="min-h-65px"></div> */}
          {/* Header start */}
          <div
            className="md-tooltip-header d-flex justify-content-between align-items-center min-h-55px paddLR24"
            style={{
              backgroundColor: `${"#DFDEFF"}`,
              zIndex: "1",
            }}
          >
            <span className="fs-18 fw-bold text-dark">Create Phase</span>
          </div>
          {/* Header end */}

          {/* Middle form start */}
          <div className="md-tooltip-info flex-1">
            {/* Create phase form start */}
            <div className="paddA24">
              {errorMessage && (
                <div className="mb-4 alert alert-danger p-2 px-3">
                  <div className="alert-text font-weight-bold fs-14">
                    {errorMessage}
                  </div>
                </div>
              )}
              {showSuccessMessage && (
                <div className="mb-4 alert alert-success p-2 px-3">
                  <div className="alert-text font-weight-bold fs-14">
                    {showSuccessMessage}
                  </div>
                </div>
              )}
              <div className="row row16 row-cols-3">
                <div className="col mb-24px">
                  <strong className="fw-bold mb-4px lh-24px d-block required">
                    Project
                  </strong>
                  {projectList && (
                    <Select
                      value={projectList?.filter(
                        (twork: any) => twork.value === createPhase?.projectID
                      )}
                      isMulti
                      isClearable={false}
                      name="Issues"
                      placeholder="Select project"
                      options={projectList?.filter(
                        (twork: any) => twork.isActive === true
                      )}
                      id="create-phase-project"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) => changeCreatetaskValue(e, "project")}
                      isDisabled={true}
                    />
                  )}
                  {showCreateError && createPhase?.projectID === "" && (
                    <span style={{ color: "red" }}>Please select project.</span>
                  )}
                </div>


                <div className="col mb-24px">
                  <strong className="fw-bold mb-4px lh-24px d-block">
                    Phase Type
                  </strong>
                  {phaseListData && (
                    <Select
                      value={phaseListData?.filter(
                        (tphase: any) =>
                          parseInt(tphase.value) ===
                          parseInt(createPhase?.parentPhaseID)
                      )}
                      isMulti
                      isClearable={false}
                      placeholder="Phase"
                      name="Phase"
                      id="create-phase-type"
                      options={phaseListData?.filter(
                        (twork: any) =>
                          twork.projectID ===
                          (createPhase?.projectID
                            ? parseInt(createPhase?.projectID)
                            : "") || twork.projectID === ""
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) =>
                        option?.isActive === false ? true : false
                      }
                      onChange={(e: any) => changeCreatetaskValue(e, "phase")}
                    />
                  )}
                </div>

                <div className="col mb-24px">
                  <strong className="fw-bold mb-4px lh-24px d-block required">
                    Title
                  </strong>
                  <input
                    type="text"
                    onChange={(e: any) => changeCreatetaskValue(e, "title")}
                    className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                    id="create-phase-title"
                  />
                  {showCreateError && createPhase?.title === "" && (
                    <span style={{ color: "red" }}>Please add title.</span>
                  )}
                  {showCreateError &&
                    createPhase?.title != "" &&
                    createPhase?.title?.length > 255 && (
                      <span style={{ color: "red" }}>
                        Title should not contain more than 255 characters.
                      </span>
                    )}
                </div>

                <div className="col mb-24px">
                  <strong className="fw-bold mb-4px lh-24px d-block required">
                    Start date
                  </strong>
                  <span
                    className="align-items-center position-relative flex-fill creatDatepicker"
                    id="create-phase-start"
                  >
                    <CustomDatePicker
                      controls={["calendar"]}
                      touchUi={true}
                      buttons={[]}
                      display="anchored"
                      showOverlay={false}
                      dateFormat="DD-MM-YYYY"
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "start_date")
                      }
                      max={
                        createPhase?.dueDate
                          ? moment(createPhase?.dueDate).format("YYYY-MM-DD")
                          : ""
                      }
                      value={
                        createPhase?.startDate
                          ? moment(createPhase?.startDate).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </span>
                  {showCreateError &&
                    (!createPhase?.startDate ||
                      createPhase?.startDate === "") && (
                      <>
                        <div className="w-100" style={{ color: "#f00" }}>
                          Please select start date.
                        </div>
                      </>
                    )}
                </div>

                <div className="col mb-24px">
                  <strong className="fw-bold mb-4px lh-24px d-block required">
                    End date
                  </strong>
                  <span
                    className="align-items-center position-relative flex-fill creatDatepicker"
                    id="create-phase-end"
                  >
                    <CustomDatePicker
                      controls={["calendar"]}
                      touchUi={true}
                      buttons={[]}
                      display="anchored"
                      showOverlay={false}
                      dateFormat="DD-MM-YYYY"
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "end_date")
                      }
                      min={
                        createPhase?.startDate
                          ? moment(createPhase?.startDate).format("YYYY-MM-DD")
                          : ""
                      }
                      value={
                        createPhase?.dueDate
                          ? moment(createPhase?.dueDate).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </span>
                  {showCreateError &&
                    (!createPhase?.dueDate || createPhase?.dueDate === "") && (
                      <>
                        <div className="w-100" style={{ color: "#f00" }}>
                          Please select end date.
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
            {/* Create phase form end */}

            <div className="d-flex paddLR24 py-4 justify-content-end border-top border-graydarkBg position-sticky bg-white bottom-0">
              <button
                id="create-phase-cancel"
                className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-4px px-4 py-0 h-32px fs-16 d-inline-flex align-items-center me-3"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                id="create-phase-save"
                className={`btn btn-primary fw-normal border-primary rounded-4px px-4 py-0 h-32px fs-16 d-inline-flex align-items-center ${
                  isSubmit ? "isoffline" : ""
                }`}
                onClick={CreatePhaseAutotask}
              >
                Create
              </button>
            </div>
          </div>
          {/* Middle form end */}
        </div>
      </Popup>
    </>
  );
};

export default PhaseCreate;
